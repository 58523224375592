import { Grid, Hidden } from "@mui/material";
import { ApplicationSectionProps } from "../../../types/jobdetails";
import ApplicationOverview from "../../Molecules/JobDetails/ApplicationOverview";
import Title from "../../Atoms/Typography/Title";

const ApplicationSection: React.FC<ApplicationSectionProps> = ({
  jobAdId,
  recruiterName,
  recruiterImgURL,
  recruiterEmail,
  prefilledUserData,
  isRecunitedAvailable,
  companyName,
  calendly,
}) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Title bold size="medium" text="Dein Ansprechpartner" />
        </Grid>
      </Hidden>
      <Grid container item xs={12}>
        <ApplicationOverview
          isRecunitedAvailable={false}
          jobAdId={jobAdId}
          prefilledUserData={prefilledUserData}
          recruiterName={recruiterName}
          recruiterImgURL={recruiterImgURL}
          companyName={companyName}
          calendly={calendly}
          recruiterEmail={recruiterEmail}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationSection;
