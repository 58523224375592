import { Grid } from "@mui/material";

const BadgesForTrust: React.FC = () => {
  return (
    <Grid container flexWrap={"nowrap"} alignItems="center" justifyContent="flex-end">
      <Grid item xs={1.3} md={1.3} lg={1.2}>
        <img alt="Kununu Top 2023" src={"/kununu_top_2023.png"} width={"100%"} />
      </Grid>
      <Grid item xs={1.5}>
        <img alt="Great Place To Work" src={"/greatPlaceToWork_Bild.png"} width={"90%"} />
      </Grid>
      <Grid item xs={2.4} md={2.4} lg={2.4}>
        <img alt="Recunited Cultural-Fit-Test" src={"/recunited_badge.png"} width={"85%"} />
      </Grid>
      <Grid item xs={2.4} md={2.4} lg={2.4}>
        <img alt="Kununu Top Company" src={"/kununu_open.png"} width={"85%"} />
      </Grid>
      <Grid item xs={2.4} md={2.4} lg={2.4}>
        <img alt="Kununu Top Company" src={"/kununu_top.png"} width={"85%"} />
      </Grid>
      <Grid item xs={2.4} md={2.4} lg={2.4}>
        <img alt="Kununu Rating 4.7" src={"/kununu.png"} width={"90%"} />
      </Grid>
      <Grid item xs={2.4} md={2.4} lg={2.4}>
        <img alt="DSGVO Konform" src={"/dsgvo.png"} width={"90%"} />
      </Grid>
    </Grid>
  );
};

export default BadgesForTrust;
