import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

interface FormStaticAutocompleteProps {
  menuItems: string[];
  label: string;
  error?: string;
  name: string;
  required?: boolean;
  value: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const FormStaticAutocomplete: React.FC<FormStaticAutocompleteProps> = ({
  menuItems,
  label,
  error,
  name,
  required,
  value,
  setFieldValue,
}) => {
  return (
    <Autocomplete
      options={menuItems}
      renderInput={(params) => (
        <TextField
          variant="standard"
          required={required}
          error={Boolean(error)}
          helperText={error}
          {...params}
          label={label}
        />
      )}
      onChange={(e, value) => {
        setFieldValue(name, value);
      }}
      value={value}
    />
  );
};

export default FormStaticAutocomplete;
