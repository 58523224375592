import { Box, Button, SxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { SvgIconComponent } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButtonText: {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
    },
  })
);

interface PrimaryBtnProps {
  className?: string;
  label: string;
  size?: "small" | "medium" | "large" | undefined;
  onClick?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
}

const PrimaryBtn: React.FC<PrimaryBtnProps> = (props) => {
  const {
    className,
    label,
    size,
    onClick,
    icon,
    color,
    disabled,
    style,
    fullWidth = true,
    endIcon,
    sx,
  } = props;
  const classes = useStyles();
  return (
    <Button
      className={className}
      style={style}
      type="submit"
      variant="contained"
      size={size ? size : "medium"}
      color={color ? color : "primary"}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
      endIcon={endIcon}
      sx={sx}
    >
      <Box className={classes.primaryButtonText}>{label}</Box>
    </Button>
  );
};

export default PrimaryBtn;
