import { Grid, Hidden } from "@mui/material";
import { Field, Formik, FormikProps } from "formik";
import React from "react";
import CheckBoxFormField from "../FormFields/CheckBoxFormField";
import MultiSelectFormField from "../FormFields/MultiSelectFormField";
import InputSliderFormField from "../FormFields/InputSliderFormField";
import { SearchFormValues } from "../../../types/jobportal";

interface FilteredSearchProps {
  updateAmountFound: (values: SearchFormValues) => void;
  formik: FormikProps<SearchFormValues>;
}

const FilteredSearch: React.FC<FilteredSearchProps> = ({
  updateAmountFound,
  formik,
}) => {
  const optionsJobType = [
    "Vollzeittätigkeit",
    "Teilzeittätigkeit",
    "Freiberufliche Tätigkeit",
    "Midijob",
    "Minijob",
  ];

  const optionsIndustry = [
    "Organisation/Projektmanagement",
    "Bauwesen, Handwerk, Umwelt",
    "Consulting, Beratung",
    "Einkauf, Materialwirtschaft",
    "Finanz- und Rechnungswesen, Controlling, Versicherung",
    "Forschung/Entwicklung, naturwissenschaftliche Berufe, Wissenschaft",
    "Gesundheit, Medizin, Soziales",
    "Hotellerie und Gastgewerbe, Tourismus",
    "IT/Telekommunikation",
    "Marketing, PR, Werbung, Design/Multimedia",
    "Personalwesen/Recruitment",
    "Rechts- und Steuerwesen",
    "Sekretariat/Office Manangement/Verwaltung",
    "Technische Berufe (Ingenieure, Konstrukteure, Architekten)",
    "Transport, Verkehr, Logistik, Lager",
    "Unternehmensführung/Management",
    "Vertrieb, Verkauf",
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4.5}>
        <MultiSelectFormField
          label="Anstellungsverhältnis"
          name="jobType"
          options={optionsJobType}
          updateAmountFound={updateAmountFound}
          values={formik.values}
          handleChange={formik.handleChange}
          value={formik.values.jobType}
        />
      </Grid>
      <Grid item xs={12} md={4.5}>
        <MultiSelectFormField
          label="Tätigkeitsfeld"
          name="industry"
          options={optionsIndustry}
          updateAmountFound={updateAmountFound}
          values={formik.values}
          handleChange={formik.handleChange}
          value={formik.values.industry}
        />
      </Grid>
      <Grid item container xs={12} md={4.5}>
        <InputSliderFormField
          label="Distanz vom Wohnort (km)"
          name="radius"
          updateAmountFound={updateAmountFound}
          values={formik.values}
          handleChange={formik.handleChange}
          value={formik.values.radius}
        />
      </Grid>
      <Grid item container xs={12} md={5}>
        <CheckBoxFormField
          label="Nur Jobs mit Remote-Option"
          name="remote"
          updateAmountFound={updateAmountFound}
          values={formik.values}
          handleChange={formik.handleChange}
          value={formik.values.remote}
        />
      </Grid>
    </Grid>
  );
};

export default FilteredSearch;
