import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FormikProps } from "formik";
import {
  ApplicationFormValues,
  CVApplicationValues,
} from "../../../types/jobdetails";
import Text from "../../Atoms/Typography/Text";
import Title from "../../Atoms/Typography/Title";
import CheckBoxFormField from "../../Molecules/FormFields/CheckBoxFormField";

interface FinalizationFormPartProps {
  formik: FormikProps<CVApplicationValues> | FormikProps<ApplicationFormValues>;
}

const useStyles = makeStyles(() =>
  createStyles({
    centerAlignForm: { alignSelf: "center" },
    leftAlignHelperText: { marginLeft: 0 },
  })
);

const FinalizationFormPart: React.FC<FinalizationFormPartProps> = ({
  formik,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} item xs={12}>
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Startdaten" />
      </Grid>

      <Grid container item md={6} xs={12}>
        <TextField
          required
          fullWidth
          variant="standard"
          label="Frühstmöglicher Startzeitpunkt"
          error={
            formik.touched.earliestDateToStart &&
            Boolean(formik.errors.earliestDateToStart)
          }
          helperText={
            formik.errors.earliestDateToStart &&
            formik.touched.earliestDateToStart
              ? formik.errors.earliestDateToStart
              : ""
          }
          {...formik.getFieldProps("earliestDateToStart")}
        />
      </Grid>
      <Grid container item md={6} xs={12}>
        <TextField
          fullWidth
          variant="standard"
          onChange={formik.handleChange}
          value={formik.values.noticePeriod}
          name="noticePeriod"
          label="Kündigungsfrist"
          error={
            formik.touched.noticePeriod && Boolean(formik.errors.noticePeriod)
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <TextField
          fullWidth
          variant="standard"
          required
          label="Gehaltsvorstellung"
          error={
            formik.touched.expectedSalary &&
            Boolean(formik.errors.expectedSalary)
          }
          helperText={
            formik.errors.expectedSalary && formik.touched.expectedSalary
              ? formik.errors.expectedSalary
              : ""
          }
          {...formik.getFieldProps("expectedSalary")}
        />
      </Grid>
      <Grid container item xs={12}>
        <CheckBoxFormField
          label={
            <>
              <Text inline text="Ich stimme der " />
              <Link href="https://searchtalent.de/datenschutz/" target="_blank">
                <Text color inline text="Datenschutzerklärung" />
              </Link>
              <Text inline text=" zu." />
            </>
          }
          name="dsgvo"
          value={formik.values.dsgvo}
          required
          handleChange={formik.handleChange}
          error={
            formik.touched.dsgvo &&
            formik.touched.dsgvo &&
            Boolean(formik.errors.dsgvo) &&
            Boolean(formik.errors.dsgvo)
              ? formik.errors.dsgvo
              : ""
          }
        />
      </Grid>
    </Grid>
  );
};

export default FinalizationFormPart;
