import { Grid, CircularProgress, Slide } from "@mui/material";
import Text from "../../Atoms/Typography/Text";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchResultsMobileProps } from "../../../types/searchresults";
import JobResult from "../../Molecules/JobResult";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import { makeStyles, createStyles } from "@mui/styles";
import { useHistory } from "react-router";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";

const useStyles = makeStyles(() =>
  createStyles({ noScrollbar: { overflow: "visible" } })
);

const SearchResultsMobile: React.FC<SearchResultsMobileProps> = ({
  resultsMobile,
  setPage,
  page,
}) => {
  const fetchData = () => {
    if (page) {
      let newPage = page + 1;
      setPage(newPage);
    }
  };
  const location: { search: string; state: { response: boolean } } =
    useLocation();

  useEffect(() => {
    if (location.state && location.state.response) {
      scrollSmoothly();
      location.state.response = false;
    }
  }, [location]);

  const scrollSmoothly = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const classes = useStyles();

  let history = useHistory();
  // example
  return (
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={500}>
      <Grid container item spacing={2} xs={12}>
        <Grid item container xs={12} justifyContent="center">
          {resultsMobile && (
            <InfiniteScroll
              className={classes.noScrollbar}
              style={{ overflow: "visible" }}
              dataLength={resultsMobile.jobs.length}
              next={fetchData}
              hasMore={resultsMobile.total_amount > resultsMobile.jobs.length}
              loader={
                <Grid item container xs={12} justifyContent="center">
                  <CircularProgress />
                </Grid>
              }
              endMessage={
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Text text={"Keine weiteren Stellen verfügbar"} />
                  </Grid>
                  <Grid item xs={12}>
                    <PrimaryBtn
                      label="Neue Suche starten"
                      onClick={() => {
                        history.push("/");
                      }}
                    />
                  </Grid>
                </Grid>
              }
            >
              {resultsMobile.jobs.map((jobResult) => (
                <JobResult
                  jobTitle={jobResult.title}
                  startDate={jobResult.start_date}
                  location={jobResult.place}
                  clientName={jobResult.company.name}
                  logo={
                    jobResult.company.logo_url ? jobResult.company.logo_url : ""
                  }
                  id={jobResult.id}
                  key={jobResult.id}
                />
              ))}
            </InfiniteScroll>
          )}
        </Grid>
      </Grid>
    </Slide>
  );
};

export default SearchResultsMobile;
