import { Grid, List, Fade } from "@mui/material";
import React from "react";
import { JobDetail } from "../../types/jobdetails";
import { SearchResultsProps } from "../../types/searchresults";
import JobResult from "../Molecules/JobResult";

const JobResultContainer: React.FC<SearchResultsProps> = ({
  searchResults,
}) => {
  const jobResults = searchResults?.jobs;

  return (
    <Fade in timeout={2000}>
        <div>
            <Grid container item xs={12}>
                <List>
                    {jobResults &&
                        jobResults.map((jobResult: JobDetail) => {
                            return (
                                <JobResult
                                    jobTitle={jobResult.title}
                                    startDate={jobResult.start_date}
                                    location={jobResult.place}
                                    clientName={jobResult.company.name}
                                    logo={
                                        jobResult.company.logo_url ? jobResult.company.logo_url : ""
                                    }
                                    id={jobResult.id}
                                    key={jobResult.id}
                                />
                            );
                        })}
                </List>
            </Grid>
        </div>
    </Fade>
  );
};

export default JobResultContainer;
