import { Grid, Link } from "@mui/material";
import React from "react";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";

const ErrorMessage: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Title size="medium" text="Oops!" />
      </Grid>
      <Grid item xs={12}>
        <Text
          inline
          text={
            "Es sieht so aus, als ob etwas beim Bewerbungsprozess schiefgelaufen ist. Wenn dieses Problem weiterhin auftritt, schicke uns einfach eine Email an: "
          }
        />
        <Link underline="none" href="mailto:support@jobs.searchtalent.com">
          <Text color inline text="support@jobs.searchtalent.com" />
        </Link>
        <Text
          inline
          text=" und wir werden uns so schnell wie möglich darum kümmern."
        />
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
