const countryCodes = [
  //   { c: "Abchasien", n: "+7", x: 0, y: -22 },
  { c: "Afghanistan", n: "+93", x: 0, y: -22 },
  { c: "Ägypten", n: "+20", x: -16, y: -110 },
  { c: "Albanien", n: "+355", x: 0, y: -55 },
  { c: "Algerien", n: "+213", x: -16, y: -77 },
  { c: "Amerikanisch-Samoa", n: "+1684", x: 0, y: -110 },
  { c: "Andorra", n: "+376", x: 0, y: 0 },
  { c: "Angola", n: "+244", x: 0, y: -88 },
  { c: "Anguilla", n: "+1264", x: 0, y: -44 },
  { c: "Antigua und Barbuda", n: "+1268", x: 0, y: -33 },
  { c: "Äquatorialguinea", n: "+240", x: -16, y: -385 },
  { c: "Argentinien", n: "+54", x: 0, y: -99 },
  { c: "Armenien", n: "+374", x: 0, y: -66 },
  { c: "Aruba", n: "+297", x: 0, y: -143 },
  //   { c: "Ascension", n: "+247", x: 0, y: -22 },
  { c: "Aserbaidschan", n: "+994", x: 0, y: -156 },
  { c: "Äthiopien", n: "+251", x: -16, y: -165 },
  { c: "Australien", n: "+61", x: 0, y: -132 },
  { c: "Bahamas", n: "+1242", x: 0, y: -319 },
  { c: "Bahrain", n: "+973", x: 0, y: -242 },
  { c: "Bangladesch", n: "+880", x: 0, y: -198 },
  { c: "Barbados", n: "+1246", x: 0, y: -187 },
  { c: "Belarus (Weißrussland)", n: "+375", x: 0, y: -363 },
  { c: "Belgien", n: "+32", x: 0, y: -209 },
  { c: "Belize", n: "+501", x: 0, y: -374 },
  { c: "Benin", n: "+229", x: 0, y: -264 },
  { c: "Bermuda", n: "+1441", x: 0, y: -275 },
  { c: "Bhutan", n: "+975", x: 0, y: -330 },
  { c: "Bolivien", n: "+591", x: 0, y: -297 },
  //   { c: "Bonaire", n: "+599", x: 0, y: -22 },
  { c: "Bosnien und Herzegowina", n: "+387", x: 0, y: -176 },
  { c: "Botswana", n: "+267", x: 0, y: -352 },
  { c: "Brasilien", n: "+55", x: 0, y: -308 },
  { c: "Brunei", n: "+673", x: 0, y: -286 },
  { c: "Bulgarien", n: "+359", x: 0, y: -231 },
  { c: "Burkina Faso", n: "+226", x: 0, y: -220 },
  { c: "Burundi", n: "+257", x: 0, y: -253 },
  { c: "Chile", n: "+56", x: 0, y: -484 },
  { c: "China (Volksrepublik)", n: "+86", x: 0, y: -506 },
  { c: "Cookinseln", n: "+682", x: 0, y: -473 },
  { c: "Costa Rica", n: "+506", x: 0, y: -539 },
  //   { c: "Curaçao", n: "+599", x: 0, y: 0 },
  { c: "Dänemark", n: "+45", x: -16, y: -44 },
  { c: "Deutschland", n: "+49", x: -16, y: -22 },
  { c: "Dominica", n: "+1767", x: -16, y: -55 },
  { c: "Dominikanische Republik", n: "+1809", x: -16, y: -66 },
  { c: "Dschibuti", n: "+253", x: -16, y: -33 },
  { c: "Ecuador", n: "+593", x: -16, y: -88 },
  { c: "El Salvador", n: "+503", x: -48, y: -528 },
  { c: "Elfenbeinküste", n: "+225", x: 0, y: -462 },
  { c: "Eritrea", n: "+291", x: -16, y: -143 },
  { c: "Estland", n: "+372", x: -16, y: -99 },
  //   { c: "Eswatini", n: "+268", x: 0, y: -22 },
  { c: "Falklandinseln", n: "+5+", x: -16, y: -220 },
  { c: "Färöer", n: "+298", x: -16, y: -242 },
  { c: "Fidschi", n: "+679", x: -16, y: -209 },
  { c: "Finnland", n: "+358", x: -16, y: -198 },
  { c: "Frankreich", n: "+33", x: -16, y: -253 },
  //   { c: "Französisch-Guayana", n: "+594", x: 0, y: -22 },
  { c: "Französisch-Polynesien", n: "+689", x: -48, y: -143 },
  { c: "Gabun", n: "+241", x: -16, y: -264 },
  { c: "Gambia", n: "+220", x: -16, y: -352 },
  { c: "Georgien", n: "+995", x: -16, y: -297 },
  { c: "Ghana", n: "+233", x: -16, y: -319 },
  { c: "Gibraltar", n: "+350", x: -16, y: -330 },
  { c: "Grenada", n: "+1473", x: -16, y: -286 },
  { c: "Griechenland", n: "+30", x: -16, y: -396 },
  { c: "Grönland", n: "+299", x: -16, y: -341 },
  {
    c: "Großbritannien/Vereinigtes Königreich (auch Isle of Man und Kanalinseln)",
    n: "+44",
  },
  //   { c: "Guadeloupe", n: "+590", x: 0, y: -22 },
  { c: "Guam", n: "+1671", x: -16, y: -429 },
  { c: "Guatemala", n: "+502", x: -16, y: -418 },
  { c: "Guinea", n: "+224", x: -16, y: -363 },
  { c: "Guinea-Bissau", n: "+245", x: -16, y: -440 },
  { c: "Guyana", n: "+592", x: -16, y: -451 },
  { c: "Haiti", n: "+509", x: -16, y: -506 },
  { c: "Honduras", n: "+504", x: -16, y: -484 },
  { c: "Hongkong", n: "+852", x: -16, y: -462 },
  { c: "Indien", n: "+91", x: -16, y: -561 },
  { c: "Indonesien", n: "+62", x: -16, y: -528 },
  { c: "Irak", n: "+964", x: -32, y: 0 },
  { c: "Iran", n: "+98", x: -32, y: -11 },
  { c: "Irland", n: "+353", x: -16, y: -539 },
  { c: "Island", n: "+354", x: -32, y: -22 },
  { c: "Israel", n: "+972", x: -16, y: -550 },
  { c: "Italien", n: "+39", x: -32, y: -33 },
  { c: "Jamaika", n: "+1876", x: -32, y: -44 },
  { c: "Japan", n: "+81", x: -32, y: -66 },
  { c: "Jemen", n: "+967", x: -64, y: -330 },
  { c: "Jordanien", n: "+962", x: -32, y: -55 },
  { c: "Kaimaninseln", n: "+1345", x: -32, y: -176 },
  { c: "Kambodscha", n: "+855", x: -32, y: -99 },
  { c: "Kamerun", n: "+237", x: 0, y: -495 },
  { c: "Kanada", n: "+1 - ", x: 0, y: -385 },
  { c: "Kap Verde", n: "+238", x: 0, y: -561 },
  { c: "Kasachstan", n: "+7", x: -32, y: -187 },
  { c: "Katar", n: "+974", x: -48, y: -275 },
  { c: "Kenia", n: "+254", x: -32, y: -77 },
  { c: "Kirgisistan", n: "+996", x: -32, y: -88 },
  { c: "Kiribati", n: "+686", x: -32, y: -110 },
  { c: "Kokosinseln", n: "+61", x: 0, y: -407 },
  { c: "Kolumbien", n: "+57", x: 0, y: -517 },
  { c: "Komoren", n: "+269", x: -32, y: -121 },
  { c: "Kongo (Republik)", n: "+242", x: 0, y: -440 },
  { c: "Kongo (Demokratische Republik)", n: "+243", x: 0, y: -418 },
  //   { c: "Kosovo", n: "+383", x: 0, y: 0 },
  { c: "Kroatien", n: "+385", x: -16, y: -495 },
  { c: "Kuba", n: "+53", x: 0, y: -528 },
  { c: "Kuwait", n: "+965", x: -32, y: -165 },
  { c: "Laos", n: "+856", x: -32, y: -198 },
  { c: "Lesotho", n: "+266", x: -32, y: -264 },
  { c: "Lettland", n: "+371", x: -32, y: -297 },
  { c: "Libanon", n: "+961", x: -32, y: -209 },
  { c: "Liberia", n: "+231", x: -32, y: -242 },
  { c: "Libyen", n: "+218", x: -32, y: -308 },
  { c: "Liechtenstein", n: "+423", x: -32, y: -231 },
  { c: "Litauen", n: "+370", x: -32, y: -275 },
  { c: "Luxemburg", n: "+352", x: -32, y: -286 },
  { c: "Macau", n: "+853", x: -32, y: -429 },
  { c: "Madagaskar", n: "+261", x: -32, y: -363 },
  { c: "Malawi", n: "+265", x: -32, y: -517 },
  { c: "Malaysia", n: "+60", x: -32, y: -539 },
  { c: "Malediven", n: "+960", x: -32, y: -506 },
  { c: "Mali", n: "+223", x: -32, y: -396 },
  { c: "Malta", n: "+356", x: -32, y: -484 },
  { c: "Marokko", n: "+212", x: -32, y: -319 },
  { c: "Marshallinseln", n: "+692", x: -32, y: -374 },
  //   { c: "Martinique", n: "+596", x: 0, y: -22 },
  { c: "Mauretanien", n: "+222", x: -32, y: -462 },
  { c: "Mauritius", n: "+230", x: -32, y: -495 },
  { c: "Mayotte", n: "+262", x: -64, y: -341 },
  { c: "Mexiko", n: "+52", x: -32, y: -528 },
  { c: "Mikronesien", n: "+691", x: -16, y: -211 },
  { c: "Moldau", n: "+373", x: -32, y: -341 },
  { c: "Monaco", n: "+377", x: -32, y: -330 },
  { c: "Mongolei", n: "+976", x: -32, y: -418 },
  { c: "Montenegro", n: "+382", x: -32, y: -352 },
  { c: "Montserrat", n: "+1664", x: -32, y: -473 },
  { c: "Mosambik", n: "+258", x: -32, y: -550 },
  { c: "Myanmar", n: "+95", x: -32, y: -407 },
  { c: "Namibia", n: "+264", x: -32, y: -561 },
  { c: "Nauru", n: "+674", x: -48, y: -77 },
  { c: "Nepal", n: "+977", x: -48, y: -66 },
  { c: "Neukaledonien", n: "+687", x: -32, y: -572 },
  { c: "Neuseeland", n: "+64", x: -48, y: -99 },
  { c: "Nicaragua", n: "+505", x: -48, y: -33 },
  { c: "Niederlande", n: "+31", x: -48, y: -44 },
  { c: "Niger", n: "+227", x: -48, y: 0 },
  { c: "Nigeria", n: "+234", x: -48, y: -22 },
  { c: "Niue", n: "+683", x: -48, y: -88 },
  { c: "Nordkorea", n: "+850", x: -32, y: -143 },
  //   { c: "Nordmazedonien", n: "+389", x: 0, y: -22 },
  //   { c: "Norfolkinsel", n: "+6723", x: 0, y: -22 },
  { c: "Norwegen", n: "+47", x: -48, y: -55 },
  { c: "Oman", n: "+968", x: -48, y: -110 },
  { c: "Österreich", n: "+43", x: 0, y: -121 },
  { c: "Osttimor", n: "+670", x: -64, y: -55 },
  { c: "Pakistan", n: "+92", x: -48, y: -176 },
  { c: "Palästina", n: "+970", x: -48, y: -231 },
  { c: "Palau", n: "+680", x: -48, y: -253 },
  { c: "Panama", n: "+507", x: -48, y: -121 },
  { c: "Papua-Neuguinea", n: "+675", x: -48, y: -154 },
  { c: "Paraguay", n: "+595", x: -48, y: -264 },
  { c: "Peru", n: "+51", x: -48, y: -132 },
  { c: "Philippinen", n: "+63", x: -48, y: -165 },
  { c: "Pitcairninseln", n: "+649", x: -48, y: -209 },
  { c: "Polen", n: "+48", x: -48, y: -187 },
  { c: "Portugal", n: "+351", x: -48, y: -242 },
  { c: "Puerto Rico", n: "+1787/+1939", x: -48, y: -220 },
  { c: "Réunion", n: "+262", x: -48, y: -286 },
  { c: "Ruanda", n: "+250", x: -48, y: -330 },
  { c: "Rumänien", n: "+40", x: -48, y: -297 },
  { c: "Russland", n: "+7", x: -48, y: -319 },
  //   { c: "Saba", n: "+599", x: 0, y: -22 },
  { c: "Saint-Pierre und Miquelon", n: "+508", x: -48, y: -198 },
  { c: "Salomonen", n: "+677", x: -48, y: -352 },
  { c: "Sambia", n: "+260", x: -64, y: -363 },
  { c: "Samoa", n: "+685", x: -64, y: -319 },
  { c: "San Marino", n: "+378", x: -48, y: -473 },
  { c: "São Tomé und Príncipe", n: "+239", x: -48, y: -517 },
  { c: "Saudi-Arabien", n: "+966", x: -48, y: -341 },
  { c: "Schweden", n: "+46", x: -48, y: -396 },
  { c: "Schweiz", n: "+41", x: 0, y: -451 },
  { c: "Senegal", n: "+221", x: -48, y: -484 },
  { c: "Serbien", n: "+381", x: -48, y: -308 },
  { c: "Seychellen", n: "+248", x: -48, y: -363 },
  { c: "Sierra Leone", n: "+232", x: -48, y: -462 },
  { c: "Simbabwe", n: "+263", x: -64, y: -374 },
  { c: "Singapur", n: "+65", x: -48, y: -407 },
  //   { c: "Sint Eustatius", n: "+599", x: 0, y: -22 },
  //   { c: "Sint Maarten", n: "+1721", x: 0, y: -22 },
  { c: "Slowakei", n: "+421", x: -48, y: -451 },
  { c: "Slowenien", n: "+386", x: -48, y: -429 },
  { c: "Somalia", n: "+252", x: -48, y: -495 },
  { c: "Spanien", n: "+34", x: -16, y: -154 },
  { c: "Sri Lanka", n: "+94", x: -32, y: -253 },
  { c: "St. Helena", n: "+290", x: -48, y: -418 },
  { c: "St. Kitts und Nevis", n: "+1869", x: -32, y: -132 },
  { c: "St. Lucia", n: "+1758", x: -32, y: -220 },
  { c: "St. Vincent und die Grenadinen", n: "+1784", x: -64, y: -231 },
  { c: "Südafrika", n: "+27", x: -64, y: -352 },
  { c: "Sudan", n: "+249", x: -48, y: -385 },
  //   { c: "Südsudan", n: "+211", x: 0, y: -22 },
  { c: "Südkorea", n: "+82", x: -32, y: -154 },
  //   { c: "Südossetien", n: "+7", x: 0, y: -22 },
  { c: "Suriname", n: "+597", x: -48, y: -506 },
  { c: "Syrien", n: "+963", x: -48, y: -539 },
  { c: "Tadschikistan", n: "+992", x: -64, y: -33 },
  { c: "Taiwan", n: "+886", x: -64, y: -132 },
  { c: "Tansania", n: "+255", x: -64, y: -143 },
  { c: "Thailand", n: "+66", x: -64, y: -22 },
  { c: "Togo", n: "+228", x: -64, y: -11 },
  { c: "Tokelau", n: "+690", x: -64, y: -44 },
  { c: "Tonga", n: "+676", x: -64, y: -88 },
  { c: "Trinidad und Tobago", n: "+1868", x: -64, y: -110 },
  //   { c: "Tristan da Cunha", n: "+290", x: 0, y: -22 },
  { c: "Tschad", n: "+235", x: -48, y: -572 },
  { c: "Tschechien", n: "+420", x: -16, y: -11 },
  { c: "Tunesien", n: "+216", x: -64, y: -77 },
  { c: "Türkei", n: "+90", x: -64, y: -99 },
  //   { c: "Türkische Republik Nordzypern", n: "+90", x: 0, y: -22 },
  { c: "Turkmenistan", n: "+993", x: -64, y: -66 },
  { c: "Turks- und Caicosinseln", n: "+1649", x: -48, y: -561 },
  { c: "Tuvalu", n: "+688", x: -64, y: -121 },
  { c: "Uganda", n: "+256", x: -64, y: -165 },
  { c: "Ukraine", n: "+380", x: -64, y: -154 },
  { c: "Ungarn", n: "+36", x: -16, y: -517 },
  { c: "Uruguay", n: "+598", x: -64, y: -198 },
  {
    c: "USA (Vereinigte Staaten)",
    n: "+1 – ",
    x: -64,
    y: -187,
  },
  { c: "Usbekistan", n: "+998", x: -64, y: -209 },
  { c: "Vanuatu", n: "+678", x: -64, y: -286 },
  { c: "Vatikan", n: "+379", x: -64, y: -220 },
  { c: "Venezuela", n: "+58", x: -64, y: -242 },
  { c: "Vereinigte Arabische Emirate", n: "+971", x: 0, y: -11 },
  { c: "Vereinigtes Königreich", n: "+44", x: -16, y: -275 },
  { c: "Vietnam", n: "+84", x: -64, y: -275 },
  { c: "Wallis und Futuna", n: "+681", x: -64, y: -308 },
  { c: "Weihnachtsinsel", n: "+61", x: 0, y: -572 },
  { c: "Zentralafrikanische Republik", n: "+236", x: 0, y: -429 },
  { c: "Zypern (ohne Türkische Republik Nordzypern)", n: "+357", x: -16, y: 0 },
];

export default countryCodes;
