import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RoomIcon from "@mui/icons-material/Room";
import { Chip, Grid, Hidden, Link } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useState } from "react";
import { AddressProps, JobOverviewProps } from "../../types/jobdetails";
import Title from "../Atoms/Typography/Title";
import ClientLogo from "../Atoms/Images/ClientLogo";

const useStyles = makeStyles(() =>
  createStyles({
    iconSpacing: {
      paddingLeft: 8,
    },
  })
);

const createAddressText = (address: AddressProps) => {
  let addressText = "";

  if (address.city) {
    addressText += address.city;
  }
  return addressText;
};

const OverviewSection: React.FC<JobOverviewProps & { isRecunitedAvailable?: boolean }> = ({ title, company, address, jobId, isRecunitedAvailable, logoLink }) => {
  const [matchingScore, setMatchingScore] = useState<number>(
    parseInt(localStorage.getItem(jobId) ?? "0", 10)
  );
  const addressText = createAddressText(address);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs container>
        <Grid item xs={12} sm={12}>
          {matchingScore ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Title bold size="large" text={title} />
                <Chip
                  label={`${matchingScore}% team match`}
                  size="small"
                  sx={{
                    marginLeft: ".2rem",
                    marginBottom: "1rem",
                    backgroundColor: "#247671",
                    color: "white",
                  }}
                />
              </Box>{" "}
              <Box></Box>
            </Box>
          ) : (
            <Title bold size="large" text={title} />
          )}
        </Grid>
        <Hidden smDown>
          <Grid item alignSelf="center" className="logo-container">
            {logoLink && <ClientLogo title="Client Logo" image={logoLink} />}
          </Grid>
        </Hidden>
        <Grid item xs={12}  sm={12}>
          <Title size="medium" text={company?.name} />
        </Grid>
        {address.city || address.zipCode ? (
          <Grid container item xs={12}>
              <Link underline="none" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressText)}`} target="_blank" className="address-link">
                <Grid container item xs={12}>
                  <Grid item alignSelf="center">
                    <RoomIcon className="map-pointer-icon" sx={{ color: "#333333" }} />
                  </Grid>
                  <Grid item xs className={classes.iconSpacing} alignSelf="center">
                    <Title noH size="small" text={addressText} />
                  </Grid>
                  <Grid item alignSelf="center">
                    <OpenInNewIcon className="company-link" fontSize="small" />
                  </Grid>
                </Grid>
              </Link>
            </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default OverviewSection;
