// NOTE: pulled from recunited. we'll have to see if we want to use it or not

const LANGUAGE_LIST: string[] = [
  "Deutsch",
  "Englisch",
  "Französisch",
  "Italienisch",
  "Spanisch",
  "Niederländisch",
  "Russisch",
  "Polnisch",
  "Chinesisch",
  "Japanisch",
  "Koreanisch",
  "Finnisch",
  "Abchasisch",
  "Aceh-Sprache",
  "Acholi-Sprache",
  "Adangme",
  "Adygeisch",
  "Afar",
  "Afrihili",
  "Afrikaans",
  "Aghem",
  "Ägyptisch",
  "Ägyptisches Arabisch",
  "Ainu-Sprache",
  "Akan",
  "Akkadisch",
  "Akoose",
  "Alabama",
  "Albanisch",
  "Aleutisch",
  "Algerisches Arabisch",
  "Amerikanische Gebärdensprache",
  "Amharisch",
  "Angika",
  "Ao-Naga",
  "Arabisch",
  "Aragonesisch",
  "Aramäisch",
  "Araona",
  "Arapaho-Sprache",
  "Araukanisch",
  "Arawak-Sprache",
  "Armenisch",
  "Aromunisch",
  "Aserbaidschanisch",
  "Assamesisch",
  "Asturianisch",
  "Atsam",
  "Avestisch",
  "Awadhi",
  "Awarisch",
  "Aymara",
  "Bachtiarisch",
  "Badaga",
  "Bafia",
  "Bafut",
  "Bairisch",
  "Balinesisch",
  "Bambara-Sprache",
  "Bamun",
  "Banjaresisch",
  "Basaa-Sprache",
  "Baschkirisch",
  "Baskisch",
  "Batak Toba",
  "Bedauye",
  "Belutschisch",
  "Bemba-Sprache",
  "Bena",
  "Bengalisch",
  "Bergmari",
  "Betawi",
  "Bhodschpuri",
  "Bhutanisch",
  "Bikol-Sprache",
  "Bini-Sprache",
  "Birmanisch",
  "Bishnupriya",
  "Bislama",
  "Blackfoot-Sprache",
  "Blin",
  "Bliss-Symbole",
  "Bodo",
  "Bosnisch",
  "Brahui",
  "Braj-Bhakha",
  "Bretonisch",
  "Buginesisch",
  "Bulgarisch",
  "Bulu",
  "Burjatisch",
  "Caddo",
  "Cajun",
  "Capiznon",
  "Cayuga",
  "Cebuano",
  "Chamorro-Sprache",
  "Cherokee",
  "Cheyenne",
  "Chibcha-Sprache",
  "Chimborazo Hochland-Quechua",
  "Chinesisch (traditionell)",
  "Chinesisch (vereinfacht)",
  "Chinook",
  "Chipewyan",
  "Choctaw",
  "Cree",
  "Dakota-Sprache",
  "Dänisch",
  "Darginisch",
  "Dazaga",
  "Delaware-Sprache",
  "Dinka-Sprache",
  "Diola",
  "Dogri",
  "Dogrib",
  "Duala",
  "Dyula-Sprache",
  "Efik",
  "Ekajuk",
  "Elamisch",
  "Elliceanisch",
  "Embu",
  "Emilianisch",
  "Ersja-Mordwinisch",
  "Esperanto",
  "Estnisch",
  "Ewe",
  "Ewondo",
  "Extremadurisch",
  "Fanti-Sprache",
  "Farefare",
  "Färöisch",
  "Fidschi",
  "Fidschi-Hindi",
  "Filipino",
  "Flämisch",
  "Fon-Sprache",
  "Frankoprovenzalisch",
  "Friulisch",
  "Ful",
  "Ga-Sprache",
  "Gabri",
  "Gagausisch",
  "Galizisch",
  "Gan",
  "Ganda",
  "Gayo",
  "Gbaya-Sprache",
  "Geez",
  "Gegisch",
  "Georgisch",
  "Ghomala",
  "Gilaki",
  "Gilbertesisch",
  "Goa-Konkani",
  "Gondi-Sprache",
  "Gotisch",
  "Grebo-Sprache",
  "Griechisch",
  "Grönländisch",
  "Guarani",
  "Gujarati",
  "Gusii",
  "Haida-Sprache",
  "Haitianisch",
  "Hakka",
  "Hausa",
  "Hawaiisch",
  "Hebräisch",
  "Herero-Sprache",
  "Hethitisch",
  "Hiligaynon-Sprache",
  "Hindi",
  "Hiri-Motu",
  "Hupa",
  "Iban",
  "Ibibio",
  "Ido-Sprache",
  "Igbo",
  "Ilokano-Sprache",
  "Inari-Lappisch",
  "Indonesisch",
  "Inguschisch",
  "Interlingua",
  "Interlingue",
  "Inuktitut",
  "Inupiak",
  "Irisch",
  "Ischorisch",
  "Isländisch",
  "Jakutisch",
  "Jamaikanisch-kreolische Sprache",
  "Javanisch",
  "Jiddisch",
  "Jju",
  "Jüdisch-Arabisch",
  "Jüdisch-Persisch",
  "Jütisch",
  "K’iche’",
  "Kabardinisch",
  "Kabuverdianu",
  "Kabylisch",
  "Kachin-Sprache",
  "Kaingang",
  "Kako",
  "Kalenjin",
  "Kalmückisch",
  "Kamba",
  "Kambodschanisch",
  "Kanembu",
  "Kannada",
  "Kantonesisch",
  "Kanuri-Sprache",
  "Karakalpakisch",
  "Karatschaiisch-Balkarisch",
  "Karelisch",
  "Karibisch",
  "Kasachisch",
  "Kaschmirisch",
  "Kaschubisch",
  "Katalanisch",
  "Kawi",
  "Kenyang",
  "Khasi-Sprache",
  "Khotta",
  "Khowar",
  "Kikuyu",
  "Kimbundu-Sprache",
  "Kinaray-a",
  "Kirchenslawisch",
  "Kirgisisch",
  "Kirmanjki",
  "Klassisches Chinesisch",
  "Klingonisch",
  "Kom",
  "Komi-Permjakisch",
  "Komi-Sprache",
  "Komorisch",
  "Kongo-Suaheli",
  "Kongolesisch",
  "Konkani",
  "Koptisch",
  "Kornisch",
  "Koro",
  "Korsisch",
  "Kosraeanisch",
  "Kotava",
  "Koyra Chiini",
  "Koyra Senni",
  "Kpelle-Sprache",
  "Krimtatarisch",
  "Krio",
  "Kroatisch",
  "Kumükisch",
  "Kurdisch",
  "Kutchin-Sprache",
  "Kutenai-Sprache",
  "Kwanyama",
  "Kwasio",
  "Ladino",
  "Lahnda",
  "Lakota",
  "Lamba-Sprache",
  "Langi",
  "Laotisch",
  "Lasisch",
  "Latein",
  "Lesgisch",
  "Lettgallisch",
  "Lettisch",
  "Ligurisch",
  "Limburgisch",
  "Lingala",
  "Lingua Franca Nova",
  "Litauisch",
  "Livisch",
  "Lojban",
  "Lombardisch",
  "Luba-Katanga",
  "Luba-Lulua",
  "Luhya",
  "Luiseno-Sprache",
  "Lule-Lappisch",
  "Lunda-Sprache",
  "Luo-Sprache",
  "Lushai-Sprache",
  "Luxemburgisch",
  "Maba",
  "Machame",
  "Madagassisch",
  "Maduresisch",
  "Mafa",
  "Mainfränkisch",
  "Maithili",
  "Makassarisch",
  "Makhuwa-Meetto",
  "Makonde",
  "Malaiisch",
  "Malayalam",
  "Maledivisch",
  "Maltesisch",
  "Mandaresisch",
  "Manding-Sprache",
  "Mandschurisch",
  "Manx",
  "Maori",
  "Marathi",
  "Marokkanisches Arabisch",
  "Marschallesisch",
  "Marwari",
  "Masanderanisch",
  "Massai-Sprache",
  "Mazedonisch",
  "Mbundu-Sprache",
  "Meänkieli",
  "Medumba",
  "Mehrsprachig",
  "Meithei-Sprache",
  "Mende-Sprache",
  "Mentawai",
  "Meru",
  "Meta’",
  "Miao-Sprache",
  "Micmac-Sprache",
  "Min Nan",
  "Minangkabau-Sprache",
  "Mingrelisch",
  "Mirandesisch",
  "Modernes Hocharabisch",
  "Mohawk",
  "Moksha",
  "Moldauisch",
  "Mongo",
  "Mongolisch",
  "Mongondou",
  "Morisyen",
  "Mossi-Sprache",
  "Mundang",
  "Muskogee-Sprache",
  "Myene",
  "N’Ko",
  "Nama",
  "Nauruisch",
  "Navajo",
  "Ndonga",
  "Neapolitanisch",
  "Nepalesisch",
  "Neumelanesisch",
  "Newari",
  "Ngambay",
  "Ngiemboon",
  "Ngomba",
  "Nheengatu",
  "Nias-Sprache",
  "Niue-Sprache",
  "Nogai",
  "Nord-Ndebele-Sprache",
  "Nord-Samisch",
  "Nord-Sotho-Sprache",
  "Nordfriesisch",
  "Norwegisch",
  "Norwegisch Bokmål",
  "Norwegisch Nynorsk",
  "Novial",
  "Nuer",
  "Nyamwezi-Sprache",
  "Nyanja-Sprache",
  "Nyankole",
  "Nyoro",
  "Nzima",
  "Obersorbisch",
  "Ojibwa-Sprache",
  "Okzitanisch",
  "Oraon-Sprache",
  "Oriya",
  "Oromo",
  "Osage-Sprache",
  "Osmanisch",
  "Ossetisch",
  "Osterinsel-Sprache",
  "Ostfriesisch",
  "Palau",
  "Pali",
  "Pampanggan-Sprache",
  "Pangasinan-Sprache",
  "Pangwe-Sprache",
  "Panjabi",
  "Papiamento",
  "Pare",
  "Paschtu",
  "Persisch",
  "Phönikisch",
  "Picardisch",
  "Piemontesisch",
  "Plautdietsch",
  "Ponapeanisch",
  "Pontisch",
  "Portugiesisch",
  "Quechua",
  "Rajasthani",
  "Rarotonganisch",
  "Rätoromanisch",
  "Romagnol",
  "Romani",
  "Rombo",
  "Root",
  "Rotse-Sprache",
  "Rotumanisch",
  "Roviana",
  "Ruandisch",
  "Rukiga",
  "Rumänisch",
  "Rundi-Sprache",
  "Russinisch",
  "Rwa",
  "Saho",
  "Sakisch",
  "Samaritanisch",
  "Samburu",
  "Samoanisch",
  "Samogitisch",
  "Sandawe-Sprache",
  "Sango",
  "Sangu",
  "Sanskrit",
  "Santali",
  "Sardisch",
  "Sasak",
  "Sassarisch",
  "Saterfriesisch",
  "Saurashtra",
  "Schan-Sprache",
  "Schwedisch",
  "Seeländisch",
  "Selayar",
  "Selkupisch",
  "Sena",
  "Seneca",
  "Serbisch",
  "Serer-Sprache",
  "Seri",
  "Shambala",
  "Shona",
  "Sidamo",
  "Sindhi",
  "Singhalesisch",
  "Sizilianisch",
  "Skolt-Lappisch",
  "Slave",
  "Slowakisch",
  "Slowenisch",
  "Soga",
  "Sogdisch",
  "Somali",
  "Soninke-Sprache",
  "Sorani",
  "Srananisch",
  "Suaheli",
  "Süd-Altaisch",
  "Süd-Ndebele-Sprache",
  "Süd-Samisch",
  "Süd-Sotho-Sprache",
  "Südaserbaidschanisch",
  "Sukuma-Sprache",
  "Sumerisch",
  "Sundanesisch",
  "Susu",
  "Swazi",
  "Syrisch",
  "Tadschikisch",
  "Tagalog",
  "Tahitisch",
  "Taita",
  "Talisch",
  "Tamaseq",
  "Tamazight",
  "Tamilisch",
  "Tarifit",
  "Taroko",
  "Tasawaq",
  "Taschelhit",
  "Tatarisch",
  "Tatisch",
  "Telugu",
  "Temne",
  "Tereno-Sprache",
  "Teso",
  "Tetum-Sprache",
  "Thailändisch",
  "Tibetisch",
  "Tigre",
  "Tigrinja",
  "Tiv-Sprache",
  "Tlingit-Sprache",
  "Tokelauanisch",
  "Tongaisch",
  "Trukesisch",
  "Tsachurisch",
  "Tsakonisch",
  "Tschadisch-Arabisch",
  "Tschagataisch",
  "Tschechisch",
  "Tscheremissisch",
  "Tschetschenisch",
  "Tschuwaschisch",
  "Tsimshian-Sprache",
  "Tsonga",
  "Tsonga-Sprache",
  "Tswana-Sprache",
  "Tulu",
  "Tumbuka-Sprache",
  "Tunesisches Arabisch",
  "Türkisch",
  "Turkmenisch",
  "Turoyo",
  "Tuwinisch",
  "Twi",
  "Tyap",
  "Udmurtisch",
  "Ugaritisch",
  "Uigurisch",
  "Ukrainisch",
  "Ungarisch",
  "Urdu",
  "Usbekisch",
  "Vai",
  "Venda-Sprache",
  "Venetisch",
  "Vietnamesisch",
  "Volapük",
  "Võro",
  "Vunjo",
  "Walamo-Sprache",
  "Walisisch",
  "Wallonisch",
  "Walser-Dialekte",
  "Waray",
  "Warlpiri",
  "Washo-Sprache",
  "Wayõu",
  "Weissrussisch",
  "Wepsisch",
  "Westflämisch",
  "Westfriesisch",
  "Wolof",
  "Wotisch",
  "Wu",
  "Xhosa",
  "Xiang",
  "Yangben",
  "Yao-Sprache",
  "Yapesisch",
  "Yemba",
  "Yi",
  "Yoruba",
  "Zapotekisch",
  "Zarma",
  "Zaza",
  "Zenaga",
  "Zentral-Alaska-Yupik",
  "Zentral-Dusun",
  "Zentralatlas-Tamazight",
  "Zhuang",
  "Zulu",
  "Zuni-Sprache",
];

export default LANGUAGE_LIST;
