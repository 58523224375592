let apiBaseUrl: string = "";
let recunitedBaseUrl: string = "";
let xingRedirectBaseUrl: string = "";
let gAMeasurementId: string = "";
if (process.env.REACT_APP_ENV === "production") {
  apiBaseUrl = "https://recruit.searchtalent.de";
  xingRedirectBaseUrl = "https://jobs.searchtalent.de";
  recunitedBaseUrl = "https://recunited.searchtalent.de";
  gAMeasurementId = "G-C9GM0ZSF75";
} else if (process.env.REACT_APP_ENV === "staging") {
  apiBaseUrl = "https://recruit-staging.searchtalent.de";
  xingRedirectBaseUrl = "https://jobs-staging.searchtalent.de";
  recunitedBaseUrl = "https://recunited-staging.searchtalent.de";
} else {
  apiBaseUrl = "http://localhost:4560";
  recunitedBaseUrl = "http://localhost:3000";
  xingRedirectBaseUrl = "https://jobs-staging.searchtalent.de"; // no localhost redirect possible...
}

const CLIENT_ID = "rq1rNoJgx81cnqBxx4FGVQ1K";
const CLIENT_SECRET = "C657Kz6ibn3Z6yEgq32lyluzxHiFTlh84k38z47aZSGMo93D";

export {
  apiBaseUrl,
  xingRedirectBaseUrl,
  gAMeasurementId,
  recunitedBaseUrl,
  CLIENT_ID,
  CLIENT_SECRET,
};
