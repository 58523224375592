import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React from "react";
import { ApplicationSectionProps } from "../../../types/jobdetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    recruiterImage: {
      [theme.breakpoints.down("sm")]: {
        height: 50,
        width: 50,
        borderRadius: 5,
      },
      [theme.breakpoints.up("sm")]: {
        height: 75,
        width: 75,
        borderRadius: 7,
      },
      [theme.breakpoints.up("lg")]: {
        height: 100,
        width: 100,
        borderRadius: 10,
      },
    },
  })
);

const RecruiterImage: React.FC<ApplicationSectionProps> = ({
  recruiterName,
  recruiterImgURL,
}) => {
  const classes = useStyles();
  return (
    <img
      alt={recruiterName}
      src={recruiterImgURL ? recruiterImgURL : ""}
      className={classes.recruiterImage}
    />
  );
};

export default RecruiterImage;
