import { Box, Button, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryButtonText: {
      fontWeight: "bold",
      color: theme.palette.primary.dark,
    },
  })
);

interface SecondaryBtnProps {
  label: string | Node;
  onClick?: any;
  icon?: any;
  size?: "small" | "medium" | "large" | undefined;
  fullWidth?: boolean;
  variant?: "contained" | "outlined" | "text";
  href?: string;
  component?: "span";
  target?: "_blank";
}

const SecondaryBtn: React.FC<SecondaryBtnProps> = ({
  label,
  size,
  onClick,
  fullWidth = true,
  variant = "outlined",
  icon,
  href,
  component = "span",
  target,
}) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth={fullWidth}
      onClick={onClick}
      color="primary"
      startIcon={icon}
      variant={variant}
      size={size ? size : "medium"}
      href={href}
      component={component}
    >
      <Box className={classes.secondaryButtonText}>{label}</Box>
    </Button>
  );
};

export default SecondaryBtn;
