import { Grid, Dialog, DialogContent, TextField } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik, useField, useFormik } from "formik";
import { useState } from "react";
import LANGUAGE_LIST from "../../../utils/LanguageList";
import { XING_LANGUAGE_LEVELS_MAPPING } from "../../../utils/XingUtils";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import FormStaticAutocomplete from "../../Molecules/FormFields/FormStaticAutocomplete";
import FormSelect from "../../Molecules/FormFields/FormSelect";
import ListFormField from "../../Molecules/FormFields/ListFormField";
import * as yup from "yup";
import Title from "../../Atoms/Typography/Title";
import Text from "../../Atoms/Typography/Text";
import { LanguageKeyFact, Languages } from "../../../types/jobdetails";
import { Deck } from "@mui/icons-material";
import { values } from "lodash";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      paddingBottom: 32,
      display: "flex",
      justifyContent: "center",
    },
    formFullWidth: {
      width: "100%",
    },
  })
);

interface LanguageFormValues {
  language: string;
  level: string;
}

const schema = yup.object({
  language: yup.string().required("Bitte Sprache angeben"),
  level: yup.string().required("Bitte Level angeben"),
});

interface LanguageFormPartProps {
  languages: Languages;
  setLanguages: (fieldName: string, language: Languages) => void;
  fieldName: string;
}

const LanguageFormPart: React.FC<LanguageFormPartProps> = ({
  languages,
  setLanguages,
  fieldName,
}) => {
  const formValueToList = (modelValue: { [key: string]: string }) => {
    return Object.entries(modelValue).map(([key, value]) => {
      return { primary: key, secondary: value };
    });
  };

  const [editKey, setEditKey] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleEditLanguage = (value: LanguageFormValues, key: string) => {
    let newLanguages = languages;
    delete newLanguages[key];
    newLanguages[value.language] = value.level;
    setLanguages(fieldName, newLanguages);
    setDialogOpen(false);
  };

  const handleAddLanguage = (value: LanguageFormValues) => {
    let newLanguages = languages;
    newLanguages[value.language] = value.level;
    setLanguages(fieldName, newLanguages);
    setDialogOpen(false);
  };

  const handleDeleteLanguage = (key: string) => {
    let newLanguages = languages;
    delete newLanguages[key];
    setLanguages(fieldName, newLanguages);
  };

  const initialValues: LanguageFormValues = { language: "", level: "" };

  const handleSubmit = async (values: LanguageFormValues) => {
    if (editKey !== "") {
      handleEditLanguage(values, editKey);
      setEditKey("");
    } else {
      handleAddLanguage(values);
    }
  };

  const formik = useFormik({
    initialValues:
      editKey !== ""
        ? { language: editKey, level: languages[editKey] }
        : initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const classes = useStyles();
  return (
    <Grid container item xs={12} spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Sprachkenntnisse" />
      </Grid>
      {/* List of languages */}
      <Grid item xs={12}>
        {formValueToList(languages).length > 0 ? (
          <ListFormField
            data={formValueToList(languages)}
            handleDeleteEntry={handleDeleteLanguage}
            setEditKey={setEditKey}
            setDialogOpen={setDialogOpen}
          />
        ) : (
          <Text text="Noch keine Sprachkenntnisse hinzugefügt" />
        )}
      </Grid>
      {/* Add language button */}
      <Grid item xs={12}>
        <SecondaryBtn
          label="Weitere Sprache hinzufügen"
          onClick={() => {
            setDialogOpen(true);
          }}
          fullWidth={false}
          variant="text"
        />
      </Grid>
      {/* Add language dialog form */}
      <Dialog
        onClose={() => {
          setDialogOpen(false);
          setEditKey("");
        }}
        maxWidth="sm"
        fullWidth
        open={isDialogOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.formFullWidth}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <FormStaticAutocomplete
                  required
                  label="Sprache"
                  name="language"
                  menuItems={LANGUAGE_LIST}
                  error={
                    formik.touched.language && formik.errors.language
                      ? formik.errors.language
                      : ""
                  }
                  setFieldValue={formik.setFieldValue}
                  value={formik.values.language}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  required
                  label="Level"
                  name="level"
                  menuItems={Object.values(XING_LANGUAGE_LEVELS_MAPPING)}
                  error={
                    formik.touched.level && formik.errors.level
                      ? formik.errors.level
                      : ""
                  }
                  value={formik.values.level}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimaryBtn
                  label={editKey !== "" ? "Änderungen anwenden" : "Hinzufügen"}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default LanguageFormPart;
