import { DialogContent, Grid, Dialog, Hidden, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Form, Formik, FormikProps, useField, useFormik } from "formik";
import { useState } from "react";
import {
  ApplicationFormValues,
  WorkExperience,
} from "../../../types/jobdetails";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import CheckBoxFormField from "../../Molecules/FormFields/CheckBoxFormField";
import DatePickerField, {
  useContainerStyles,
} from "../../Molecules/FormFields/DatePickerField";
import TimelineFormField from "../../Molecules/FormFields/TimelineFormField";
import * as yup from "yup";
import FormInput from "../../Molecules/FormFields/FormInput";
import Title from "../../Atoms/Typography/Title";
import Text from "../../Atoms/Typography/Text";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      paddingBottom: 32,
      display: "flex",
      justifyContent: "center",
    },
  })
);

interface WorkExperienceFormPartProps {
  workExperience: WorkExperience[];
  setWorkExperience: (
    fieldName: string,
    workExperience: WorkExperience[]
  ) => void;
  fieldName: string;
}

const WorkExperienceFormPart: React.FC<WorkExperienceFormPartProps> = ({
  workExperience,
  setWorkExperience,
  fieldName,
}) => {
  const formValueToTimeline = (modelValue: WorkExperience[]) => {
    return modelValue.map((value: WorkExperience) => {
      return {
        subtitle: value.title,
        description: value.company,
        from: value.from,
        to: value.tillToday ? "heute" : value.to,
      };
    });
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const handleAddStation = (values: WorkExperience) => {
    let newTotalWorkExperience = workExperience;
    newTotalWorkExperience.push(values);
    setWorkExperience(fieldName, newTotalWorkExperience);
    setDialogOpen(false);
  };

  const handleUpdateStation = (values: WorkExperience, index: number) => {
    let newTotalWorkExperience = workExperience;
    newTotalWorkExperience[index] = values;
    setWorkExperience(fieldName, newTotalWorkExperience);
    setDialogOpen(false);
  };

  const handleDeleteStation = (index: number) => {
    let newTotalWorkExperience = workExperience;
    newTotalWorkExperience.splice(index, 1);
    setWorkExperience(fieldName, newTotalWorkExperience);
  };

  const handleSubmit = async (values: WorkExperience) => {
    if (editIndex >= 0) {
      handleUpdateStation(values, editIndex);
      setEditIndex(-1);
    } else {
      handleAddStation(values);
    }
  };

  const initialValues: WorkExperience = {
    title: "",
    company: "",
    description: "",
    tillToday: false,
    from: undefined,
    to: undefined,
  };

  const schema = yup.object({
    title: yup.string().required("Bitte Jobbezeichnung angeben"),
    company: yup.string().required("Bitte Unternehmen angeben"),
  });

  const formik = useFormik({
    initialValues: editIndex >= 0 ? workExperience[editIndex] : initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const classes = useStyles();
  const datePickerContainerClasses = useContainerStyles();

  return (
    <Grid item container xs={12} spacing={2} justifyContent="center">
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Werdegang" />
      </Grid>
      {/* Timeline */}
      <Grid item xs={12}>
        {workExperience.length > 0 ? (
          <TimelineFormField
            handleDeleteStation={handleDeleteStation}
            setEditIndex={setEditIndex}
            setDialogOpen={setDialogOpen}
            data={formValueToTimeline(workExperience)}
          />
        ) : (
          <Text text="Noch keine Erfahrung hinzugefügt" />
        )}
      </Grid>
      {/* Add station button */}
      <Grid item xs={12}>
        <SecondaryBtn
          label="Weitere Arbeitsstation hinzufügen"
          onClick={() => {
            setDialogOpen(true);
          }}
          fullWidth={false}
          variant="text"
        />
      </Grid>
      {/* Add station dialog form */}
      <Dialog
        onClose={() => {
          setDialogOpen(false);
          setEditIndex(-1);
        }}
        maxWidth="sm"
        open={isDialogOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Jobbezeichnung"
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  variant="standard"
                  helperText={
                    formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : ""
                  }
                  {...formik.getFieldProps("title")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Unternehmen"
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  variant="standard"
                  helperText={
                    formik.errors.company && formik.touched.company
                      ? formik.errors.company
                      : ""
                  }
                  {...formik.getFieldProps("company")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Beschreibung der Tätigkeit"
                  name="description"
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={datePickerContainerClasses.datePickerContainer}
              >
                <DatePickerField
                  label="Von"
                  name="from"
                  value={formik.values.from}
                  setFieldValue={formik.setFieldValue}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={datePickerContainerClasses.datePickerContainer}
              >
                <DatePickerField
                  label="Bis"
                  name="to"
                  value={formik.values.to}
                  disabled={Boolean(formik.values.tillToday)}
                  setFieldValue={formik.setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckBoxFormField
                  label="bis heute"
                  name="tillToday"
                  handleChange={formik.handleChange}
                  value={formik.values.tillToday}
                />
              </Grid>
              <Hidden smDown>
                <Grid item sm={6} />
              </Hidden>
              <Grid item xs={12}>
                <PrimaryBtn
                  label={editIndex >= 0 ? "Änderungen anwenden" : "Hinzufügen"}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default WorkExperienceFormPart;
