import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SearchFormValues } from "../../../types/jobportal";

interface MultiSelectFormFieldProps {
  label: string;
  name: string;
  updateAmountFound: (values: SearchFormValues) => void;
  values: SearchFormValues;
  options: Array<string>;
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  value?: string[];
}

const MultiSelectFormField: FC<MultiSelectFormFieldProps> = ({
  label,
  options,
  updateAmountFound,
  values,
  name,
  handleChange,
  value,
}) => {
  useEffect(() => {
    updateAmountFound({ ...values, ...{ [name]: value } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, name]);

  return (
    <FormControl variant="standard" fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        multiple
        value={value}
        name={name}
        onChange={handleChange}
        renderValue={(selected) => (selected as string[]).join(", ")}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Checkbox
              checked={value && value.indexOf(option) > -1}
              color="primary"
            />
            <ListItemText primary={option} className={"mobile-select"} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectFormField;
