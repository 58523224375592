import { Divider, Grid } from "@mui/material";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import Title from "../../Atoms/Typography/Title";

interface AccountSettingsProps {
  setIsDeleteDialogShown: (isDeleteDialogShown: boolean) => void;
}

export const AccountSettings: React.FC<AccountSettingsProps> = ({
  setIsDeleteDialogShown,
}) => {
  return (
    <Grid item container spacing={3}>
      <Grid item container direction="column" xs={12} spacing={1}>
        <Grid item xs={12}>
          <Title size="medium" text="Account Einstellungen" />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <PrimaryBtn label="Passwort ändern" color="primary" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <PrimaryBtn
          label="Konto löschen"
          color="error"
          onClick={() => {
            setIsDeleteDialogShown(true);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
