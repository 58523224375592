import { Grid, Slide, Theme, Hidden } from "@mui/material";
import Title from "../Atoms/Typography/Title";
import { makeStyles } from "@mui/styles";
import Text from "../Atoms/Typography/Text";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "80vh",
  },
  [theme.breakpoints.up("lg")]: {
    gridContainerFullHeight: {
      minWidth: "100%",
      minHeight: "80vh ",
    },
  },
  [theme.breakpoints.down("lg")]: {
    gridContainerFullHeight: {
      height: "none",
    },
  },
}));

const AfterSignUpPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={500}>
      <Grid container spacing={1} className={classes.root}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          alignContent="center"
          spacing={4}
          className={classes.gridContainerFullHeight}
        >
          <Grid item xs={12} />
          <Grid item container xs spacing={2}>
            <Grid item xs={12}>
              <Title text="Geschafft!" size="extraLarge" />
            </Grid>
            <Grid item xs={12}>
              <Text text="Im Postfach deiner von dir angegeben Mailadresse sollte nun eine Bestätigungsemail eingegangen sein. Klicke auf den dort angegebenen Link um den Registrierungsvorgang abzuschliessen" />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <img alt="Success" src="/success.png" />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item container justifyContent="center">
              <img alt="Success" src="/success.png" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default AfterSignUpPage;
