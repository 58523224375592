import { Grid } from "@mui/material";
import JobResultContainer from "../JobResultContainer";
import { SearchResultsWebProps } from "../../../types/searchresults";

const SearchResultsWeb: React.FC<SearchResultsWebProps> = ({
  searchResults,
}) => {
  return (
    <Grid container item spacing={2} xs={12} alignItems="center">
      <JobResultContainer searchResults={searchResults} />
    </Grid>
  );
};

export default SearchResultsWeb;
