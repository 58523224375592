import { Schema } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Hidden, InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, useFormik } from "formik";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutocompleteModel, SearchFormValues } from "../../types/jobportal";
import { apiBaseUrl } from "../../utils/environmentDependencies";
import { fireGAEvent, GA_CATEGORY_SEARCH } from "../../utils/googleAnalytics";
import DynamicAutocomplete from "../Molecules/FormFields/DynamicAutocomplete";
import FilteredSearch from "../Molecules/LandingPage/FilteredSearch";

const useStyles = makeStyles(() =>
  createStyles({ fullWidthForm: { width: "100%" } })
);

type AutocompleteTypes = "keywords" | "locations";

const SearchForm: React.FC = () => {
  const [keywordOptions, setKeywordOptions] = useState<AutocompleteModel[]>([]);
  const [locationOptions, setLocationOptions] = useState<AutocompleteModel[]>(
    []
  );
  const [amountSearchResults, setAmountSearchResults] = useState<number>(0);
  const [isLoadingAmount, setIsLoadingAmount] = useState<boolean>(false);

  const initialValues: SearchFormValues = {
    profession: { word: "", amount: 0 },
    location: { word: "", amount: 0 },
    radius: 0,
    jobType: [],
    remote: false,
    industry: [],
  };

  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollSmoothly = () => {
    window.scroll({
      top: 100,
      left: 0,
      behavior: "smooth",
    });
  };

  const getAutocompleteOptions = (
    type: AutocompleteTypes,
    words: string,
    values: SearchFormValues
  ) => {
    let params = {
      keywords: values.profession.word,
      locations: values.location.word,
    };

    params[type] = words;

    let urlSearch = new URLSearchParams(params);

    if (values.remote) {
      urlSearch.append("remote_only", values.remote?.toString());
    }
    if (values.radius) {
      urlSearch.append("radius", values.radius.toString());
    }
    if (values.jobType) {
      values.jobType.forEach((value) => {
        urlSearch.append("job_type", value);
      });
    }
    if (values.industry) {
      values.industry.forEach((value) => {
        urlSearch.append("industry", value);
      });
    }

    urlSearch.append("page", "1");

    fetch(
      `${apiBaseUrl}/job_portal/autocomplete_${type}?${urlSearch.toString()}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (type === "keywords") {
          setKeywordOptions(data[type]);
        } else if (type === "locations") {
          setLocationOptions(data[type]);
        }
      });
  };

  const updateAmountFound = (values: SearchFormValues) => {
    setIsLoadingAmount(true);
    let params = {
      keywords: values.profession.word,
      place: values.location.word,
    };

    let urlSearch = new URLSearchParams(params);

    if (values.remote) {
      urlSearch.append("remote_only", values.remote?.toString());
    }
    if (values.radius) {
      urlSearch.append("radius", values.radius.toString());
    }
    if (values.jobType) {
      values.jobType.forEach((value) => {
        urlSearch.append("job_type", value);
      });
    }
    if (values.industry) {
      values.industry.forEach((value) => {
        urlSearch.append("industry", value);
      });
    }

    urlSearch.append("page", "1");

    fetch(`${apiBaseUrl}/job_portal/jobs?${urlSearch.toString()}`)
      // fetch(`${apiBaseUrl}/job_portal/jobs?${searchParamsURL}&user_id=1`)
      .then((response) => response.json())
      .then((data) => {
        setAmountSearchResults(data.total_amount);
        setIsLoadingAmount(false);
      });
  };

  useEffect(() => {
    if (mobileScreen) scrollSmoothly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch(`${apiBaseUrl}/job_portal/jobs`)
      .then((res) => res.json())
      .then((data) => {
        setAmountSearchResults(data.total_amount);
      });
  }, []);

  const handleSubmit = async (values: SearchFormValues) => {
    fireGAEvent(
      GA_CATEGORY_SEARCH,
      GA_CATEGORY_SEARCH.actions.executeAdvancedSearch,
      `keyword: ${values.profession}; location: ${values.location}`
    );

    let params = {
      keywords: values.profession.word,
      place: values.location.word,
    };

    let urlSearch = new URLSearchParams(params);

    if (values.remote) {
      urlSearch.append("remote_only", values.remote?.toString());
    }
    if (values.radius) {
      urlSearch.append("radius", values.radius.toString());
    }
    if (values.jobType) {
      values.jobType.forEach((value) => {
        urlSearch.append("job_type", value);
      });
    }
    if (values.industry) {
      values.industry.forEach((value) => {
        urlSearch.append("industry", value);
      });
    }

    urlSearch.append("page", "1");

    history.push({
      pathname: "/search",
      search: urlSearch.toString(),
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.fullWidthForm}>
      <Grid container item xs={12} spacing={2} alignItems="center">
        <Grid item xs={12} md={4.5}>
          <DynamicAutocomplete
            label={"Jobtitel, Firma oder Fähigkeit"}
            name={"profession"}
            options={keywordOptions}
            updateAmountFound={updateAmountFound}
            setFieldValue={formik.setFieldValue}
            getAutocompleteOptions={getAutocompleteOptions}
            values={formik.values}
            icon={<SearchIcon />}
            type="keywords"
          />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <DynamicAutocomplete
            label={"Stadt, PLZ oder Bundesland"}
            name={"location"}
            options={locationOptions}
            updateAmountFound={updateAmountFound}
            setFieldValue={formik.setFieldValue}
            getAutocompleteOptions={getAutocompleteOptions}
            values={formik.values}
            icon={<RoomIcon />}
            type="locations"
          />
        </Grid>
        <Hidden mdDown>
          <Grid item md={3}>
            <LoadingButton
              type="submit"
              loading={isLoadingAmount}
              disabled={!Boolean(amountSearchResults)}
              variant="contained"
              size="large"
              fullWidth
              style={
                isLoadingAmount ? {} : { color: "white", fontWeight: "bold" }
              }
            >
              {amountSearchResults} {amountSearchResults === 1 ? "Job" : "Jobs"}{" "}
              anzeigen
            </LoadingButton>
          </Grid>
        </Hidden>
        <Grid item container xs={12}>
          <FilteredSearch
            updateAmountFound={updateAmountFound}
            formik={formik}
          />
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              loading={isLoadingAmount}
              disabled={!Boolean(amountSearchResults)}
              variant="contained"
              size="large"
              fullWidth
              style={
                isLoadingAmount ? {} : { color: "white", fontWeight: "bold" }
              }
            >
              {amountSearchResults} {amountSearchResults === 1 ? "Job" : "Jobs"}{" "}
              anzeigen
            </LoadingButton>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
};

export default SearchForm;
