import { Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { TabPanelProps, TabsMenuProps } from "../../../types/jobdetails";
import JobDescriptionSection from "../JobDescriptionSection";
import KeyFacts from "../KeyFacts";
import Recunited from "./Recunited";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabsMenu: React.FC<TabsMenuProps> = ({
  keyFacts,
  description,
  id,
  jobOverview,
  isRecunitedAvailable,
  storageMatchingScore,
  matchingScore,
}) => {
  const [value, setValue] = React.useState(0);
  const [jobDetailsLoaded, setJobDetailsLoaded] = useState<Boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setJobDetailsLoaded(false);
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Jobbeschreibung" {...a11yProps(0)} />
          {/* nur bei matchingScores */}
          {isRecunitedAvailable && !storageMatchingScore && !matchingScore && (
            <Tab label="Passen wir Zusammen?" {...a11yProps(1)} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <KeyFacts {...keyFacts} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <JobDescriptionSection
              description={description.description}
              setJobDetailsLoaded={setJobDetailsLoaded}
            />
          </Grid>
        </Grid>
      </TabPanel>
      {isRecunitedAvailable && !storageMatchingScore && !matchingScore && (
        <TabPanel value={value} index={1}>
          {" "}
          <Recunited jobOverview={jobOverview} />
        </TabPanel>
      )}
    </Box>
  );
};
