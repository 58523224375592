import { Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import SecondaryBtn from "../Atoms/Buttons/SecondaryBtn";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";

const SuccessMessage: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title size="medium" text="Geschafft!" />
      </Grid>
      <Grid item xs={12}>
        <Text text="Sie haben sich erfolgreich beworben! Wir werden uns schnellstmöglich bei Ihnen melden." />
      </Grid>
      <Grid item xs={12}>
        <SecondaryBtn label={"Weitere Stellen suchen"} onClick={handleClick} />
      </Grid>
    </Grid>
  );
};

export default SuccessMessage;
