// NOTE: pulled from recunited. remains to be seen if we want to use it here

import {
  ApplicationFormValues,
  Education,
  WorkExperience,
} from "../types/jobdetails";
import { apiBaseUrl, xingRedirectBaseUrl } from "./environmentDependencies";

export const XING_ACADEMIC_TITLES = [
  "Dr.",
  "Prof.",
  "Prof. Dr.",
  "Ing.",
  "Dipl.-Ing.",
  "Mag.",
];

export const XING_LANGUAGE_ABBREVATIONS: { [key: string]: string } = {
  de: "Deutsch",
  en: "Englisch",
  es: "Spanisch",
  fi: "Finnisch",
  fr: "Französisch",
  hu: "Ungarisch",
  it: "Italienisch",
  ja: "Japanisch",
  ko: "Koreanisch",
  nl: "Niederländisch",
  pl: "Polnisch",
  pt: "Portugiesisch",
  ru: "Russisch",
  sv: "Schwedisch",
  tr: "Türkisch",
  zh: "Chinesisch",
  ro: "Rumänisch",
  no: "Norwegisch",
  cs: "Tschechisch",
  el: "Griechisch",
  da: "Dänisch",
  ar: "Arabisch",
  he: "Hebräisch",
};

export const XING_LANGUAGE_LEVELS_MAPPING: { [key: string]: string } = {
  BASIC: "Basiswissen",
  GOOD: "Gut",
  FLUENT: "Fließend",
  NATIVE: "Muttersprache",
};

export const XING_LANGUAGE_LEVEL_MAP_NULL = "Unbestimmt";

export const convertXingDate = (date: string) => {
  try {
    return "01." + date.split("-")[1] + "." + date.split("-")[0];
  } catch {
    return null;
  }
};

export const retrieveXingData = (
  code: string,
  redirectURI?: string
): Promise<ApplicationFormValues> => {
  return fetch(
    `${apiBaseUrl}/job_portal/get_xing_data_searchtalent?auth_code=${code}&redirect_uri=${redirectURI}`
  )
    .then((response) => response.json())
    .then((userData) => {
      // Format title from gender
      let title = "";
      switch (userData.personal_details.gender) {
        case "m":
          title = "Herr";
          break;
        case "f":
          title = "Frau";
          break;
        default:
          title = "Divers";
      }

      // Get phone number
      let phone = "";
      if (userData.personal_details.mobile_phone) {
        phone = userData.personal_details.mobile_phone;
      } else if (userData.personal_details.phone) {
        phone = userData.personal_details.phone;
      }

      // Format languages
      let languages: { [key: string]: string } = {};
      for (const [language, level] of Object.entries(
        userData.personal_details.languages
      )) {
        if (level) {
          languages[XING_LANGUAGE_ABBREVATIONS[language]] =
            XING_LANGUAGE_LEVELS_MAPPING["" + level];
        }
      }

      // Format education
      let education: Education[] = userData.educational_details.schools.map(
        (station: { [key: string]: string }) => {
          return {
            location: station.name,
            field: station.subject,
            degree: station.degree,
            from: convertXingDate(station.begin_date),
            to: convertXingDate(station.end_date),
            tillToday: station.until_now,
          };
        }
      );

      // Format work experience
      let workExperience: WorkExperience[] =
        userData.professional_details.companies.map(
          (station: { [key: string]: string }) => {
            return {
              title: station.title,
              company: station.name,
              description: station.description,
              from: convertXingDate(station.begin_date),
              to: convertXingDate(station.end_date),
              tillToday: station.until_now,
            };
          }
        );

      let formattedData = {
        baseUserInfo: {
          firstName: userData.personal_details.first_name,
          lastName: userData.personal_details.last_name,
          email: userData.personal_details.active_email
            ? userData.personal_details.active_email
            : "",
          title,
          academicTitle: userData.personal_details.academic_title
            ? userData.personal_details.academic_title
            : "",
          phone,
        },
        skills: userData.personal_details.haves ?? [],
        languages,
        education,
        workExperience,
        dsgvo: false,
        expectedSalary: "",
        earliestDateToStart: "",
        noticePeriod: "",
      };
      return formattedData;
    });
};
