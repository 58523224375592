import { Chip, Divider, Grid } from "@mui/material";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import Text from "../../Atoms/Typography/Text";
import Title from "../../Atoms/Typography/Title";
import { ProfileData } from "../../Templates/ProfilePage";

interface ProfileSectionProps {
  data: ProfileData;
}

export const ProfileSection: React.FC<ProfileSectionProps> = ({ data }) => {
  return (
    <Grid item container xs={12}>
      <Grid item container direction="column" xs={12} spacing={1}>
        <Grid item xs={12}>
          <Title size="medium" text="Persönlich" />
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={12} spacing={1}>
        {data.gender && data.first_name && data.last_name && (
          <Grid item xs={12}>
            <Title size="small" text={"Name"} />
            <Text
              text={`${data.gender} ${data.first_name} ${data.last_name}`}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {data.mobile_phone && (
            <>
              <Title text="Telefonnummer" size="small" />
              <Text text={data.mobile_phone} />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {data.active_email && (
            <>
              <Title size="small" text="Email" />
              <Text text={data.active_email.toLowerCase()} />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {data.earliest_start_date && (
            <>
              <Title size="small" text="Verfügbar ab" />
              <Text text={data.earliest_start_date} />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {data.languages && data.languages.length > 0 && (
            <>
              <Title size="small" text="Sprachen" />
              {data?.languages?.map((language, index) => {
                return (
                  <Chip
                    color="primary"
                    size="medium"
                    label={language}
                    key={index}
                  />
                );
              })}
            </>
          )}
        </Grid>
        {data.skills && (
          <Grid item container>
            <Grid item xs={12}>
              <Title size="small" text="Fähigkeiten" />
              <Grid item xs={12} md={9}>
                {data.skills?.map((skill, index) => {
                  return (
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="medium"
                      label={skill}
                      key={index}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={3}>
          <Grid item xs={12} md={4}>
            <PrimaryBtn label={"persönliche Daten bearbeiten"} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};
