import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Divider,
  Grid,
  Grow,
  Hidden,
  IconButton,
  Link,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ApplicationFormValues,
  ApplicationSectionProps,
  JobDescriptionProps,
  JobOverviewProps,
  KeyFactsProps,
  RecruiterProps,
} from "../../types/jobdetails";
import {
  apiBaseUrl,
  xingRedirectBaseUrl,
} from "../../utils/environmentDependencies";
import {
  fireGAEvent,
  fireGAPageView,
  GA_CATEGORY_JOB_DETAILS,
} from "../../utils/googleAnalytics";
import createGoogleJobJSON from "../../utils/googleJobs";
// import { useAuthentication } from "../../utils/useAuthentication";
import { useTheme } from "@mui/material/styles";
import Title from "../Atoms/Typography/Title";
import Footer from "../Molecules/Footer";
import ApplicationSection from "../Organisms/JobDetails/ApplicationSection";
import { TabsMenu } from "../Organisms/JobDetails/TabsMenu";
import OverviewSection from "../Organisms/OverviewSection";
import JobDescriptionSection from "../Organisms/JobDescriptionSection";
import KeyFacts from "../Organisms/KeyFacts";
import { retrieveXingData } from "../../utils/XingUtils";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";

const JobDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [storageMatchingScore, setStorageMatchingScore] = useState<number>(
    parseInt(localStorage.getItem(id) ?? "0", 10)
  );
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const [keyFacts, setKeyFacts] = useState<KeyFactsProps>({});
  const [calendly, setCalendly] = useState("");
  const [recruiter, setRecruiter] = useState<RecruiterProps>({});
  const [jobDescription, setJobDescription] = useState<JobDescriptionProps>({
    description: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setNotFound] = useState<boolean>(false);
  const [jobOverview, setJobOverview] = useState<JobOverviewProps>({
    title: "",
    company: { id: 0, name: "", url: "" },
    address: { zipCode: "", city: "", streetNameAndNumber: "" },
    jobId: id,
  });
  const [userData, setUserData] = useState<ApplicationFormValues>();
  const [isRecunitedAvailable, setIsRecunitedAvailable] =
    useState<boolean>(false);

  const matchingScore = new URLSearchParams(useLocation().search).get(
    "matchingScore"
  );

  if (id && matchingScore) {
    localStorage.setItem(id, matchingScore);
  }

  const history = useHistory();
  // const { authFetch, isAuthenticated } = useAuthentication();

  // check if code parameter exists in query
  const code = new URLSearchParams(useLocation().search).get("code");

  // const recunitedStyle = {
  //   container: {
  //     backgroundColor: 'rgba(34, 85, 65, 0.8)',
  //   },
  // };

  useEffect(() => {
    fireGAPageView();
    setIsLoading(true);
    if (code) {
      retrieveXingData(code, `${xingRedirectBaseUrl}/jobdetails/${id}`)
        .then((data) => {
          setUserData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch(`${apiBaseUrl}/job_portal/job_details?id=${id}`)
      .then((response) => {
        if (response?.status !== 200) {
          setNotFound(true);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setIsLoading(false);
        console.log(data);
        document.title = data.title + " | Searchtalent";
        var googleJobsScript = document.createElement("script");
        googleJobsScript.async = true;
        googleJobsScript.type = "application/ld+json";

        const googleJobJSON = createGoogleJobJSON(data);
        googleJobsScript.innerHTML = JSON.stringify(googleJobJSON);
        document.head.appendChild(googleJobsScript);
        setRecruiter(data.recruiter);
        setCalendly(data.calendly);
        setKeyFacts(data);
        setJobDescription({ description: data.description });
        setJobOverview({
          address: {
            zipCode: data?.address?.zip_code,
            city: data?.address?.city,
            streetNameAndNumber: data?.address?.street_name_and_number,
          },
          company: data?.company,
          title: data?.title,
          jobId: id,
        });
        setIsRecunitedAvailable(false);

        console.log(data);
        let header = document.getElementById("header");
        if (header) {
          if (data.industry) {
            let myIndustry = data.industry.split(" ");
            let industry = myIndustry[0];
            myIndustry = industry.split("/");
            industry = myIndustry[0];
            industry = industry.replace(/[^a-zA-Z ]/g, "");
            industry = industry.toLocaleLowerCase();
            header.classList.add("header-" + industry);
          }
          header.classList.add("visible");
        }
      })
      .catch((error) => {
        console.log(error);
        setNotFound(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  if (!isLoading) {
    return (
      <Grow in mountOnEnter unmountOnExit timeout={1000}>
        <Grid
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          container
          spacing={4}
        >
          <Grid item xs={12} />
          <Grid item container xs={12} md={12}>
            <Grid item xs={12}>
              <OverviewSection
                {...jobOverview}
                isRecunitedAvailable={isRecunitedAvailable}
                logoLink={jobOverview.company.logo_url}
              />
            </Grid>
          </Grid>
          {isRecunitedAvailable && !storageMatchingScore && !matchingScore ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item container alignContent="center" xs={12}>
                <Title
                  size="small"
                  inline
                  vCenter
                  text={
                    `${jobOverview.company.name} steht für Transparenz und nutzt deshalb` +
                    (isMobile ? " Recunited" : "")
                  }
                />
                <Hidden mdDown>
                  <Link href="https://recunited.com">
                    <img
                      alt="recunited-logo"
                      src="/recunited_logo.png"
                      width="auto"
                      height="40px"
                    />
                  </Link>
                </Hidden>
              </Grid>
              <Grid item xs={12}>
                <TabsMenu
                  keyFacts={keyFacts}
                  description={jobDescription}
                  id={id}
                  jobOverview={jobOverview}
                  isRecunitedAvailable={isRecunitedAvailable}
                  storageMatchingScore={storageMatchingScore}
                  matchingScore={matchingScore}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Divider light className="short-divider" />
              </Grid>
              <Grid item xs={12}>
                <KeyFacts {...keyFacts} />
              </Grid>
              <Grid item xs={12}>
                <Divider light className="short-divider" />
              </Grid>
              <Grid item xs={12}>
                <JobDescriptionSection
                  description={jobDescription.description}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider light className="short-divider" />
          </Grid>
          <Grid item xs={12} md={5}>
            <ApplicationSection
              isRecunitedAvailable={isRecunitedAvailable}
              jobAdId={id ? parseInt(id) : undefined}
              prefilledUserData={userData}
              recruiterName={recruiter?.name ? recruiter?.name : ""}
              recruiterImgURL={recruiter?.img ? recruiter?.img : ""}
              recruiterEmail={recruiter?.email ? recruiter?.email : ""}
              companyName={jobOverview?.company.name}
              calendly={calendly ? calendly : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider light className="short-divider" />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <IconButton
              aria-label="go-back"
              className="arrow-back"
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon color="primary" />
              <span> zurück zu den Stellen</span>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Grow>
    );
  } else {
    return (
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
};

export default JobDetails;
