import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface ListFormFieldProps {
  data: { primary: string; secondary: string }[];
  handleDeleteEntry: (key: string) => void;
  setEditKey: (key: string) => void;
  setDialogOpen: (isOpen: boolean) => void;
}

const ListFormField: React.FC<ListFormFieldProps> = ({
  data,
  handleDeleteEntry,
  setEditKey,
  setDialogOpen,
}) => {
  return (
    <List>
      {data.map((entry) => (
        <ListItem id={"ListItem-" + entry}>
          <ListItemText primary={entry.primary} secondary={entry.secondary} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => {
                setDialogOpen(true);
                setEditKey(entry.primary);
              }}
              size="large">
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                handleDeleteEntry(entry.primary);
              }}
              size="large">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default ListFormField;
