import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extraLargeHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 27,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 40,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 50,
      },
    },
    largeHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 23,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 35,
        marginBottom: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 40,
        marginBottom: "20px",
      },
    },
    mediumHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 27,
        marginBottom: "5px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 30,
        marginBottom: "5px",
      },
    },
    smallHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 19,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 22,
      },
    },
  })
);

interface TitleProps {
  text: string | undefined;
  size?: "small" | "medium" | "large" | "extraLarge";
  bold?: boolean;
  center?: boolean;
  noH?: boolean;
  inline?: boolean;
  vCenter?: boolean;
  style?: React.CSSProperties;
}

const Title: React.FC<TitleProps> = ({
  text,
  size = "medium",
  bold = false,
  center = false,
  noH = false,
  inline = false,
  vCenter = false,
  style,
}) => {
  const classes = useStyles();
  let usedClass = classes.mediumHeader;
  let variant: "h1" | "h2" | "h3" = "h2";
  let boldWeight = 800;
  switch (size) {
    case "small":
      variant = "h3";
      usedClass = classes.smallHeader;
      boldWeight = 700;
      break;
    case "medium":
      variant = "h2";
      usedClass = classes.mediumHeader;
      boldWeight = 500;
      break;
    case "large":
      variant = "h1";
      usedClass = classes.largeHeader;
      boldWeight = 550;
      break;
    case "extraLarge":
      variant = "h1";
      usedClass = classes.extraLargeHeader;
      boldWeight = 550;
      break;
  }

  const styleProps: any = {};
  if (vCenter) {
    styleProps.display = "inline-flex";
    styleProps.alignItems = "center";
  }

  return (
    <Typography
      textAlign={center ? "center" : undefined}
      fontWeight={bold ? boldWeight : undefined}
      className={usedClass}
      variant={variant}
      color="textPrimary"
      component={inline ? "span" : noH ? "p" : variant}
      style={{ ...styleProps, ...style }}
    >
      {text}
    </Typography>
  );
};

export default Title;
