import { Grid } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Title from "../../Atoms/Typography/Title";
import RecruitingProcessImage from "../../Atoms/Images/RecruitingProcessImage";

const RECRUITING_PROCESS = [
  { title: "Du bewirbst dich.", icon: "/step1.png" },
  {
    title:
      "Telefonat mit uns oder wir leiten deine Bewerbung direkt an den Kunden weiter.",
    icon: "/step2.png",
  },
  {
    title: "Wir oder der Kunde meldet sich direkt bei dir.",
    icon: "/step3.png",
  },
  { title: "Bewerbungsprozess beim Kunden geht los.", icon: "/step4.png" },
  { title: "Entscheidung!", icon: "/step5.png" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineConnector: {
      minHeight: "10px",
    },
    verticalCenterContent: {
      alignSelf: "center",
    },
    hideConnector: {
      backgroundColor: "revert",
    },
    imageHiddenConnectorOffset: {
      paddingTop: 24,
    },
  })
);

const RecruitingProcess: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container item alignContent="space-evenly">
      <Grid item xs={12}>
        <Title
          center
          size="large"
          text={"Recruiting-Prozess für Kandidat:innen"}
        />
      </Grid>
      <Grid item container xs={12} md={12} alignContent="center">
        <Grid item container sm={12} md={5} justifyContent="center">
          <img
            alt={"Recruiting-Prozess für Kandidat:innen"}
            src={"/vacancy.png"}
            width="100%"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={7}
          spacing={1}
          alignItems="space-between"
        >
          <Timeline>
            {RECRUITING_PROCESS.map((step, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
                  <TimelineSeparator>
                    <TimelineConnector
                      className={
                        classes.timelineConnector +
                        (index === 0 ? " " + classes.hideConnector : "")
                      }
                    />
                    <RecruitingProcessImage
                      title={step.title}
                      imagePath={step.icon}
                    />
                    <TimelineConnector
                      className={
                        classes.timelineConnector +
                        (index === RECRUITING_PROCESS.length - 1
                          ? " " + classes.hideConnector
                          : "")
                      }
                    />
                  </TimelineSeparator>
                  <TimelineContent className={classes.verticalCenterContent}>
                    <Title size="small" text={step.title} />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecruitingProcess;
