import { Grid } from "@mui/material";
import Title from "../../Atoms/Typography/Title";
import SearchForm from "../../Organisms/SearchForm";
import BadgesForTrust from "./BadgesForTrust";

const ContainerSearch: React.FC = () => {
  return (
    <Grid container item xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12} />
      <Grid item container xs={12} spacing={2} alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={12} md={6}>
          <Title
            size="extraLarge"
            text="Finde mit Searchtalent den Job, der genau zu dir passt."
            bold
          />
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12} md={6}>
          <img
            alt={"Searching for talents"}
            src={"/landingpage.png"}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <SearchForm />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Grid item xs={8} md={6} lg={6} style={{ textAlign: "right" }}>
          <BadgesForTrust />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContainerSearch;
