import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import Title from "../Atoms/Typography/Title";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Text from "../Atoms/Typography/Text";
import { apiBaseUrl } from "../../utils/environmentDependencies";

interface DeleteDialogProps {
  isDeleteDialogShown: boolean;
  setIsDeleteDialogShown: (isSignupShown: boolean) => void;
  id: number;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  setIsDeleteDialogShown,
  isDeleteDialogShown,
  id,
}) => {
  // still needs to be tested
  const handleSubmit = () => {
    fetch(`${apiBaseUrl}/job_portal/profile/${id}`, { method: "DELETE" })
      .then((response) => response.json())
      .then((data) => {})
      .catch((err) => {});
  };
  return (
    <Dialog
      onClose={() => {
        setIsDeleteDialogShown(false);
      }}
      maxWidth="sm"
      open={isDeleteDialogShown}
    >
      <DialogTitle>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="flex-start"
        >
          <WarningAmberIcon fontSize={"large"} color="error" />
          <Title size="medium" text={"Warnung"} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Text text="Sie sind dabei, Ihr Konto zu löschen. Möchten Sie Ihr Konto wirklich löschen?" />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <PrimaryBtn
                label="Ja, lösche mein Konto"
                color="error"
                onClick={handleSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <PrimaryBtn
                label="Nein!"
                color="primary"
                onClick={setIsDeleteDialogShown(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
