export const getKeycloakManager = () => {
  if (typeof process.env.REACT_APP_KEYCLOAK_HOST === "undefined") {
    return {
      tokenUrl:
        "http://localhost:8180/realms/Searchtalent/protocol/openid-connect/token",
      logOutUrl:
        "http://localhost:8180/realms/Searchtalent/protocol/openid-connect/logout",
    };
  } else {
    return {
      tokenUrl: `${process.env.REACT_APP_KEYCLOAK_HOST}:8180/realms/Searchtalent/protocol/openid-connect/token`,
      logOutUrl: `${process.env.REACT_APP_KEYCLOAK_HOST}:8180/realms/Searchtalent/protocol/openid-connect/logout`,
    };
  }
};