import { Grid, Slide } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import Title from "../Atoms/Typography/Title";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { fireGAPageView } from "../../utils/googleAnalytics";

const useStyles = makeStyles(() =>
  createStyles({ fullHeightContainer: { height: "80vh" } })
);

const NotFound: React.FC = () => {
  let history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    fireGAPageView();
  });
  return (
    <Slide direction="down" in mountOnEnter unmountOnExit timeout={500}>
      <Grid
        container
        className={classes.fullHeightContainer}
        xs={12}
        spacing={3}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item xs={5}>
          <img src={"/404.png"} alt="Page not found" width="100%" />
        </Grid>
        <Grid item xs={12}>
          <Title
            center
            size="medium"
            text="Sorry! Sieht so aus als ob die angeforderte Seite nicht mehr existiert."
          />
        </Grid>
        <Grid item xs={3}>
          <PrimaryBtn
            label="Zurück"
            onClick={() => {
              history.goBack();
            }}
          />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default NotFound;
