import {
  Grid,
  Dialog,
  DialogContent,
  Theme,
  useMediaQuery,
  DialogTitle,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useEffect, useState } from "react";
import { ApplicationSectionProps } from "../../../types/jobdetails";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import RecruiterImage from "../../Atoms/Images/RecruiterImage";
import ApplicationTypeSelection from "../../Templates/ApplicationTypeSelection";
import ApplicationForm from "../../Templates/ApplicationForm";
import CVApplication from "../../Templates/CVApplication";
import SuccessMessage from "../../Templates/SuccessMessage";
import Title from "../../Atoms/Typography/Title";
import Text from "../../Atoms/Typography/Text";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorMessage from "../../Templates/ErrorMessage";
import {
  fireGAEvent,
  GA_CATEGORY_JOB_DETAILS,
} from "../../../utils/googleAnalytics";
import RecunitedMatchingConfirm from "./Matching/RecunitedMatchingConfirm";
import RecunitedIntro from "../RecunitedIntro";
import { useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      paddingBottom: 32,
      display: "flex",
      justifyContent: "center",
    },
  })
);

const ApplicationOverview: React.FC<ApplicationSectionProps> = ({
  jobAdId,
  recruiterName,
  recruiterImgURL,
  prefilledUserData,
  isRecunitedAvailable,
  companyName,
  calendly,
  recruiterEmail,
}) => {
  const [storageMatchingScore, setStorageMatchingScore] = useState<number>(
    parseInt(localStorage.getItem(jobAdId ? jobAdId + "" : "0") ?? "0", 10)
  );
  const continueApplicationFromRecunited = new URLSearchParams(
    useLocation().search
  ).has("show-application-dialog");
  const [isApplicationDialogShown, setIsApplicationDialogShown] = useState(
    continueApplicationFromRecunited || false
  );
  const [applicationSectionShown, setApplicationSectionShown] = useState(
    isRecunitedAvailable && !storageMatchingScore ? "RECUNITED_INTRO" : ""
  );
  const [candidateId, setCandidateId] = useState<number>();
  const [recunitedId, setRecunitedId] = useState<number>();

  const isExtraSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const classes = useStyles();

  const recruiterButton = document.getElementsByClassName("recruiter-button");

  const [visible, setVisible] = useState(true);
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (recruiterButton[0] !== undefined) {
      let buttonBound = recruiterButton[0].getBoundingClientRect();
      setVisible(buttonBound.top > window.innerHeight);
    }
  }

  useEffect(() => {
    if (!storageMatchingScore) {
      setApplicationSectionShown("RECUNITED_INTRO");
    } else if (continueApplicationFromRecunited) {
      setApplicationSectionShown("");
    }
  }, [
    isRecunitedAvailable,
    storageMatchingScore,
    continueApplicationFromRecunited,
  ]);

  useEffect(() => {
    if (prefilledUserData) {
      setApplicationSectionShown("FORM");
      setIsApplicationDialogShown(true);
    }
  }, [prefilledUserData]);

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item>
          <RecruiterImage
            recruiterName={recruiterName}
            recruiterImgURL={recruiterImgURL}
          />
        </Grid>
        <Grid item>
          <Title noH size="medium" text={recruiterName} />
          <Grid item xs={6} spacing={2}>
            <Title noH size="small" text="Recruiter" />
            <Grid item xs={6}>
              <Link target="_blank" href={"mailto:" + recruiterEmail}>
                <EmailIcon color="primary" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PrimaryBtn
            onClick={() => {
              fireGAEvent(
                GA_CATEGORY_JOB_DETAILS,
                GA_CATEGORY_JOB_DETAILS.actions.clickApply,
                `id: ${jobAdId}`
              );
              setApplicationSectionShown(
                isRecunitedAvailable && !storageMatchingScore
                  ? "RECUNITED_INTRO"
                  : ""
              );
              setIsApplicationDialogShown(true);
            }}
            label="Jetzt bewerben"
            size="large"
            className={"recruiter-button"}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryBtn
            className={calendly ? "buttonvi" : "posfixb hidden"}
            onClick={() => {
              window.open(calendly);
            }}
            label="Termin mit Recruiter Vereinbaren"
            size="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            center
            text="Einfach CV einreichen, Formular ausfüllen oder mit deinem
            XING-Profil bewerben."
          />
        </Grid>
      </Grid>
      <Dialog
        fullScreen={isExtraSmallScreen && applicationSectionShown === "FORM"}
        onClose={() => {
          setIsApplicationDialogShown(false);
          if (recruiterButton !== undefined) {
            let buttonBound = recruiterButton[0].getBoundingClientRect();
            setTimeout(function () {
              setVisible(buttonBound.top > window.innerHeight);
            }, 250);
          }
        }}
        maxWidth="md"
        keepMounted
        open={isApplicationDialogShown}
      >
        {isExtraSmallScreen && applicationSectionShown === "FORM" && (
          <DialogTitle>
            <Grid container>
              <Grid item xs>
                <Title noH size="medium" text="Bewerbungsformular" />
              </Grid>
              <Grid item>
                <ArrowBackIcon
                  color="primary"
                  onClick={() => {
                    setApplicationSectionShown("");
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContent}>
          {(() => {
            switch (applicationSectionShown) {
              case "CV":
                return (
                  <CVApplication
                    jobAdId={jobAdId}
                    setApplicationSectionShown={setApplicationSectionShown}
                    setRecunitedId={setRecunitedId}
                    setCandidateId={setCandidateId}
                  />
                );
              case "FORM":
                return (
                  <ApplicationForm
                    jobAdId={jobAdId}
                    setApplicationSectionShown={setApplicationSectionShown}
                    prefilledUserData={prefilledUserData}
                    setRecunitedId={setRecunitedId}
                    setCandidateId={setCandidateId}
                  />
                );

              case "RECUNITED_MATCHING":
                return (
                  <RecunitedMatchingConfirm
                    setApplicationSectionShown={setApplicationSectionShown}
                    jobId={jobAdId}
                    candidateId={candidateId}
                    recunitedId={recunitedId}
                  />
                );
              case "ERROR":
                return <ErrorMessage />;
              case "SUCCESS":
                return <SuccessMessage />;
              // @ts-ignore
              case "RECUNITED_INTRO":
                if (!storageMatchingScore) {
                  return (
                    <RecunitedIntro
                      jobAdId={jobAdId}
                      setApplicationSectionShown={setApplicationSectionShown}
                      companyName={companyName}
                    />
                  );
                }
                break;
              default:
                return (
                  <ApplicationTypeSelection
                    jobAdId={jobAdId}
                    setApplicationSectionShown={setApplicationSectionShown}
                    setRecunitedId={setRecunitedId}
                    setCandidateId={setCandidateId}
                  />
                );
            }
          })()}
        </DialogContent>
      </Dialog>
      <PrimaryBtn
        className={visible ? "posfixb" : "posfixb hidden"}
        onClick={() => {
          fireGAEvent(
            GA_CATEGORY_JOB_DETAILS,
            GA_CATEGORY_JOB_DETAILS.actions.clickApply,
            `id: ${jobAdId}`
          );
          setApplicationSectionShown("");
          setIsApplicationDialogShown(true);
          setVisible(false);
        }}
        fullWidth={false}
        label="Jetzt bewerben"
        size="large"
      />
    </>
  );
};

export default ApplicationOverview;
