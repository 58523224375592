import { Grid, Hidden, Link } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Text from "../Atoms/Typography/Text";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  footerCenter: {
    textAlign: "center",
  },
  footerRight: {
    textAlign: "right",
  },
  footerContainer: {
    marginBottom: "3rem",
  },
  customIcon: {
    width: 18,
    height: 18,
    padding: 3,
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer>
      <Grid
        item
        container
        spacing={1}
        xs={12}
        className={classes.footerContainer}
      >
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          container
          justifyContent="space-evenly"
        >
          <Link target="_blank" href="https://www.xing.com/pages/searchtalent">
            <img
              src={"/xingLogo.png"}
              alt={"Xing Logo"}
              className={classes.customIcon}
            />
          </Link>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/searchtalent-gmbh/mycompany/"
          >
            <LinkedInIcon color="primary" />
          </Link>
          <Link
            target="_blank"
            href="https://www.instagram.com/searchtalent.de/"
          >
            <InstagramIcon color="primary" />
          </Link>
          <Link
            target="_blank"
            href="https://www.facebook.com/searchtalentrecruiting/"
          >
            <FacebookIcon color="primary" />
          </Link>
          <Link
            target="_blank"
            href="https://www.youtube.com/watch?v=tWDa1VWRh5I"
          >
            <YouTubeIcon color="primary" />
          </Link>
          <Link target="_blank" href="https://twitter.com/searchtalentcom">
            <TwitterIcon color="primary" />
          </Link>
          <Link target="_blank" href="mailto:info@searchtalent.com">
            <EmailIcon color="primary" />
          </Link>
        </Grid>
        <Hidden mdDown>
          <Grid item md={4} className={classes.footerCenter}>
            <Text text={`Landsberger Allee 366 | 12681 Berlin`} />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={7} md={4} className={classes.footerRight}>
          <Hidden mdUp>
            <Text text={`Landsberger Allee 366 | 12681 Berlin`} />
          </Hidden>
          <Text text={`Info: 030 / 546 374 84`} />
          <Text text={`Recruiting: 030 / 546 374 78`} />
          <Text text={`Kundenanfragen: 030 / 459 634 56`} />
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
