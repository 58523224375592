import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
// import { RewriteFrames } from "@sentry/integrations";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { gAMeasurementId } from "./utils/environmentDependencies";

// This allows TypeScript to detect our global value
declare global {
  namespace NodeJS {
    interface Global {
      __rootdir__: string;
    }
  }
}

global.__rootdir__ = __dirname || process.cwd();

if (gAMeasurementId) {
  ReactGA.initialize(gAMeasurementId);
}

// // Initialize Sentry for bug tracking
// Sentry.init({
//   dsn: "https://128c66e28bbe4bd5a85429ebcb3f8ee9@o284133.ingest.sentry.io/5882395",
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new RewriteFrames({
//       root: global.__rootdir__,
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
