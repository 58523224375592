import {
  Divider,
  Grid,
  Hidden,
  Link,
  Stack,
  useMediaQuery,
} from "@mui/material";
// import { id } from "date-fns/locale";
import React from "react";
import { recunitedBaseUrl } from "../../../utils/environmentDependencies";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import Text from "../../Atoms/Typography/Text";
import Title from "../../Atoms/Typography/Title";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { JobOverviewProps } from "../../../types/jobdetails";
import { useTheme } from "@mui/material/styles";
import {
  fireGAEvent,
  GA_CATEGORY_RECUNITED_APPLICATION,
} from "../../../utils/googleAnalytics";

interface RecunitedProps {
  jobOverview: JobOverviewProps;
}

const Recunited: React.FC<RecunitedProps> = ({ jobOverview }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
  return (
    <>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Link href="https://recunited.com">
            <img
              alt="recunited-logo"
              src="/recunited_logo.png"
              width="auto"
              height="40px"
            />
          </Link>
        </Grid>
      </Hidden>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12} sm={9}>
          <Grid item xs={12}>
            <Text
              text={`Mit Recunited lernst du zunächst deine eigenen Werte, Motivation und Arbeitsweise kennen und erfährst anschließend, wie gut diese zum Team von ${jobOverview.company.name} passen.`}
            />
            <br />
            <Text text="Damit du..." />
            <ul style={{ color: "#227671" }}>
              <li style={{ marginBottom: "4px" }}>
                <Text
                  color={false}
                  text={`dich besser kennenlernst und was dir im Job wirklich wichtig ist.`}
                />
              </li>
              <li style={{ marginBottom: "4px" }}>
                <Text
                  color={false}
                  text="vorher weißt, ob das DEIN zukünftiges Team ist."
                />
              </li>
              <li style={{ marginBottom: "4px" }}>
                <Text
                  color={false}
                  text="wegen deiner Persönlichkeit eingestellt wirst und nicht (nur) wegen deines CVs."
                />
              </li>
              <li style={{ marginBottom: "4px" }}>
                <Text color={false} text="wirklich sein kannst wie du bist." />
              </li>
            </ul>
            <Stack justifyContent="center" alignItems="center">
              <Link
                href={`${recunitedBaseUrl}?job_id=${jobOverview.jobId}`}
                underline="none"
                onClick={() => {
                  fireGAEvent(
                    GA_CATEGORY_RECUNITED_APPLICATION,
                    GA_CATEGORY_RECUNITED_APPLICATION.actions.clickApplyIntro,
                    `id: ${jobOverview.jobId}`
                  );
                }}
              >
                <PrimaryBtn
                  sx={{ borderRadius: 28, marginTop: "2rem" }}
                  fullWidth={false}
                  size="medium"
                  label="Jetzt den Wertetest machen"
                  style={{ backgroundColor: "#227671" }}
                  icon={<ArrowForwardIcon color="secondary" />}
                />
              </Link>
              <Text
                text="kostenfrei, in nur 5 Minuten"
                style={{ fontSize: "12px", marginTop: "2px" }}
              ></Text>
            </Stack>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={3} justifyContent={"center"}>
          <video
            playsInline={true}
            height="100%"
            width="70%"
            autoPlay={true}
            muted={true}
            loop={true}
            preload="auto"
            controls={true}
          >
            <source src="/recunited_video.mp4" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Grid>
      </Grid>
    </>
  );
};

export default Recunited;
