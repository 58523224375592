import {
  Select,
  MenuItem,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { useState } from "react";
import { ApplicationFormValues } from "../../../types/jobdetails";
import countryCodes from "../../../utils/countryCodes";
import Text from "../../Atoms/Typography/Text";

const useStyles = makeStyles(() =>
  createStyles({
    leftPaddingText: {
      paddingLeft: 4,
    },
    selectNoUnderline: {
      "&::before": {
        borderBottom: "none",
      },
    },
  })
);

const findCountryIndexByName = (countryName: string) => {
  return countryCodes.findIndex((element) => element.c === countryName);
};

const DEFAULT_COUNTRY_INDEX = findCountryIndexByName("Deutschland");

interface FormPhoneInputProps {
  handleChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  onBlur?: (e: any) => void;
  error?: string;
  value?: string;
  touched?: boolean;
  name: string;
  label: string;
  required: boolean;
  formikProps: FieldInputProps<any>;
}

const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  handleChange,
  error,
  value,
  touched,
  name,
  label,
  required,
  formikProps,
  onBlur,
}) => {
  const updateFieldValue = (countryIndex: number, phoneValue: string) => {
    if (!phoneValue) {
      handleChange(name, "", true);
    } else {
      handleChange(name, countryCodes[countryIndex].n + " " + phoneValue, true);
    }
  };

  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState("");
  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    DEFAULT_COUNTRY_INDEX
  );
  const classes = useStyles();
  console.log(error, touched);
  return (
    <TextField
      fullWidth
      {...formikProps}
      required={required}
      helperText={error && touched ? error : ""}
      error={Boolean(error) && touched}
      value={phoneWithoutCountryCode}
      onChange={(e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        setPhoneWithoutCountryCode(onlyNums);
        updateFieldValue(selectedCountryIndex, onlyNums);
      }}
      variant="standard"
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              className={classes.selectNoUnderline}
              variant="standard"
              value={selectedCountryIndex}
              renderValue={(val) => {
                let country = countryCodes[val];
                return (
                  <img
                    alt={country.c + "-flag"}
                    src="/blank.gif"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/flagssprite_small.png)`,
                      backgroundPosition: `${country.x}px ${country.y}px`,
                    }}
                  />
                );
              }}
              onChange={(e) => {
                let index = parseInt("" + e.target.value, 10);
                setSelectedCountryIndex(index);
                updateFieldValue(index, phoneWithoutCountryCode);
              }}
            >
              {countryCodes.map((country, index) => {
                return (
                  <MenuItem value={index} key={index}>
                    <img
                      alt={country.c + "-flag"}
                      src="/blank.gif"
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/flagssprite_small.png)`,
                        backgroundPosition: `${country.x}px ${country.y}px`,
                      }}
                    />
                    <Box className={classes.leftPaddingText}>{country.c}</Box>
                  </MenuItem>
                );
              })}
            </Select>
            <Text text={countryCodes[selectedCountryIndex].n} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormPhoneInput;
