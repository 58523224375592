import { OptionUnstyled } from "@mui/base";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Grid,
  InputAdornment,
  SvgIconTypeMap,
  TextField,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { ReactNode, SyntheticEvent } from "react";
import { AutocompleteModel, SearchFormValues } from "../../../types/jobportal";

type AutocompleteTypes = "keywords" | "locations";

interface DynamicAutocompleteProps {
  label: string;
  name: string;
  options: AutocompleteModel[];
  updateAmountFound: (values: SearchFormValues) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  getAutocompleteOptions: (
    type: AutocompleteTypes,
    words: string,
    values: SearchFormValues
  ) => void;
  values: SearchFormValues;
  icon?: ReactNode;
  type: AutocompleteTypes;
}

const DynamicAutocomplete: React.FC<DynamicAutocompleteProps> = ({
  label,
  name,
  options,
  updateAmountFound,
  setFieldValue,
  getAutocompleteOptions,
  values,
  icon,
  type,
}) => {
  return (
    <Autocomplete
      options={options}
      freeSolo
      disableClearable
      getOptionLabel={(option: AutocompleteModel | string) =>
        typeof option == "string" ? option : option.word
      }
      onInputChange={(e: object, value: string, reason: string) => {
        if (reason === "input") {
          setFieldValue(name, { word: value, amount: 0 });
          updateAmountFound({
            ...values,
            ...{ [name]: { word: value, amount: 0 } },
          });
          getAutocompleteOptions(type, value, values);
        }
      }}
      onChange={(
        e: SyntheticEvent<Element, Event>,
        value: string | AutocompleteModel | (string | AutocompleteModel)[]
      ) => {
        if (Array.isArray(value)) {
          return;
        }
        setFieldValue(name, {
          word: typeof value == "string" ? value : value.word,
          amount: 0,
        });
        updateAmountFound({
          ...values,
          ...{
            [name]: {
              word: typeof value == "string" ? value : value.word,
              amount: 0,
            },
          },
        });
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          name={name}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">{icon}</InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props: any, option: AutocompleteModel) => (
        <Box component="li" {...props}>
          <Grid container>
            <Grid item xs>
              {option.word}
            </Grid>
            <Grid item style={{ color: "grey" }}>
              {option.amount} {option.amount === 1 ? "Ergebnis" : "Ergebnisse"}
            </Grid>
          </Grid>
        </Box>
      )}
    />
  );
};

export default DynamicAutocomplete;
