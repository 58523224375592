import { Typography, Theme, useTheme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

interface TextProps {
  text: string;
  bold?: boolean;
  secondary?: boolean;
  color?: boolean;
  inline?: boolean;
  center?: boolean;
  overflow?: "ellipsis" | "clip";
  underline?: "dashed" | "solid" | "double" | "dotted" | "wavy";
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("sm")]: {
      smallText: {
        fontSize: "0.8rem",
      },
    },
  })
);

const Text: React.FC<TextProps> = ({
  text,
  bold = false,
  secondary = false,
  color = false,
  inline = false,
  center = false,
  overflow,
  underline,
  style,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const componentStyle: { [key: string]: number | string | undefined } = {
    textOverflow: overflow,
    textDecorationStyle: undefined,
    textDecorationLine: undefined,
    textDecorationColor: undefined,
    textUnderlineOffset: 2,
  };
  if (underline) {
    componentStyle.textDecorationStyle = underline;
    componentStyle.textDecorationLine = "underline";
    componentStyle.textDecorationColor = color
      ? theme.palette.primary.main
      : undefined;
  }
  return (
    <Typography
      className={classes.smallText}
      component={inline ? "span" : "p"}
      fontWeight={bold ? "600" : "400"}
      color={color ? "primary" : secondary ? "textSecondary" : "textPrimary"}
      textAlign={center ? "center" : undefined}
      style={{ ...componentStyle, ...style }}
    >
      {text}
    </Typography>
  );
};

export default Text;
