import { DialogContent, Grid, Dialog, Hidden, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Form, Formik, useField, useFormik } from "formik";
import { useState } from "react";
import { Education } from "../../../types/jobdetails";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import CheckBoxFormField from "../../Molecules/FormFields/CheckBoxFormField";
import DatePickerField, {
  useContainerStyles,
} from "../../Molecules/FormFields/DatePickerField";
import TimelineFormField from "../../Molecules/FormFields/TimelineFormField";
import * as yup from "yup";
import FormInput from "../../Molecules/FormFields/FormInput";
import Title from "../../Atoms/Typography/Title";
import Text from "../../Atoms/Typography/Text";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      paddingBottom: 32,
      display: "flex",
      justifyContent: "center",
    },
  })
);

const schema = yup.object({
  field: yup.string().required("Bitte angeben"),
  location: yup.string().required("Bitte angeben"),
});

interface EducationFormPartProps {
  education: Education[];
  setEducation: (fieldName: string, education: Education[]) => void;
  fieldName: string;
}

const EducationFormPart: React.FC<EducationFormPartProps> = ({
  education,
  setEducation,
  fieldName,
}) => {
  const formValueToTimeline = (modelValue: Education[]) => {
    return modelValue.map((value: Education) => {
      return {
        subtitle: value.degree
          ? value.degree + " - " + value.location
          : value.location,
        description: value.field,
        from: value.from,
        to: value.tillToday ? "heute" : value.to,
      };
    });
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const initialValues: Education = {
    degree: "",
    field: "",
    location: "",
    tillToday: false,
    from: undefined,
    to: undefined,
  };

  const handleAddStation = (values: Education) => {
    let newTotalEducation = education;
    newTotalEducation.push(values);
    setEducation(fieldName, newTotalEducation);
    setDialogOpen(false);
  };

  const handleUpdateStation = (values: Education, index: number) => {
    let newTotalEducation = education;
    newTotalEducation[index] = values;
    setEducation(fieldName, newTotalEducation);
    setDialogOpen(false);
  };

  const handleDeleteStation = (index: number) => {
    let newTotalEducation = education;
    newTotalEducation.splice(index, 1);
    setEducation(fieldName, newTotalEducation);
  };

  const classes = useStyles();
  const datePickerContainerClasses = useContainerStyles();

  const handleSubmit = async (values: Education) => {
    if (editIndex >= 0) {
      handleUpdateStation(values, editIndex);
      setEditIndex(-1);
    } else {
      handleAddStation(values);
    }
  };

  const formik = useFormik({
    initialValues: editIndex >= 0 ? education[editIndex] : initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <Grid item container xs={12} spacing={2} justifyContent="center">
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Bildung" />
      </Grid>
      {/* Timeline */}
      <Grid item xs={12}>
        {education.length > 0 ? (
          <TimelineFormField
            handleDeleteStation={handleDeleteStation}
            setEditIndex={setEditIndex}
            setDialogOpen={setDialogOpen}
            data={formValueToTimeline(education)}
          />
        ) : (
          <Text text="Noch keine Ausbildungsstation hinzugefügt" />
        )}
      </Grid>
      {/* Add station button */}
      <Grid item xs={12}>
        <SecondaryBtn
          label="Weitere Ausbildungsstation hinzufügen"
          onClick={() => {
            setDialogOpen(true);
          }}
          fullWidth={false}
          variant="text"
        />
      </Grid>
      {/* Add station dialog form */}
      <Dialog
        onClose={() => {
          setDialogOpen(false);
          setEditIndex(-1);
        }}
        maxWidth="sm"
        open={isDialogOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Abschluss"
                  fullWidth
                  name="degree"
                  variant="standard"
                  value={formik.values.degree}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Studiengang/Ausbildung"
                  variant="standard"
                  error={formik.touched.field && Boolean(formik.errors.field)}
                  helperText={
                    formik.errors.field && formik.touched.field
                      ? formik.errors.field
                      : ""
                  }
                  {...formik.getFieldProps("field")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Universität/Ausbildungsstätte"
                  variant="standard"
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={
                    formik.errors.location && formik.touched.location
                      ? formik.errors.location
                      : ""
                  }
                  {...formik.getFieldProps("location")}
                />
              </Grid>
              <Hidden smDown>
                <Grid item sm={6} />
              </Hidden>
              <Grid
                item
                xs={12}
                sm={6}
                className={datePickerContainerClasses.datePickerContainer}
              >
                <DatePickerField
                  label="Von"
                  name="from"
                  setFieldValue={formik.setFieldValue}
                  value={formik.values.from}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={datePickerContainerClasses.datePickerContainer}
              >
                <DatePickerField
                  label="Bis"
                  name="to"
                  value={formik.values.to}
                  disabled={Boolean(formik.values.tillToday)}
                  setFieldValue={formik.setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckBoxFormField
                  label="bis heute"
                  name="tillToday"
                  value={formik.values.tillToday}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Hidden smDown>
                <Grid item sm={6} />
              </Hidden>
              <Grid item xs={12}>
                <PrimaryBtn
                  label={editIndex >= 0 ? "Änderungen anwenden" : "Hinzufügen"}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default EducationFormPart;
