import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface FormSelectProps {
  menuItems: readonly string[];
  label: string;
  error?: string;
  required: boolean;
  displayEmpty?: boolean;
  value?: string;
  onChange: (e: SelectChangeEvent<any>) => void;
  name: string;
}

const FormSelect: React.FC<FormSelectProps> = ({
  menuItems,
  label,
  error,
  required,
  displayEmpty = true,
  value,
  onChange,
  name,
}) => {
  console.log(value);
  return (
    <FormControl
      required={required}
      variant="standard"
      fullWidth
      error={Boolean(error)}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        name={name}
        displayEmpty={displayEmpty}
        onChange={(e) => {
          console.log(e);
          onChange(e);
        }}
        fullWidth
      >
        {displayEmpty && !required && (
          <MenuItem key={"empty"} value={undefined}>
            -
          </MenuItem>
        )}
        {menuItems.map((value: string, index: number) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
