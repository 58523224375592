import { FieldProps } from "formik";
import React, { useEffect } from "react";
import { Grid, Slider } from "@mui/material";
import { FormLabel } from "@mui/material";
import { SearchFormValues } from "../../../types/jobportal";

interface InputSliderFormFieldProps {
  label: string;
  name: string;
  updateAmountFound: (values: SearchFormValues) => void;
  values: SearchFormValues;
  handleChange: (e: Event) => void;
  value?: string | number;
}

const InputSliderFormField: React.FC<InputSliderFormFieldProps> = ({
  label,
  updateAmountFound,
  values,
  value,
  handleChange,
  name,
}) => {
  useEffect(() => {
    updateAmountFound({ ...values, ...{ [name]: value } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  return (
    <Grid container>
      <FormLabel style={{ marginTop: "1rem" }}>{label}</FormLabel>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof value === "number" ? value : 0}
            onChange={(e) => {
              handleChange(e);
            }}
            name={name}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            aria-label="radius"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputSliderFormField;
