import { Link, useHistory } from "react-router-dom";
import { useAuthentication } from "../../utils/useAuthentication";
import { useState } from "react";
import {
  Alert,
  CircularProgress,
  // Button,
  // Divider,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Title from "../Atoms/Typography/Title";
// import { Facebook, Google, LinkedIn } from "@mui/icons-material";
import axios from "axios";
import Text from "../Atoms/Typography/Text";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitImage: {
      width: "85%",
      height: "auto",
      marginTop: "auto",
      marginBottom: "auto",
    },
    PageWrapper: {
      marginTop: "24px",
      marginBottom: "24px",
    },
    boxShadow: {
      boxShadow: "7px 1px 75px -19px rgba(109,130,21,0.30)",
    },
  })
);

export const SignUpPage: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shownSignupError, setShownSignupError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { signUp } = useAuthentication();
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.PageWrapper}>
        <form
          onSubmit={async (event) => {
            setIsLoading(true);
            // TODO: We should validate atleast the email
            event.preventDefault();
            try {
              await signUp(firstName, lastName, email, password);
              history.push("/sign-up-success");
            } catch (err) {
              setIsLoading(false);
              if (axios.isAxiosError(err)) {
                if (err.response?.status === 409) {
                  // Error for already existing user with provided email
                  // TODO: link to password recovery recommended
                  setShownSignupError(
                    "Ein Benutzer mit dieser Emailadresse existiert bereits"
                  );
                } else {
                  // Unexpected error
                  setShownSignupError(
                    "Registrierung fehlgeschlagen. Bitte probiere es erneut"
                  );
                }
              } else {
                // Even more unexpected error
                setShownSignupError(
                  "Ein unerwarteter Fehler ist aufgetreten. Helfe uns, ihn zu beheben und schicke eine Email mit den Einzelheiten an support@jobs.searchtalent.com"
                );
              }
            }
          }}
        >
          <Grid
            container
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              xs={12}
              md={5}
              container
              item
              spacing={4}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid item xs={12}>
                <Title size="large" text={"Bei Searchtalent registrieren"} />
              </Grid>
              <Grid item xs={12}>
                <Text text="Spare Zeit beim Bewerben über unsere Platform, indem du deine Bewerberdaten hinterlegst" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.boxShadow}
                  fullWidth
                  label="Vorname"
                  variant="outlined"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.boxShadow}
                  fullWidth
                  label="Nachname"
                  variant="outlined"
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.boxShadow}
                  fullWidth
                  label="E-Mail"
                  variant="outlined"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.boxShadow}
                  fullWidth
                  label="Passwort"
                  type="password"
                  variant="outlined"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  required
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <PrimaryBtn
                    label="Registrieren"
                    disabled={!(email && password)}
                    onClick={async () => {}}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Du hast bereits ein Nutzerkonto?{" "}
                  <Link to={"/sign-in"}>Anmelden</Link>
                </Typography>
              </Grid>
              {/* comment out below lines for oauth2 sign up services */}
              {/* <Grid
                container
                item
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={5}>
                  <Divider />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  oder
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  startIcon={<LinkedIn />}
                >
                  Registrieren mit LinkedIn
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  startIcon={<Google />}
                >
                  Registrieren mit Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  startIcon={<Facebook />}
                >
                  Registrieren mit Facebook
                </Button>
              </Grid> */}
            </Grid>
            <Grid item xs={12} md={7}>
              <img
                className={classes.fitImage}
                src={"/register.svg"}
                alt="Registrieren"
              />
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={Boolean(shownSignupError)}
          onClose={() => setShownSignupError("")}
          autoHideDuration={5000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {shownSignupError}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
