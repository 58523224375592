import { useEffect } from "react";

const PortalRedirect: React.FC = () => {
  useEffect(() => {
    window.location.assign("https://portal.searchtalent.com/clientportal");
  });
  return <div />;
};

export default PortalRedirect;
