import { CircularProgress, Grid, Hidden, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

import * as yup from "yup";
import {
  ApplicationTypeSelectionProps,
  CVApplicationValues,
} from "../../types/jobdetails";
import { apiBaseUrl } from "../../utils/environmentDependencies";
import {
  fireGAEvent,
  GA_CATEGORY_JOB_DETAILS,
} from "../../utils/googleAnalytics";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";
import UploadFile from "../Molecules/UploadFile";
import FinalizationFormPart from "../Organisms/Forms/FinalizationFormPart";

const CVApplication: React.FC<ApplicationTypeSelectionProps> = ({
  jobAdId,
  setApplicationSectionShown,
  setCandidateId,
  setRecunitedId,
  openApplication,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues: CVApplicationValues = {
    uploadedCV: undefined,
    email: "",
    dsgvo: false,
    earliestDateToStart: "",
    expectedSalary: "",
    noticePeriod: "",
  };

  const schema = yup.object({
    uploadedCV: yup.string().required("Bitte wähle deinen Lebenslauf aus"),
    email: yup
      .string()
      .required("Bitte Email angeben")
      .email("Email ist nicht gültig"),
    noticePeriod: yup.string(),
    earliestDateToStart: yup.string().required("Bitte Startzeitpunkt angeben"),
    expectedSalary: yup.string().required("Bitte Gehaltsvorstellung angeben"),
    dsgvo: yup.bool().oneOf([true], "Bitte der DSGVO zustimmen"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      fireGAEvent(
        GA_CATEGORY_JOB_DETAILS,
        GA_CATEGORY_JOB_DETAILS.actions.finishApplication,
        `id: ${jobAdId}`
      );
      let formData = new FormData();

      // need to satisfy typescript here...
      if (values.uploadedCV) {
        formData.append("uploadedCV", values.uploadedCV);
      }
      formData.append("email", values.email);
      formData.append("jobId", "" + jobAdId);
      if (values.noticePeriod) {
        formData.append("noticePeriod", values.noticePeriod);
      }
      formData.append("earliestDateToStart", values.earliestDateToStart);
      formData.append("expectedSalary", values.expectedSalary);
      setIsLoading(true);
      fetch(`${apiBaseUrl}/job_portal/apply_with_cv_searchtalent`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            setApplicationSectionShown("SUCCESS");
          } else {
            setApplicationSectionShown("ERROR");
          }
          setIsLoading(false);
          return response.json();
        })
        .then((response) => {
          if (response.status === 200) {
            setApplicationSectionShown("SUCCESS");
          } else {
            setApplicationSectionShown("ERROR");
          }
          setIsLoading(false);
          return response.json();
        })
        .then((data) => {
          if (data.recunited_id && data.candidate_id) {
            setRecunitedId && setRecunitedId(data.recunited_id);
            setCandidateId(data.candidate_id);
            if (localStorage.getItem(jobAdId ? jobAdId + "" : "")) {
              setApplicationSectionShown("RECUNITED_MATCHING");
            }
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Title noH size="small" text="Lebenslauf & Email" />
          </Grid>
          <Grid item xs={12}>
            <UploadFile
              fileError={Boolean(
                formik.touched.uploadedCV && Boolean(formik.errors.uploadedCV)
              )}
              label="Datei per Drag & Drop hinzufügen oder hier klicken (max. 20 MB)"
              errorMessage={
                formik.errors.uploadedCV ? formik.errors.uploadedCV : ""
              }
              onChange={(files) => {
                formik.handleChange({
                  target: { value: files[0], name: "uploadedCV" },
                });
              }}
            />
          </Grid>
          {formik.values.uploadedCV && !formik.errors.uploadedCV && (
            <Grid item xs={12}>
              <Text inline text="Deine Datei " />
              <Text inline text={formik.values.uploadedCV.name} color />
              <Text inline text=" wurde erfolgreich hinzugefügt!" />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              required
              variant="standard"
              fullWidth
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : ""
              }
              label="Email"
              {...formik.getFieldProps("email")}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <FinalizationFormPart formik={formik} />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <PrimaryBtn onClick={(window as any).lintrk("track", { conversion_id: 1130360 })} label={"Absenden"} />
            )}
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} />
          </Hidden>
        </Grid>
      </Grid>
    </form>
  );
};

export default CVApplication;
