import {
  FormGroup,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FieldProps, Formik, FormikErrors } from "formik";
import { FC, useEffect, useState } from "react";
import { WorkExperience } from "../../../types/jobdetails";
import { SearchFormValues } from "../../../types/jobportal";

const useStyles = makeStyles(() =>
  createStyles({
    centerAlignForm: { alignSelf: "center" },
    leftAlignHelperText: { marginLeft: 0 },
  })
);

interface CheckBoxFormFieldProps {
  label: string | React.ReactElement;
  name: string;
  value?: boolean;
  updateAmountFound?: (values: SearchFormValues) => void;
  values?: SearchFormValues;
  error?: string;
  required?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const CheckBoxFormField: FC<CheckBoxFormFieldProps> = ({
  label,
  name,
  value,
  updateAmountFound,
  values,
  error,
  required,
  handleChange,
}) => {
  const classes = useStyles();

  useEffect(() => {
    updateAmountFound &&
      values &&
      updateAmountFound({ ...values, ...{ [name]: value } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  return (
    <FormGroup row>
      <FormControl
        className={classes.centerAlignForm}
        required={required}
        error={Boolean(error)}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={handleChange}
              name={name}
              color="primary"
            />
          }
          label={label}
        />
        {error ? (
          <FormHelperText className={classes.leftAlignHelperText}>
            {error}
          </FormHelperText>
        ) : null}
      </FormControl>
    </FormGroup>
  );
};

export default CheckBoxFormField;
