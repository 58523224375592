import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { RecruitingProcessImageProps } from "../../../types/jobdetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    recruitingProcessIconImage: {
      [theme.breakpoints.down("sm")]: {
        width: 50,
      },
      [theme.breakpoints.up("sm")]: {
        width: 60,
      },
      [theme.breakpoints.up("md")]: {
        width: 75,
      },
      height: "auto",
    },
  })
);

const RecruitingProcessImage: React.FC<RecruitingProcessImageProps> = ({
  title,
  imagePath,
}) => {
  const classes = useStyles();

  return (
    <img
      alt={title}
      src={imagePath}
      className={classes.recruitingProcessIconImage}
    />
  );
};

export default RecruitingProcessImage;
