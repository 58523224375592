import { Grid, Hidden } from "@mui/material";
import React from "react";
import { FactType, KeyFactsProps } from "../../types/jobdetails";
import Title from "../Atoms/Typography/Title";
import KeyFact from "../Molecules/JobDetails/KeyFact";

const defineKeyFactsToShow = (keyFactsToShow: KeyFactsProps) => {
  const filteredKeyFacts = new Map<string, FactType>();

  for (const [key, value] of Object.entries(keyFactsToShow)) {
    switch (key) {
      case "amount_coworkers_team":
        if (value > 0) filteredKeyFacts.set(key, value);
        break;
      case "amount_coworkers_company":
        if (value > 0) filteredKeyFacts.set(key, value);
        break;
      case "is_temporary":
        if (value) filteredKeyFacts.set(key, value);
        break;
      case "benefits":
        if (value && value.length > 0) filteredKeyFacts.set(key, value);
        break;
      case "allowance_travel":
        if (value.allowance) filteredKeyFacts.set(key, value);
        break;
      case "health_measurements":
        if (value && value.length > 0) filteredKeyFacts.set(key, value);
        break;
      case "amount_holidays":
        if (value && value > 0) filteredKeyFacts.set(key, value);
        break;
      case "has_home_office":
        if (value) filteredKeyFacts.set(key, value);
        break;
      case "remote":
        if (value) filteredKeyFacts.set(key, value);
        break;
      case "industry":
        if (value) filteredKeyFacts.set(key, value);
        break;
      case "job_type":
        if (value) filteredKeyFacts.set(key, value);
        break;
      case "languages":
        if (value.english_knowledge || value.german_knowledge)
          filteredKeyFacts.set(key, value);
        break;
      case "salary_info":
        if (value.show_salary) filteredKeyFacts.set(key, value);
        break;
    }
  }
  return filteredKeyFacts;
};

const KeyFacts: React.FC<KeyFactsProps> = ({ ...props }) => {
  const keyFacts: KeyFactsProps = { ...props };
  const entries = Array.from(defineKeyFactsToShow(keyFacts).entries());
  return (
    <Grid container item xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Title size="medium" bold text="Wichtigste Fakten" />
      </Grid>
      <Grid
        style={{ pointerEvents: "none" }}
        container
        item
        xs={12}
        justifyContent="center"
      >
        {entries.map((value, index) => {
          return (
            <KeyFact factName={value[0]} factValue={value[1]} key={index} />
          );
        })}
        {entries.length % 2 === 1 ? (
          <Hidden mdDown>
            <KeyFact factName="" factValue="" />
          </Hidden>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default KeyFacts;
