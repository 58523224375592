import { getKeycloakManager } from "./ApiUtils";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import qs from "query-string";
import { apiBaseUrl } from "./environmentDependencies";

export function useAuthentication() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
  ]);
  const history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState<Boolean>(
    Boolean(cookies.access_token)
  );

  const keycloakManager = getKeycloakManager();

  useEffect(() => {
    setAuthenticated(Boolean(cookies.access_token));
  }, [cookies]);

  const signIn = async (email: string, password: string) => {
    const response = await axios.post(
      keycloakManager.tokenUrl,
      qs.stringify({
        client_id: "searchtalent-webapp",
        grant_type: "password",
        username: email,
        password,
      })
    );

    if (response.status === 200) {
      const expires = new Date();
      const refresh_expires = new Date();
      expires.setTime(
        expires.getTime() + (response.data.expires_in - 10) * 1000
      );
      refresh_expires.setTime(
        refresh_expires.getTime() +
          (response.data.refresh_expires_in - 10) * 1000
      );

      setCookie("access_token", response.data.access_token, {
        path: "/",
        expires,
      });
      setCookie("refresh_token", response.data.refresh_token, {
        path: "/",
        expires: refresh_expires,
      });

      return;
    }

    throw new Error("Error while signing in!");
  };

  const signUp = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    const response = await axios.post(`${apiBaseUrl}/job_portal/users`, {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    });

    return;
  };

  const refreshAccessToken = async () => {
    if (cookies.refresh_token) {
      const response = await axios.post(
        keycloakManager.tokenUrl,
        qs.stringify({
          client_id: "searchtalent-webapp",
          grant_type: "refresh_token",
          refresh_token: cookies.refresh_token,
        })
      );

      if (response.status === 200) {
        const expires = new Date();
        const refresh_expires = new Date();
        expires.setTime(
          expires.getTime() + (response.data.expires_in - 10) * 1000
        );
        refresh_expires.setTime(
          refresh_expires.getTime() +
            (response.data.refresh_expires_in - 10) * 1000
        );

        setCookie("access_token", response.data.access_token, {
          path: "/",
          expires,
        });
        setCookie("refresh_token", response.data.refresh_token, {
          path: "/",
          expires: refresh_expires,
        });
        return response.data.access_token;
      }
    } else {
      history.push("/sign-in");
      return;
    }
  };

  const signOut = async () => {
    const response = await axios.post(
      keycloakManager.logOutUrl,
      qs.stringify({
        client_id: "searchtalent-webapp",
        refresh_token: cookies.refresh_token,
      })
    );

    if (response.status === 204) {
      removeCookie("access_token", { path: "/" });
      removeCookie("refresh_token", { path: "/" });

      return;
    }

    throw new Error("Error while signing out!");
  };

  /**
   * Helper function to create an authorization header
   * @param options standard fetch options
   * @param accessToken optional access token, only needed if set cookies doesn't set the access token in time (e.g. after using the refresh token to generate a new access token)
   * @return fetch options with added authorization header
   */
  const authorizeHeader = (options: any, accessToken?: string) => {
    const update = { ...options };
    if (cookies.access_token || accessToken) {
      update.headers = {
        ...update.headers,
        Authorization: accessToken ? accessToken : cookies.access_token,
      };
    }
    return update;
  };

  /**
   * Wrapper for authenticated fetch requests
   * @param url same as for fetch: url of the request
   * @param options same as for fetch: options for the request (containing method, headers, etc)
   * @param authNeeded flag for whether authentication is a requirement for this request
   * @returns fetch function call with added authentication header (if user is logged in)
   */
  const authFetch = async (url: string, options: any, authNeeded?: boolean) => {
    // In case an access token is available, simply fetch with the authorization header
    // NOTE: react cookies does not offer a way to check for expiry, and the cookies object is not getting updated on expiry, so we have to use document
    if (document.cookie.includes("access_token")) {
      return fetch(url, authorizeHeader(options));
    }
    // In case no access token, but a refresh token is available, get a new access token and execute the request with the authorization header
    else if (document.cookie.includes("refresh_token")) {
      // Retrieving the new access token is needed here, due to setCookies not updating in time
      let newAccessToken = await refreshAccessToken();
      return fetch(url, authorizeHeader(options, newAccessToken));
    }
    // If no token is available, redirect to sign in
    else if (authNeeded) {
      history.push("/sign-in");
      return;
    }
    return fetch(url, options);
  };

  return { isAuthenticated, signIn, signOut, signUp, authFetch };
}
