import { Chip } from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";

interface ChipListFormPartProps {
  items: string[];
  deleteItem: (item: string) => void;
}

const useStyle = makeStyles(() =>
  createStyles({ offsetChip: { marginRight: 8, marginBottom: 4 } })
);

const ChipListFormField: React.FC<ChipListFormPartProps> = ({
  items,
  deleteItem,
}) => {
  const classes = useStyle();
  return (
    <>
      {items.map((value) => (
        <Chip
          className={classes.offsetChip}
          key={value}
          label={value}
          onDelete={() => {
            deleteItem(value);
          }}
        />
      ))}
    </>
  );
};

export default ChipListFormField;
