import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Container,
  Grid,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { useHistory } from "react-router-dom";
// import { useAuthentication } from "../../utils/useAuthentication";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../Atoms/Buttons/SecondaryBtn";

const useStyles = makeStyles(() => ({
  toolbarNoPadding: {
    padding: 0,
    marginBottom: 14,
  },
}));

const Header: React.FC = () => {
  // const { isAuthenticated, signOut } = useAuthentication();
  const classes = useStyles();
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <AppBar elevation={1} position="relative" color="secondary">
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbarNoPadding}>
          <Grid container spacing={1}>
            <Grid item>
              <Link href="/">
                <img
                  alt="Searchtalent logo"
                  src="/searchtalent-header.png"
                  style={{
                    width: "14rem",
                    marginTop: "1rem",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs />
            <Hidden smUp>
              <Grid item alignSelf="center">
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton
                        aria-label="open"
                        {...bindTrigger(popupState)}
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <Link
                            underline="none"
                            href="https://searchtalent.de/#unternehmen"
                            color="inherit"
                          >
                            Für Unternehmen
                          </Link>
                        </MenuItem>
                        {/* {isAuthenticated ? (
                          <>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                history.push({
                                  pathname: "/profile",
                                });
                              }}
                            >
                              Profil
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                              <Link
                                underline="none"
                                href="https://searchtalent.de/#unternehmen"
                                color="inherit"
                              >
                                Für Unternehmen
                              </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                history.push({
                                  pathname: "/",
                                });
                                signOut();
                              }}
                            >
                              Ausloggen
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                history.push("/sign-up");
                              }}
                            >
                              Registrieren
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                popupState.close();
                                history.push("/sign-in");
                              }}
                            >
                              Anmelden
                            </MenuItem>

                            <MenuItem onClick={popupState.close}>
                              <Link
                                underline="none"
                                href="https://searchtalent.de/#unternehmen"
                                color="inherit"
                              >
                                Für Unternehmen
                              </Link>
                            </MenuItem>
                          </>
                        )} */}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item alignSelf="flex-end">
                <Link
                  underline="none"
                  href="https://searchtalent.de/#unternehmen"
                >
                  <SecondaryBtn variant="text" label="Für Unternehmen" />
                </Link>
              </Grid>
              {/* {isAuthenticated ? (
                <>
                  <Grid item alignSelf="flex-end">
                    <IconButton
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      size="small"
                    >
                      <AccountCircleIcon color="primary" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          history.push({
                            pathname: "/profile",
                          });
                          setAnchorEl(null);
                        }}
                      >
                        Profil
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          signOut();
                          setAnchorEl(null);
                        }}
                      >
                        Ausloggen
                      </MenuItem>
                    </Menu>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item alignSelf="flex-end">
                    <SecondaryBtn
                      label="Anmelden"
                      onClick={() => history.push("/sign-in")}
                    />
                  </Grid>
                  <Grid item alignSelf="flex-end">
                    <PrimaryBtn
                      label="Registrieren"
                      onClick={() => history.push("/sign-up")}
                    />
                  </Grid>
                </>
              )} */}
            </Hidden>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
