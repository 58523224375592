import { Grid, Hidden, Link } from "@mui/material";
import { XING_CLIENT_ID } from "../../utils/constants";
import { xingRedirectBaseUrl } from "../../utils/environmentDependencies";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";
import ApplicationTypeOption from "../Molecules/JobDetails/ApplicationTypeOption";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  fireGAEvent,
  GA_CATEGORY_JOB_DETAILS,
} from "../../utils/googleAnalytics";

interface ApplicationTypePickerProps {
  setApplicationSectionShown: (applicationTypeToShow: string) => void;
  jobAdId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "1rem",
      },
    },
  })
);

const ApplicationTypePicker: React.FC<ApplicationTypePickerProps> = ({
  setApplicationSectionShown,
  jobAdId,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      spacing={3}
      justifyContent="center"
      alignContent="center"
      className={classes.container}
    >
      <Grid container item xs={12} spacing={2}>
        <Grid container item xs={12}>
          <Title noH size="medium" text="Wie möchten Sie sich bewerben?" />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={4} justifyContent="center">
        <ApplicationTypeOption
          buttonIsPrimary={false}
          buttonText="Mit Lebenslauf"
          descriptionComponent={
            <Hidden smDown>
              <Text
                inline
                text="Schicken Sie uns einfach Ihren Lebenslauf und wir kümmern uns um
                              den Rest. Bei eventuellen Fragen melden wir uns. Kein Lebenslauf verfügbar? 
                              Gehen Sie auf Ihr "
              />
              <Link
                color="primary"
                href="https://www.linkedin.com/in/me/"
                target="_blank"
              >
                <Text color inline text="LinkedIn-Profil" />
              </Link>
              <Text
                inline
                text=", klicken Sie oben auf 'Mehr' und wählen Sie 'Als PDF speichern' aus."
              />
            </Hidden>
          }
          headerText="Lebenslauf"
          onClick={() => {
            fireGAEvent(
              GA_CATEGORY_JOB_DETAILS,
              GA_CATEGORY_JOB_DETAILS.actions.clickApplyCV,
              `id: ${jobAdId ? jobAdId : "None"}`
            );
            setApplicationSectionShown("CV");
          }}
        />
      </Grid>
      <Grid container item xs={12} md={4} justifyContent="center">
        <ApplicationTypeOption
          buttonIsPrimary={true}
          buttonText="Mittels XING"
          descriptionComponent={
            <Hidden smDown>
              <Text
                text="Bewerben Sie sich bequem mithilfe Ihres XING Accounts. Klicken Sie
                    einfach auf den Button, authentifizieren Sie sich via XING und
                    wir übernehmen den Rest. Sie können Ihre Informationen vor
                    dem Absenden nochmal einsehen und abändern."
              />
            </Hidden>
          }
          headerText="XING"
          onClick={() => {
            fireGAEvent(
              GA_CATEGORY_JOB_DETAILS,
              GA_CATEGORY_JOB_DETAILS.actions.clickApplyXING,
              `id: ${jobAdId ? jobAdId : "None"}`
            );
            window.open(
              `https://api.xing.com/auth/oauth2/authorize?client_id=${XING_CLIENT_ID}&response_type=code&redirect_uri=${
                xingRedirectBaseUrl + window.location.pathname
              }`,
              "_self"
            );
          }}
        />
      </Grid>
      <Grid container item xs={12} md={4} justifyContent="center">
        <ApplicationTypeOption
          buttonIsPrimary={false}
          buttonText="Mit Formular"
          descriptionComponent={
            <Hidden smDown>
              <Text text="Tragen Sie Ihre Informationen per Hand ein." />
            </Hidden>
          }
          headerText="Formular"
          onClick={() => {
            fireGAEvent(
              GA_CATEGORY_JOB_DETAILS,
              GA_CATEGORY_JOB_DETAILS.actions.clickApplyForm,
              `id: ${jobAdId ? jobAdId : "None"}`
            );
            setApplicationSectionShown("FORM");
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationTypePicker;
