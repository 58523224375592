import {
  ListItem,
  ListItemButton,
  ListItemText,
  Link,
  Hidden,
  ListItemIcon,
} from "@mui/material";
import React from "react";
import { JobResultProps } from "../../types/searchresults";
import Title from "../Atoms/Typography/Title";
import RoomIcon from "@mui/icons-material/Room";
import Text from "../Atoms/Typography/Text";
import ClientLogo from "../Atoms/Images/ClientLogo";
import {
  fireGAEvent,
  GA_CATEGORY_SEARCH_RESULTS,
} from "../../utils/googleAnalytics";

const JobResult: React.FC<JobResultProps> = (props) => {
  const { startDate, clientName, location, jobTitle, id, logo } = props;

  return (
    <ListItem
      style={{ borderLeft: "2px solid teal" }}
      alignItems="center"
      disablePadding
    >
      <Link
        style={{ width: "100%" }}
        underline="none"
        onClick={() => {
          fireGAEvent(
            GA_CATEGORY_SEARCH_RESULTS,
            GA_CATEGORY_SEARCH_RESULTS.actions.clickResult,
            `id: ${id}`
          );
        }}
        href={`/jobdetails/${id}`}
      >
        <ListItemButton dense>
          <Hidden smDown>
            <ClientLogo image={logo} title={clientName + " logo"} />
          </Hidden>
          <>
            <ListItemText
              disableTypography
              primary={<Title text={jobTitle} size="small" bold />}
              secondary={
                <>
                  <ListItemIcon>
                    <RoomIcon />
                    <Text text={location} />
                  </ListItemIcon>
                  <Text text={clientName} />
                  <Text text={startDate} secondary={true} color={true} />
                </>
              }
            />
          </>
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default JobResult;
