const GOOGLE_JOB_TYPE_MAP: { [key: string]: string } = {
  Vollzeittätigkeit: "FULL_TIME",
  Teilzeittätigkeit: "PART_TIME",
  "Freiberufliche Tätigkeit": "OTHER",
  Midijob: "OTHER",
  Minijob: "OTHER",
};

// Note: this is only a rough outline of the type definition of the google for job json. for a complete definition see:
// https://developers.google.com/search/docs/advanced/structured-data/job-posting?hl=de#structured-data-type-definitions
interface googleJobJSONDefinition {
  datePosted: string;
  description: string;
  hiringOrganization: { [key: string]: any };
  jobLocation: { [key: string]: any };
  title: string;
  validThrough?: string;
  applicantLocationRequirements?: { [key: string]: any };
  baseSalary?: { [key: string]: any };
  employmentType?: string[] | string;
  identifier?: { [key: string]: any };
  jobLocationType?: { [key: string]: any };
  "@context": string;
  "@type": string;
}

const createGoogleJobJSON = (jobData: any) => {
  var googleJobJSON: googleJobJSONDefinition = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: jobData.title,
    description: jobData.description,
    identifier: {
      "@type": "PropertyValue",
      name: "Searchtalent",
      value: jobData.id,
    },
    datePosted: jobData.time_published,
    employmentType: "CONTRACTOR",
    hiringOrganization: {
      "@type": "Organization",
      name: jobData.company?.name,
      sameAs: jobData.company?.url,
      logo: jobData.company?.logo_url,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: jobData.address?.street_name_and_number,
        addressLocality: jobData.address?.city,
        postalCode: jobData.address?.zip_code,
      },
    },
  };
  if (jobData.salary_info?.show_salary) {
    googleJobJSON.baseSalary = {
      "@type": "MonetaryAmount",
      currency: "EUR",
      value: {
        "@type": "QuantitativeValue",
        value: jobData.salary_info.salary,
        unitText: "YEAR",
      },
    };
  }
  if (jobData.job_type) {
    googleJobJSON.employmentType = [
      ...new Set<string>(
        jobData.job_type.map((type: string) => GOOGLE_JOB_TYPE_MAP[type])
      ),
    ];
  }

  return googleJobJSON;
};

export default createGoogleJobJSON;
