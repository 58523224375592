import { Grid, Hidden } from "@mui/material";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import React from "react";
import Title from "../../Atoms/Typography/Title";
import { ApplicationTypeOptionProps } from "../../../types/jobdetails";

const ApplicationTypeOption: React.FC<ApplicationTypeOptionProps> = ({
  onClick,
  headerText,
  descriptionComponent,
  buttonText,
  buttonIsPrimary,
  buttonIcon,
}) => {
  const ButtonComponent = buttonIsPrimary ? (
    <PrimaryBtn label={buttonText} icon={buttonIcon} onClick={onClick} />
  ) : (
    <SecondaryBtn label={buttonText} icon={buttonIcon} onClick={onClick} />
  );
  return (
    <Grid
      container
      item
      direction="column"
      xs={12}
      spacing={2}
      justifyContent="space-between"
    >
      <Hidden smDown>
        <Grid item>
          <Title noH size="small" text={headerText} />
        </Grid>
      </Hidden>
      <Grid item xs>
        {descriptionComponent}
      </Grid>
      <Grid item>{ButtonComponent}</Grid>
    </Grid>
  );
};

export default ApplicationTypeOption;
