import { FormHelperText, Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DropzoneArea } from "react-mui-dropzone";
import { UploadFileProps } from "../../types/jobdetails";
import Text from "../Atoms/Typography/Text";

const ALLOWED_FILE_TYPES = [
  ".doc",
  ".docx",
  ".odt",
  ".html",
  ".txt",
  ".rtf",
  ".dot",
  ".pdf",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      fontSize: "1rem",
    },
  })
);

const UploadFile: React.FC<UploadFileProps> = ({
  onChange,
  label,
  fileError,
  errorMessage,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Text
          text={
            "Erlaubt sind folgende Dateitypen: " + ALLOWED_FILE_TYPES.join(", ")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DropzoneArea
          acceptedFiles={ALLOWED_FILE_TYPES}
          maxFileSize={21000000}
          showPreviewsInDropzone={true}
          dropzoneParagraphClass={classes.dropzone}
          dropzoneText={label}
          filesLimit={1}
          onChange={onChange}
          showAlerts={true}
        />
        <FormHelperText error={fileError}>
          {fileError ? errorMessage : ""}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default UploadFile;
