import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  Pagination,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { ApplicationFormValues } from "../../types/jobdetails";
import { JobResults } from "../../types/searchresults";
import {
  apiBaseUrl,
  xingRedirectBaseUrl,
} from "../../utils/environmentDependencies";
import {
  fireGAEvent,
  fireGAPageView,
  GA_CATEGORY_SEARCH_RESULTS,
} from "../../utils/googleAnalytics";
import { retrieveXingData } from "../../utils/XingUtils";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../Atoms/Buttons/SecondaryBtn";
import Title from "../Atoms/Typography/Title";
import Footer from "../Molecules/Footer";
import NoResults from "../Organisms/NoResults";
import FoundResults from "../Organisms/SearchResults/FoundResults";
import ApplicationForm from "./ApplicationForm";
import ApplicationTypeSelection from "./ApplicationTypeSelection";
import CVApplication from "./CVApplication";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

const useStyles = makeStyles((theme: Theme) => ({
  [theme.breakpoints.down("md")]: {
    container: {
      minHeight: "unset",
      height: "unset",
    },
    jobResultContainer: {
      minHeight: "unset",
      height: "unset",
    },
  },
  [theme.breakpoints.up("md")]: {
    container: {
      minHeight: "800px",
      height: "100vh",
    },
    jobResultContainer: {
      height: "80vh",
      minHeight: "700px",
    },
  },
  fitImage: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const SearchResults: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<JobResults | undefined>();
  const [isReturned, setIsReturned] = useState<boolean>(true);
  const [resultsMobile, setResultsMobile] = useState<JobResults | undefined>();
  const [totalResults, setTotalResults] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const popupClass = window.location.href.search(/popup/) > 0 ? "uniq" : "";

  const theme = useTheme();
  let mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const classes = useStyles();
  const history = useHistory();

  let PER_PAGE = mobileScreen ? 15 : 4;

  const handlePaginationChange = (e: any, page: number) => {
    e.preventDefault();
    if (page) {
      fireGAEvent(
        GA_CATEGORY_SEARCH_RESULTS,
        GA_CATEGORY_SEARCH_RESULTS.actions.clickOtherPage,
        `targetPage: ${page}`
      );
      setPage(page);
      const searchParamsURL = new URLSearchParams(window.location.search);
      searchParamsURL.set("page", page.toString());
      let searchURL = searchParamsURL.toString();

      history.push({
        pathname: "/search",
        search: searchURL.toString(),
      });
    }
  };

  useEffect(() => {
    fireGAPageView();
    const searchParamsURL = new URLSearchParams(window.location.search);
    let initialPage = searchParamsURL.get("page");
    if (initialPage) {
      setPage(parseInt(initialPage, 10));
    }
    setIsLoading(true);
  }, []);

  useEffect(() => {
    let searchParamsURL = new URLSearchParams(window.location.search);
    searchParamsURL.set("page", page.toString());
    searchParamsURL.set("per_page", PER_PAGE.toString());
    fetch(`${apiBaseUrl}/job_portal/jobs?${searchParamsURL}`)
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data);
        setResultsMobile({
          jobs: resultsMobile
            ? resultsMobile.jobs.concat(data.jobs)
            : data.jobs,
          total_amount: data.total_amount,
        });
        setIsLoading(false);
        setTotalResults(data.total_amount);
        setIsReturned(!(data.total_amount === 0));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, mobileScreen]);

  const [userData, setUserData] = useState<ApplicationFormValues>();
  const [candidateId, setCandidateId] = useState<number>();
  const [applicationSectionShown, setApplicationSectionShown] = useState("");
  const [isApplicationDialogShown, setIsApplicationDialogShown] =
    useState(popupClass);

  const code = new URLSearchParams(useLocation().search).get("code");

  useEffect(() => {
    fireGAPageView();
    if (code) {
      retrieveXingData(code, `${xingRedirectBaseUrl}/search`)
        .then((data) => {
          setUserData(data);
          setIsApplicationDialogShown("");
          setApplicationSectionShown("FORM");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container justifyContent="center" alignContent="center" spacing={5}>
      <Grid
        item
        container
        xs={12}
        spacing={5}
        alignContent="center"
        className={classes.container}
      >
        {isLoading ? (
          <Grid item container spacing={5}>
            <Grid item xs={12} />
            <Grid item container justifyContent="center" xs={12}>
              <CircularProgress />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        ) : (
          <>
            {isReturned ? (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignContent="space-between"
                className={classes.jobResultContainer}
              >
                <Grid item container xs={12} alignItems="center">
                  <Grid item container xs={12} md={7}>
                    <FoundResults
                      searchResults={searchResults}
                      totalResults={totalResults}
                      resultsMobile={resultsMobile}
                      page={page}
                      setPage={setPage}
                    />
                  </Grid>
                  <Hidden mdDown>
                    <Grid item xs={5}>
                      <img
                        className={classes.fitImage}
                        src={"/searchresults.png"}
                        alt="Search results"
                      />
                    </Grid>
                  </Hidden>
                </Grid>
                <Dialog
                  fullScreen={
                    isExtraSmallScreen && applicationSectionShown === "FORM"
                  }
                  onClose={() => {
                    if (!popupClass) setIsApplicationDialogShown("");
                  }}
                  maxWidth="md"
                  keepMounted
                  open={isApplicationDialogShown == "uniq"}
                  className={popupClass}
                >
                  {isExtraSmallScreen && applicationSectionShown === "FORM" && (
                    <DialogTitle>
                      <Grid container>
                        <Grid item xs>
                          <Title noH size="medium" text="Bewerbungsformular" />
                        </Grid>
                        <Grid item>
                          <ArrowBackIcon
                            color="primary"
                            onClick={() => {
                              setApplicationSectionShown("");
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogTitle>
                  )}
                  <DialogContent className={classes.dialogContent}>
                    {(() => {
                      switch (applicationSectionShown) {
                        case "CV":
                          return (
                            <CVApplication
                              setApplicationSectionShown={
                                setApplicationSectionShown
                              }
                              setCandidateId={setCandidateId}
                              openApplication={true}
                            />
                          );
                        case "FORM":
                          return (
                            <ApplicationForm
                              setApplicationSectionShown={
                                setApplicationSectionShown
                              }
                              prefilledUserData={userData}
                              setCandidateId={setCandidateId}
                            />
                          );
                        case "ERROR":
                          return <ErrorMessage />;
                        case "SUCCESS":
                          return <SuccessMessage />;
                        default:
                          return (
                            <ApplicationTypeSelection
                              setApplicationSectionShown={
                                setApplicationSectionShown
                              }
                              setCandidateId={setCandidateId}
                            />
                          );
                      }
                    })()}
                  </DialogContent>
                </Dialog>
                <Hidden mdDown>
                  <Grid item container xs={12} justifyContent="center">
                    <Pagination
                      count={
                        totalResults ? Math.ceil(totalResults / PER_PAGE) : 0
                      }
                      onChange={(e, page) => handlePaginationChange(e, page)}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                    />
                  </Grid>
                </Hidden>
                <Grid
                  item
                  container
                  justifyContent="center"
                  xs={10}
                  md={5}
                  sx={{ margin: "1rem" }}
                >
                  <Grid item container xs={12} md={12} justifyContent="center">
                    <Typography variant="subtitle2">
                      Keinen Job gefunden, der zu dir passt?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <PrimaryBtn
                      label="Initiativbewerbung schicken"
                      size="small"
                      onClick={() => {
                        setIsApplicationDialogShown("uniq");
                      }}
                    />
                    <PrimaryBtn
                      label="Initiativbewerbung schicken"
                      size="small"
                      fullWidth={false}
                      className="posfix"
                      onClick={() => {
                        setIsApplicationDialogShown("uniq");
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NoResults />
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default SearchResults;
