import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { JobDescriptionSectionProps } from "../../types/jobdetails";
import Title from "../Atoms/Typography/Title";

const JobDescriptionSection: React.FC<JobDescriptionSectionProps> = ({
  description,
  setJobDetailsLoaded,
}) => {
  let descriptionNode = document.createElement("div");
  descriptionNode.innerHTML = description;
  let container = document.getElementById("descriptionContainer");
  if (container) {
    container.innerHTML = descriptionNode.innerHTML;
    // hack for unsetting white backgrounds, fixing images and to set correct font
    for (let node of container.getElementsByTagName("*")) {
      // images
      if (node.tagName === "IMG") {
        let lastChild = node;
        while (true) {
          let parent = lastChild.parentElement;
          parent?.removeChild(lastChild);
          if (parent) {
            if (
              (parent.childNodes &&
                parent.childNodes.length === 1 &&
                parent.firstChild?.nodeName === "BR") ||
              (parent.childNodes && parent.childNodes.length === 0)
            ) {
              lastChild = parent;
            } else {
              break;
            }
          }
        }
        // LEFTOVER CODE: Ines wanted to have all images removed. In case this needs to be reverted, you can use the commented lines below
        // let adjustElementSize = (imageNode: Element) => {
        //   if (imageNode.clientWidth > 100) {
        //     imageNode.setAttribute(
        //       "style",
        //       imageNode.getAttribute("style") + "; width: 100%; height: auto"
        //     );
        //   }
        //   imageNode.addEventListener("error", () => {
        //     let parent = imageNode.parentElement;
        //     if (parent) parent.removeChild(imageNode);
        //   });
        // };
        // adjustElementSize(node);
        // let parent = node.parentElement;
        // while (parent !== null && parent.id !== "descriptionContainer") {
        //   adjustElementSize(parent);
        //   parent = parent.parentElement;
        // }
      }

      // h1
      if (node.tagName === "H1") {
        let replacement = document.createElement("p");
        while (node.firstChild) {
          replacement.appendChild(node.firstChild);
        }
        if (node.parentNode) {
          node.parentNode.replaceChild(replacement, node);
        }
      }
    }
    for (let node of container.getElementsByTagName("*")) {
      // background and font
      node.setAttribute(
        "style",
        node.getAttribute("style") +
          "; background-color: unset; font-family: Work Sans; color: #333333"
      );
    }
  }

  useEffect(() => {
    setJobDetailsLoaded && setJobDetailsLoaded(true);
  }, []);

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12}>
        <Title size="medium" bold text="Details" />
      </Grid>
      <Grid
        id="descriptionContainer"
        item
        xs={12}
        dangerouslySetInnerHTML={{
          __html: container ? container.innerHTML : "",
        }}
      ></Grid>
    </Grid>
  );
};

export default JobDescriptionSection;
