import Title from "../Atoms/Typography/Title";
import Text from "../Atoms/Typography/Text";
import SecondaryBtn from "../Atoms/Buttons/SecondaryBtn";
import { Grid, Theme, Slide } from "@mui/material";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  [theme.breakpoints.up("lg")]: {
    gridContainerFullHeight: {
      minWidth: "100%",
      minHeight: "100vh",
    },
  },
  [theme.breakpoints.down("lg")]: {
    gridContainerFullHeight: {
      minHeight: "100vh",
    },
  },
  [theme.breakpoints.down("sm")]: {
    gridContainerFullHeight: {
      minHeight: "100vh",
    },
  },
}));

const NoResults: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={1000}>
      <Grid
        container
        xs={12}
        spacing={5}
        justifyContent="center"
        alignItems="center"
        alignContent="space-evenly"
        className={classes.gridContainerFullHeight}
      >
        <Grid item xs={12} />
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item container justifyContent="center" xs={12}>
            <Title text={"Upps, das war etwas zu spezifisch!"} size="small" />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Text text={"Weiter suchen?"} color={true} />
          </Grid>
          <Grid item container justifyContent="center" xs={2}>
            <SecondaryBtn
              label={"okay"}
              size="small"
              onClick={() => history.push("/")}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <img
              alt="Kein Stellen gefunden"
              src="/keinstellen.png"
              width="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default NoResults;
