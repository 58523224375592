import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { FieldProps, FormikErrors } from "formik";
import { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { TextField } from "@mui/material";
import { WorkExperience } from "../../../types/jobdetails";

export const useContainerStyles = makeStyles(() =>
  createStyles({
    datePickerContainer: { alignSelf: "flex-end" },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    fullWidth: { width: "100%" },
  })
);

interface DatePickerFieldProps {
  value?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  label: string;
  name: string;
  disabled?: boolean;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  label,
  disabled = false,
  value,
  setFieldValue,
  name,
}) => {
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      let dateParts = value.split(".");
      setDate(new Date(dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2]));
    }
  }, [value]);

  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        label={label}
        className={classes.fullWidth}
        inputFormat="dd.MM.yyyy"
        value={date}
        onChange={(date: Date) => {
          setDate(date);
          let dayString = "" + date?.getDate();
          let monthString =
            "" + (date?.getMonth() ? date?.getMonth() + 1 : "01");
          let yearString = "" + date?.getFullYear();

          if (dayString.length === 1) {
            dayString = "0" + dayString;
          }
          if (monthString.length === 1) {
            monthString = "0" + monthString;
          }

          let dateString = dayString + "." + monthString + "." + yearString;

          setFieldValue(name, dateString);
        }}
        renderInput={(params: any) => (
          <TextField fullWidth {...params} variant="standard" label={label} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
