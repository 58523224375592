/* eslint-disable no-fallthrough */
import { Box, Grid, Link, Tooltip, ClickAwayListener } from "@mui/material";
import {
  KeyFactProps,
  TravelAllowanceKeyFact,
  LanguageKeyFact,
  SalaryKeyFact,
} from "../../../types/jobdetails";
import {
  WorkOutline,
  Timelapse,
  GroupOutlined,
  BusinessOutlined,
  MonetizationOnOutlined,
  InsertInvitationOutlined,
  StarOutlineOutlined,
  HomeWorkOutlined,
  FavoriteBorderOutlined,
  CommuteOutlined,
  WbSunnyOutlined,
  DoneOutlined,
} from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import Text from "../../Atoms/Typography/Text";
import { ReactElement, useState } from "react";
// for holidays this might be better: https://www.flaticon.com/free-icon/sunset_1086426?term=holiday&page=1&position=4&page=1&position=4&related_id=1086426&origin=tag

const MAX_DISPLAYED_FACT_CHARS = 50;

const ICON_PROPS: { [key: string]: string } = {
  color: "primary",
  fontSize: "large",
};

const FACT_ICON_MAP: { [key: string]: ReactElement<any, any> } = {
  job_type: <WorkOutline {...ICON_PROPS} />,
  is_temporary: <Timelapse {...ICON_PROPS} />,
  amount_coworkers_company: <GroupOutlined {...ICON_PROPS} />,
  amount_coworkers_team: <GroupOutlined {...ICON_PROPS} />,
  industry: <BusinessOutlined {...ICON_PROPS} />,
  salary_info: <MonetizationOnOutlined {...ICON_PROPS} />,
  amount_holidays: <InsertInvitationOutlined {...ICON_PROPS} />,
  benefits: <StarOutlineOutlined {...ICON_PROPS} />,
  has_home_office: <HomeWorkOutlined {...ICON_PROPS} />,
  health_measurements: <FavoriteBorderOutlined {...ICON_PROPS} />,
  allowance_travel: <CommuteOutlined {...ICON_PROPS} />,
  languages: <LanguageIcon {...ICON_PROPS} />,
  remote: <DoneOutlined {...ICON_PROPS} />,
};

const FACT_NAME_MAP: { [key: string]: string } = {
  job_type: "Jobart",
  is_temporary: "Befristeter Vertrag",
  amount_coworkers_company: "Anzahl Mitarbeiter:innen Unternehmen",
  amount_coworkers_team: "Anzahl Mitarbeiter:innen Team",
  industry: "Branche",
  salary_info: "Gehalt",
  amount_holidays: "Urlaubstage",
  benefits: "Benefits",
  has_home_office: "Homeoffice möglich",
  health_measurements: "Gesundheitsmaßnahmen",
  allowance_travel: "Fahrtkostenzuschuss",
  languages: "Sprachkenntnisse",
  remote: "Remote Arbeit möglich",
};

const extractValueFromObject = (
  factName: string,
  factValue: TravelAllowanceKeyFact | LanguageKeyFact | SalaryKeyFact
): string => {
  switch (factName) {
    case "languages":
      let languageKnowledge: string[] = [];
      if ("german_knowledge" in factValue) {
        languageKnowledge.push("Deutsch");
      }
      if ("english_knowledge" in factValue) {
        languageKnowledge.push("Englisch");
      }
      return languageKnowledge.join(", ");
    case "allowance_travel":
      if (
        "allowance" in factValue &&
        factValue.allowance &&
        factValue.allowance_details
      ) {
        return factValue.allowance_details;
      }
      break;
    case "salary_info":
      if ("salary" in factValue && factValue.show_salary && factValue.salary) {
        return factValue.salary;
      }
      break;
  }
  return "";
};

const getResultingFactValue = ({ factName, factValue }: KeyFactProps) => {
  switch (typeof factValue) {
    case "object":
      if (factValue && factValue instanceof Array) {
        return factValue.join(", ");
      } else {
        return extractValueFromObject(factName, factValue);
      }
    case "boolean":
      return "";
    case "number":
      return factValue.toString();
    case "string":
      return factValue;
  }
};

const KeyFact: React.FC<KeyFactProps> = ({ factName, factValue }) => {
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  const [isTooltipHovered, setIsTooltipHovered] = useState<boolean>(false);
  const [isMouseInTooltip, setIsMouseInTooltip] = useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<any>(null);
  const resultingFactValue = getResultingFactValue({ factName, factValue });
  const shownFactValue = resultingFactValue.substring(
    0,
    MAX_DISPLAYED_FACT_CHARS
  );

  return (
    <Grid container alignItems="center" item xs={12} md={6} spacing={2}>
      <Grid item>{FACT_ICON_MAP[factName]}</Grid>
      <Grid style={{ pointerEvents: "auto" }} item xs>
        {factName && factValue && (
          <>
            <Text
              inline
              text={
                FACT_NAME_MAP[factName] +
                (typeof factValue === "boolean" ? "" : ": ")
              }
            />
            {resultingFactValue.length > MAX_DISPLAYED_FACT_CHARS ? (
              <ClickAwayListener
                onClickAway={() => {
                  setIsTooltipClicked(false);
                  setIsTooltipHovered(false);
                }}
              >
                <Tooltip
                  onMouseOver={() => {
                    clearTimeout(tooltipTimeout);
                    setIsTooltipHovered(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipTimeout(
                      setTimeout(() => {
                        setIsTooltipHovered(false);
                      }, 500)
                    );
                  }}
                  onClick={() => {
                    setIsTooltipClicked(true);
                  }}
                  open={
                    isTooltipClicked || isTooltipHovered || isMouseInTooltip
                  }
                  arrow
                  placement="top"
                  title={
                    <Box
                      style={{ padding: 8 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onMouseOver={() => {
                        setIsMouseInTooltip(true);
                      }}
                      onMouseLeave={() => {
                        setIsMouseInTooltip(false);
                      }}
                      fontSize={15}
                    >
                      {resultingFactValue}
                    </Box>
                  }
                >
                  <Link underline="none">
                    <Text
                      inline
                      underline="dotted"
                      text={shownFactValue + "..."}
                    />
                  </Link>
                </Tooltip>
              </ClickAwayListener>
            ) : (
              <Text inline text={shownFactValue} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default KeyFact;
