import { CircularProgress, Grid } from "@mui/material";
import Text from "../../../Atoms/Typography/Text";
import Title from "../../../Atoms/Typography/Title";
import PrimaryBtn from "../../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../../Atoms/Buttons/SecondaryBtn";
import { apiBaseUrl } from "../../../../utils/environmentDependencies";
import { useState } from "react";
import {
  fireGAEvent,
  GA_CATEGORY_RECUNITED_RESULTS,
} from "../../../../utils/googleAnalytics";

interface RecunitedMatchingConfirmProps {
  setApplicationSectionShown: (section: string) => void;
  recunitedId?: number;
  candidateId?: number;
  jobId?: number;
}

const RecunitedMatchingConfirm: React.FC<RecunitedMatchingConfirmProps> = ({
  setApplicationSectionShown,
  recunitedId,
  candidateId,
  jobId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return recunitedId && candidateId && jobId ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Title text="Deine Matching-Ergebnisse" size="medium" bold />
        <Text text="Wir haben gesehen, dass du den Recunited-Wertetest abgeschlossen hast. Wenn du möchtest, kannst du deine Matching-Ergebnisse zu deiner Bewerbung hinzufügen. Der Arbeitgeber kann daraufhin einsehen, wie gut ihr zusammenpasst." />
      </Grid>
      {isLoading ? (
        <Grid item container xs={12} justifyContent="center">
          <CircularProgress size={24} />
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <PrimaryBtn
              label="Matching-Ergebnisse mitsenden"
              onClick={() => {
                fireGAEvent(
                  GA_CATEGORY_RECUNITED_RESULTS,
                  GA_CATEGORY_RECUNITED_RESULTS.actions.sendResult,
                  `id: ${jobId}`
                );
                setIsLoading(true);
                let body = {
                  recunited_id: recunitedId,
                  candidate_id: candidateId,
                  job_id: jobId,
                };
                fetch(
                  `${apiBaseUrl}/recunited/questionnaire/send_matching_scores`,
                  {
                    method: "POST",
                    body: JSON.stringify(body),
                  }
                )
                  .then((res) => {
                    setIsLoading(false);
                    setApplicationSectionShown("SUCCESS");
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    setApplicationSectionShown("ERROR");
                  });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SecondaryBtn
              label="Matching-Ergebnisse nicht mitsenden"
              onClick={() => {
                setApplicationSectionShown("SUCCESS");
                fireGAEvent(
                  GA_CATEGORY_RECUNITED_RESULTS,
                  GA_CATEGORY_RECUNITED_RESULTS.actions.dontSendResult,
                  `id: ${jobId}`
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    <div />
  );
};

export default RecunitedMatchingConfirm;
