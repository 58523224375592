import { Grid, Divider, Theme, Slide, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import Footer from "../Molecules/Footer";
import ContainerSearch from "../Molecules/LandingPage/ContainerSearch";
import RecruitingProcess from "../Organisms/LandingPage/RecruitingProcess";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { fireGAPageView } from "../../utils/googleAnalytics";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  [theme.breakpoints.up("lg")]: {
    gridContainerFullHeight: {
      minWidth: "100%",
      minHeight: "100vh ",
    },
  },
  [theme.breakpoints.down("lg")]: {
    gridContainerFullHeight: {
      height: "none",
    },
  },
}));

const scrollUp = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const JobPortal: React.FC = () => {
  useEffect(() => {
    fireGAPageView();
  });
  const classes = useStyles();
  return (
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={500}>
      <Grid
        container
        justifyContent="center"
        alignContent="flex-start"
        spacing={8}
        className={classes.root}
      >
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          className={classes.gridContainerFullHeight}
        >
          <ContainerSearch />
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          spacing={1}
          style={{ minHeight: "100vh" }}
        >
          <RecruitingProcess />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <IconButton aria-label="scroll-up" onClick={() => scrollUp()}>
            <ArrowUpwardIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default JobPortal;
