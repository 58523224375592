import { CircularProgress, Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import {
  ApplicationFormValues,
  ApplicationTypeSelectionProps,
} from "../../types/jobdetails";
import { apiBaseUrl } from "../../utils/environmentDependencies";
import {
  fireGAEvent,
  GA_CATEGORY_JOB_DETAILS,
} from "../../utils/googleAnalytics";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import BaseUserInformationFormPart from "../Organisms/Forms/BaseUserInformationFormPart";
import EducationFormPart from "../Organisms/Forms/EducationFormPart";
import FinalizationFormPart from "../Organisms/Forms/FinalizationFormPart";
import LanguageFormPart from "../Organisms/Forms/LanguageFormPart";
import SkillsFormPart from "../Organisms/Forms/SkillsFormPart";
import WorkExperienceFormPart from "../Organisms/Forms/WorkExperienceFormPart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredForm: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const schema = yup.object({
  baseUserInfo: yup.object({
    firstName: yup.string().required("Bitte Vorname angeben"),
    lastName: yup.string().required("Bitte Nachname angeben"),
    email: yup
      .string()
      .required("Bitte Email angeben")
      .email("Email ist nicht gültig"),
    academicTitle: yup.string(),
    phone: yup.string().required("Bitte Telefonnummer angeben"),
  }),
  workExperience: yup.array(yup.object()),
  skills: yup.array(yup.string()),
  languages: yup.object(),
  education: yup.array(yup.object()),
  dsgvo: yup.bool().oneOf([true], "Bitte der DSGVO zustimmen"),
  noticePeriod: yup.string(),
  earliestDateToStart: yup.string().required("Bitte Startzeitpunkt angeben"),
  expectedSalary: yup.string().required("Bitte Gehaltsvorstellung angeben"),
});

const ApplicationForm: React.FC<ApplicationTypeSelectionProps> = ({
  jobAdId,
  setApplicationSectionShown,
  prefilledUserData,
  setRecunitedId,
  setCandidateId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues: ApplicationFormValues = prefilledUserData
    ? prefilledUserData
    : {
        baseUserInfo: {
          firstName: "",
          lastName: "",
          email: "",
          academicTitle: "",
          phone: "",
        },
        workExperience: [],
        skills: [],
        languages: {},
        education: [],
        dsgvo: false,
        earliestDateToStart: "",
        expectedSalary: "",
        noticePeriod: "",
      };

  const classes = useStyles();

  const handleSubmit = async (values: any) => {
    console.log("triggered");
    fireGAEvent(
      GA_CATEGORY_JOB_DETAILS,
      GA_CATEGORY_JOB_DETAILS.actions.finishApplication,
      `id: ${jobAdId}`
    );
    setIsLoading(true);

    let body = {
      ...values,
      job_ad_id: jobAdId,
    };
    fetch(`${apiBaseUrl}/job_portal/apply_with_form_searchtalent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          setApplicationSectionShown("SUCCESS");
        } else {
          setApplicationSectionShown("ERROR");
        }
        setIsLoading(false);
        return response.json();
      })
      .then((data) => {
        if (data.recunited_id && data.candidate_id) {
          setRecunitedId && setRecunitedId(data.recunited_id);
          setCandidateId(data.candidate_id);
          if (localStorage.getItem(jobAdId ? jobAdId + "" : "")) {
            setApplicationSectionShown("RECUNITED_MATCHING");
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialTouched: {
      baseUserInfo: {
        firstName: false,
        lastName: false,
        email: false,
        academicTitle: false,
        phone: false,
      },
      workExperience: false,
      skills: false,
      languages: false,
      education: false,
      dsgvo: false,
      earliestDateToStart: false,
      expectedSalary: false,
      noticePeriod: false,
    },
  });

  console.log("formik:", formik);
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.centeredForm}
      noValidate
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid container item xs={12} justifyContent="center">
          <BaseUserInformationFormPart formik={formik} />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <WorkExperienceFormPart
            workExperience={formik.values.workExperience ?? []}
            setWorkExperience={formik.setFieldValue}
            fieldName={"workExperience"}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <EducationFormPart
            education={formik.values.education ?? []}
            setEducation={formik.setFieldValue}
            fieldName={"education"}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LanguageFormPart
            languages={formik.values.languages ?? {}}
            setLanguages={formik.setFieldValue}
            fieldName={"language"}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <SkillsFormPart
            skills={formik.values.skills ?? []}
            setSkills={formik.setFieldValue}
            fieldName={"skills"}
          />
        </Grid>
        <Grid container item xs={12}>
          <FinalizationFormPart formik={formik} />
        </Grid>
        <Grid container item xs={12} spacing={2} justifyContent="center">
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            style={{ marginBottom: "1rem" }}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <PrimaryBtn onClick={(window as any).lintrk("track", { conversion_id: 1130360 })} label={"Absenden"} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ApplicationForm;
