import { Grid, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { ApplicationFormValues } from "../../../types/jobdetails";
import { XING_ACADEMIC_TITLES } from "../../../utils/XingUtils";
import Title from "../../Atoms/Typography/Title";
import FormPhoneInput from "../../Molecules/FormFields/FormPhoneInput";
import FormSelect from "../../Molecules/FormFields/FormSelect";

const BaseUserInformationFormPart: React.FC<{
  formik: FormikProps<ApplicationFormValues>;
}> = ({ formik }) => {
  console.log(formik);
  return (
    <Grid item container xs={12} spacing={2} justifyContent="center">
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Allgemein" />
      </Grid>
      {/* Academic title */}
      <Grid item xs={12} md={2}>
        <FormSelect
          menuItems={XING_ACADEMIC_TITLES}
          label="Titel"
          required={false}
          error={
            formik.touched.baseUserInfo &&
            formik.touched.baseUserInfo.academicTitle &&
            Boolean(formik.errors.baseUserInfo) &&
            Boolean(formik.errors.baseUserInfo?.academicTitle)
              ? formik.errors.baseUserInfo?.academicTitle
              : ""
          }
          {...formik.getFieldProps("baseUserInfo.academicTitle")}
        />
      </Grid>
      {/* First name */}
      <Grid item xs={12} md={5}>
        <TextField
          required
          fullWidth
          variant="standard"
          label="Vorname"
          error={
            formik.touched.baseUserInfo &&
            formik.touched.baseUserInfo.firstName &&
            Boolean(formik.errors.baseUserInfo) &&
            Boolean(formik.errors.baseUserInfo?.firstName)
          }
          helperText={
            formik.errors.baseUserInfo?.firstName &&
            formik.touched.baseUserInfo?.firstName
              ? formik.errors.baseUserInfo?.firstName
              : ""
          }
          {...formik.getFieldProps("baseUserInfo.firstName")}
        />
      </Grid>
      {/* Last Name */}
      <Grid item xs={12} md={5}>
        <TextField
          required
          fullWidth
          variant="standard"
          label="Nachname"
          error={
            formik.touched.baseUserInfo &&
            formik.touched.baseUserInfo.lastName &&
            Boolean(formik.errors.baseUserInfo) &&
            Boolean(formik.errors.baseUserInfo?.lastName)
          }
          helperText={
            formik.errors.baseUserInfo?.lastName &&
            formik.touched.baseUserInfo?.lastName
              ? formik.errors.baseUserInfo?.lastName
              : ""
          }
          {...formik.getFieldProps("baseUserInfo.lastName")}
        />
      </Grid>
      {/* Email */}
      <Grid item xs={12} md={7}>
        <TextField
          required
          fullWidth
          variant="standard"
          label="Email"
          error={
            formik.touched.baseUserInfo &&
            formik.touched.baseUserInfo.email &&
            Boolean(formik.errors.baseUserInfo) &&
            Boolean(formik.errors.baseUserInfo?.email)
          }
          helperText={
            formik.errors.baseUserInfo?.email &&
            formik.touched.baseUserInfo?.email
              ? formik.errors.baseUserInfo?.email
              : ""
          }
          {...formik.getFieldProps("baseUserInfo.email")}
        />
      </Grid>
      {/* Phone */}
      <Grid item xs={12} md={5}>
        <FormPhoneInput
          required
          name="baseUserInfo.phone"
          handleChange={formik.setFieldValue}
          error={formik.errors.baseUserInfo?.phone}
          touched={formik.touched.baseUserInfo?.phone}
          label="Telefon"
          formikProps={formik.getFieldProps("baseUserInfo.phone")}
        />
      </Grid>
    </Grid>
  );
};

export default BaseUserInformationFormPart;
