import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
interface SuccessDialogProps {
  isSuccessDialogShown: boolean;
  setIsSuccessDialogShown: (isSignupShown: boolean) => void;
  title: string;
  text: string;
  success: boolean;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = ({
  setIsSuccessDialogShown,
  isSuccessDialogShown,
  title,
  text,
  success,
}) => {
  return (
    <Dialog
      onClose={() => {
        setIsSuccessDialogShown(false);
      }}
      maxWidth="sm"
      open={isSuccessDialogShown}
    >
      <DialogTitle>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="flex-start"
        >
          {success ? (
            <SentimentSatisfiedAltIcon />
          ) : (
            <SentimentVeryDissatisfiedIcon />
          )}
          <Title size="medium" text={title} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Text text={text} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
