import { Divider, Grid, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiBaseUrl } from "../../utils/environmentDependencies";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../Atoms/Buttons/SecondaryBtn";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";
import { DeleteDialog } from "../Molecules/DeleteDialog";
import { AccountSettings } from "../Molecules/ProfilePage/AccountSettings";
import { JobSearchDetailsSection } from "../Molecules/ProfilePage/JobSearchDetailsSection";
import { ProfileSection } from "../Molecules/ProfilePage/ProfileSection";

const DUMMY_DATA_PERSONAL = [
  "Noam Rubin",
  "Full stack web developer",
  "Looking for a job in Berlin",
];

const DUMMY_DATA_JOB = [
  "Applied for 5 jobs",
  "Currently working for",
  "Salary expectation",
];

export interface Education {
  degree?: string;
  field?: string;
  from?: string;
  location?: string;
  till_today?: boolean;
  to?: string;
}

export interface WorkExperience {
  company?: string;
  description?: string;
  from?: string;
  till_today?: boolean;
  title?: string;
  to?: string;
}

export interface ProfileData {
  academic_title?: string;
  active_email?: string;
  cancellation_period?: string;
  earliest_start_date?: string;
  education?: Education[];
  first_name?: string;
  gender?: string;
  languages?: string[];
  last_name?: string;
  mobile_phone?: string;
  salary_expectation?: string;
  skills?: string[];
  work_experience?: WorkExperience[];
}

export const ProfilePage: React.FC = () => {
  const [isDeleteDialogShown, setIsDeleteDialogShown] =
    useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileData>({});
  // const tempId = 7257000009622312;
  const tempId = 7257000009470074;

  useEffect(() => {
    fetch(`${apiBaseUrl}/job_portal/profile/${tempId}`)
      .then((response) => response.json())
      .then((data) => {
        setProfileData(data);
      })
      .catch((error) => {});
  }, []);
  return (
    <Slide direction="up" appear in timeout={1000}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        spacing={4}
      >
        <Grid item xs={12} />
        <Grid item xs={12}>
          <ProfileSection data={profileData} />
        </Grid>
        <Grid item xs={12}>
          <JobSearchDetailsSection data={profileData} id={tempId} />
        </Grid>
        <Grid item xs={12}>
          <AccountSettings setIsDeleteDialogShown={setIsDeleteDialogShown} />
        </Grid>
        <DeleteDialog
          setIsDeleteDialogShown={setIsDeleteDialogShown}
          isDeleteDialogShown={isDeleteDialogShown}
          id={tempId}
        />
      </Grid>
    </Slide>
  );
};
