import { Grid, IconButton } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { TimelineEntry } from "../../../types/jobdetails";
import Text from "../../Atoms/Typography/Text";
import Title from "../../Atoms/Typography/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface TimelineFormFieldProps {
  data: TimelineEntry[];
  handleDeleteStation: (index: number) => void;
  setEditIndex: (index: number) => void;
  setDialogOpen: (isOpen: boolean) => void;
}

const TimelineFormField: React.FC<TimelineFormFieldProps> = ({
  data,
  handleDeleteStation,
  setEditIndex,
  setDialogOpen,
}) => {
  return (
    <Timeline>
      {data.map((entry, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Text
              text={
                (entry.from ? "Von " + entry.from + " " : "") +
                (entry.to ? "bis " + entry.to : "")
              }
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Grid container>
              <Grid item>
                <Title size="small" text={entry.subtitle} />
                <Text text={entry.description} />
              </Grid>
              <Grid item xs>
                <IconButton
                  onClick={() => {
                    setEditIndex(index);
                    setDialogOpen(true);
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDeleteStation(index);
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TimelineFormField;
