import { Grid, Hidden } from "@mui/material";
import { FoundResultsProps } from "../../../types/searchresults";
import SearchResultsWeb from "./SearchResultsWeb";
import SearchResultsMobile from "./SearchResultsMobile";
import Text from "../../Atoms/Typography/Text";

const FoundResults: React.FC<FoundResultsProps> = ({
  searchResults,
  page,
  totalResults,
  resultsMobile,
  setPage,
}) => {
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Text
          text={!totalResults ? "" : totalResults.toString() + " Jobs gefunden"}
        />
      </Grid>
      <Hidden mdUp>
        <SearchResultsMobile
          resultsMobile={resultsMobile}
          setPage={setPage}
          page={page}
        />
      </Hidden>
      <Hidden mdDown>
        <SearchResultsWeb searchResults={searchResults} />
      </Hidden>
    </Grid>
  );
};

export default FoundResults;
