import ReactGA from "react-ga4";

type GoogleAnalyticsActionSearch =
  | "executeAdvancedSearch"
  | "executeBasicSearch";

type GoogleAnalyticsActionSearchResults = "clickResult" | "clickOtherPage";

type GoogleAnalyticsActionJobDetails =
  | "clickApply"
  | "clickApplyCV"
  | "clickApplyXING"
  | "clickApplyForm"
  | "finishApplication";

type GoogleAnalyticsActionUserManagement = "logIn";

type GoogleAnalyticsActionRecunitedApplication =
  | "clickApplyIntro"
  | "clickApplyDialog";

type GoogleAnalyticsActionRecunitedMatchingResults =
  | "sendResult"
  | "dontSendResult";

export type GoogleAnalyticsAction =
  | GoogleAnalyticsActionSearch
  | GoogleAnalyticsActionSearchResults
  | GoogleAnalyticsActionJobDetails
  | GoogleAnalyticsActionUserManagement
  | GoogleAnalyticsActionRecunitedApplication
  | GoogleAnalyticsActionRecunitedMatchingResults;

export interface GoogleAnalyticsCategory<
  ActionType extends GoogleAnalyticsAction
> {
  name: string;
  actions: { [key: string]: ActionType };
}

export const GA_CATEGORY_SEARCH: GoogleAnalyticsCategory<GoogleAnalyticsActionSearch> =
  {
    name: "search",
    actions: {
      executeAdvancedSearch: "executeAdvancedSearch",
      executeBasicSearch: "executeBasicSearch",
    },
  };

export const GA_CATEGORY_SEARCH_RESULTS: GoogleAnalyticsCategory<GoogleAnalyticsActionSearchResults> =
  {
    name: "searchResults",
    actions: {
      clickResult: "clickResult",
      clickOtherPage: "clickOtherPage",
    },
  };

export const GA_CATEGORY_JOB_DETAILS: GoogleAnalyticsCategory<GoogleAnalyticsActionJobDetails> =
  {
    name: "jobDetails",
    actions: {
      clickApply: "clickApply",
      clickApplyCV: "clickApplyCV",
      clickApplyXING: "clickApplyXING",
      clickApplyForm: "clickApplyForm",
      finishApplication: "finishApplication",
    },
  };

export const GA_CATEGORY_USER_MANAGEMENT: GoogleAnalyticsCategory<GoogleAnalyticsActionUserManagement> =
  {
    name: "userManagement",
    actions: {
      logIn: "logIn",
    },
  };

export const GA_CATEGORY_RECUNITED_APPLICATION: GoogleAnalyticsCategory<GoogleAnalyticsActionRecunitedApplication> =
  {
    name: "onApplication",
    actions: {
      clickApplyIntro: "clickApplyIntro",
      clickApplyDialog: "clickApplyDialog",
    },
  };

export const GA_CATEGORY_RECUNITED_RESULTS: GoogleAnalyticsCategory<GoogleAnalyticsActionRecunitedMatchingResults> =
  {
    name: "afterApplication",
    actions: {
      sendResult: "sendResult",
      dontSendResult: "dontSendResult",
    },
  };
export function fireGAEvent<ActionType extends GoogleAnalyticsAction>(
  category: GoogleAnalyticsCategory<ActionType>,
  action: ActionType,
  label?: string
): void {
  ReactGA.event({ category: category.name, action, label });
}

export function fireGAPageView(): void {
  ReactGA.send("pageview");
}
