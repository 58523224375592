import { CircularProgress, Divider, Grid } from "@mui/material";
import { ConnectedFocusError } from "focus-formik-error";
import { Form, Formik } from "formik";
import { useState } from "react";
import { IndexKind } from "typescript";
import { apiBaseUrl } from "../../../utils/environmentDependencies";
import PrimaryBtn from "../../Atoms/Buttons/PrimaryBtn";
import SecondaryBtn from "../../Atoms/Buttons/SecondaryBtn";
import Text from "../../Atoms/Typography/Text";
import Title from "../../Atoms/Typography/Title";
import { ProfileData } from "../../Templates/ProfilePage";
import { SuccessDialog } from "../SuccessDialog";
import UploadFile from "../UploadFile";

interface JobSearchDetailsProps {
  data: ProfileData;
  id: number;
}
type UploadedCV = {
  uploadedCV?: File | undefined;
};
export const JobSearchDetailsSection: React.FC<JobSearchDetailsProps> = ({
  data,
  id,
}) => {
  const [isUploadBtnShown, setIsUploadBtnShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessDialogShown, setIsSuccessDialogShown] =
    useState<boolean>(false);
  const [success, setSucces] = useState<boolean>(false);

  const initialValues: UploadedCV = {
    uploadedCV: undefined,
  };

  return (
    <Grid item container xs={12}>
      <Grid item container direction="column" xs={12} spacing={1}>
        <Grid item xs={12}>
          <Title size="medium" bold text={"Arbeit"} />
        </Grid>
        <Grid item xs={12}>
          {data.salary_expectation && (
            <>
              <Title size="small" text="Gehaltsvorstellung" />
              <Text text={`€${data.salary_expectation}`} />
            </>
          )}
        </Grid>
        {data.work_experience && data.work_experience.length > 0 && (
          <Grid item container xs={12}>
            <Title size="small" text="Erfahrung" />
            <Grid item container xs={12} spacing={3}>
              {data.work_experience?.map((experience, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    {experience.title && (
                      <>
                        <Text bold text={experience.title} />
                      </>
                    )}
                    {experience.company && (
                      <>
                        <Text text={experience.company} />
                      </>
                    )}
                    {experience.from && (
                      <Text
                        color={true}
                        text={`von ${experience.from} bis ${
                          experience.till_today ? "heute" : experience.to
                        }`}
                      />
                    )}
                    {experience.description && (
                      <Text text={experience.description} />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={3}>
          <Grid item xs={12} md={4}>
            {!isUploadBtnShown && (
              <PrimaryBtn
                label={"neuen Lebenslauf hochladen"}
                onClick={() => setIsUploadBtnShown(!isUploadBtnShown)}
              />
            )}
            {isSuccessDialogShown && (
              <SuccessDialog
                isSuccessDialogShown={isSuccessDialogShown}
                setIsSuccessDialogShown={setIsSuccessDialogShown}
                title={success ? "Geschafft" : "Leider"}
                text={
                  success
                    ? "Lebenslauf ist erfolgreich hochgeladen"
                    : "Etwas ist schiefgelaufen, versuch nochmals"
                }
                success={success}
              />
            )}
            {isUploadBtnShown && (
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  let formData = new FormData();
                  if (values.uploadedCV) {
                    formData.append("uploadedCV", values.uploadedCV);
                  }

                  setIsLoading(true);
                  fetch(`${apiBaseUrl}/job_portal/cv/${id}`, {
                    method: "POST",
                    body: formData,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        setIsUploadBtnShown(false);
                        setSucces(true);
                        setIsSuccessDialogShown(true);
                      } else {
                        setSucces(false);
                        setIsSuccessDialogShown(true);
                      }
                      setIsLoading(false);
                    })
                    .catch(() => {
                      setIsLoading(false);
                    });
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form noValidate>
                    <ConnectedFocusError />
                    <Title size="small" text={"Lebenslaub"} />
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <UploadFile
                          fileError={Boolean(
                            touched.uploadedCV && Boolean(errors.uploadedCV)
                          )}
                          label="Datei per Drag & Drop hinzufügen oder hier klicken (max. 20 MB)"
                          errorMessage={
                            errors.uploadedCV ? errors.uploadedCV : ""
                          }
                          onChange={(files) => {
                            setFieldValue("uploadedCV", files[0]);
                          }}
                        />
                      </Grid>
                      {values.uploadedCV && !errors.uploadedCV && (
                        <Grid item xs={12}>
                          <Text inline text="Deine Datei " />
                          <Text inline text={values.uploadedCV?.name} color />
                          <Text inline text=" wurde erfolgreich hinzugefügt!" />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item container xs={12} justifyContent="center">
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <PrimaryBtn onClick={(window as any).lintrk("track", { conversion_id: 1130360 })} label={"Absenden"} />
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};
