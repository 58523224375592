import {
  Container,
  Grid,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./components/Organisms/Header";
import AfterSignUpPage from "./components/Templates/AfterSignUpPage";
import JobDetails from "./components/Templates/JobDetails";
import JobPortal from "./components/Templates/JobPortal";
import NotFound from "./components/Templates/NotFound";
import { ProfilePage } from "./components/Templates/ProfilePage";
import SearchResults from "./components/Templates/SearchResults";
import { SignInPage } from "./components/Templates/SignInPage";
import { SignUpPage } from "./components/Templates/SignUpPage";
import PortalRedirect from "./PortalRedirect";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactPixel.init("121617349974896");
      ReactPixel.init("2318170248475692");
      ReactPixel.pageView();
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#007d8a",
        light: "#00c2d7",
        dark: "#004f57",
      },
      secondary: {
        main: "#f8f8f8;",
        light: "#ffffff",
        dark: "#cccccc",
      },
      text: {
        primary: "#333333",
        secondary: "#4F4F4F",
      },
    },
    typography: {
      fontFamily: "Work Sans",
    },
  });

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Header />
          <Switch>
            {/* Redirects in order to ensure integrity with old Zoho links. Can be removed in a bit */}
            <Route
              path="/clientportal"
              render={() => (
                <Container maxWidth="lg">
                  <PortalRedirect />
                </Container>
              )}
            />
            <Redirect from="/jobs/Careers/:id/:name" to="/jobdetails/:id" />
            <Redirect from="/jobs/Careers" to="/" />
            <Route
              exact
              path="/"
              render={() => (
                <Container maxWidth="lg">
                  <JobPortal />
                </Container>
              )}
            />
            <Route
              exact
              path="/search"
              render={() => (
                <Container maxWidth="lg">
                  <SearchResults />
                </Container>
              )}
            />
            <Route
              exact
              path="/profile"
              render={() => (
                <Container maxWidth="lg">
                  <ProfilePage />
                </Container>
              )}
            />
            <Route
              exact
              path="/sign-in"
              render={() => (
                <Container maxWidth="lg">
                  <SignInPage />
                </Container>
              )}
            />
            <Route
              exact
              path="/sign-up"
              render={() => (
                <Container maxWidth="lg">
                  <SignUpPage />
                </Container>
              )}
            />
            <Route
              exact
              path="/sign-up-success"
              render={() => (
                <Container maxWidth="lg">
                  <AfterSignUpPage />
                </Container>
              )}
            />
            <Route
              exact
              path="/jobdetails/:id"
              render={() => (
                <div className="detail-container">
                  <Grid item className="header-container" id="header"></Grid>
                  <Container maxWidth="lg" className="job-detail-container">
                    <JobDetails />
                  </Container>
                </div>
              )}
            />
            <Route
              render={() => (
                <Container maxWidth="lg">
                  <NotFound />
                </Container>
              )}
            />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
