import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  IconButton,
  Input,
  FormHelperText,
} from "@mui/material";
import ChipListFormField from "../../Molecules/FormFields/ChipListFormField";
import AddIcon from "@mui/icons-material/Add";
import { useReducer, useState } from "react";
import Title from "../../Atoms/Typography/Title";
import Text from "../../Atoms/Typography/Text";

interface SkillsFormPartProps {
  skills: string[];
  setSkills: (fieldName: string, skills: string[]) => void;
  fieldName: string;
}

const SkillsFormPart: React.FC<SkillsFormPartProps> = ({
  skills,
  setSkills,
  fieldName,
}) => {
  const [skillToAdd, setSkillToAdd] = useState("");
  const [helperText, setHelperText] = useState("");

  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const handleDeleteSkill = (skill: string) => {
    let newSkills = skills;
    newSkills.splice(skills.indexOf(skill), 1);
    setSkills(fieldName, newSkills);
    forceUpdate();
  };

  const handleAddSkill = () => {
    if (skillToAdd.length === 0) {
      setHelperText(
        "Wert hier einfügen, dann auf '+' oder die Enter-Taste drücken"
      );
    } else if (skills.includes(skillToAdd)) {
      setHelperText("Fähigkeit ist bereits in der Liste enthalten");
    } else {
      let newSkills = skills;
      newSkills.push(skillToAdd);
      setSkills(fieldName, newSkills);
      setSkillToAdd("");
    }
  };

  return (
    <Grid item container xs={12} spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Title noH size="small" text="Fähigkeiten" />
      </Grid>
      {/* List of skills */}
      <Grid item xs={12}>
        {!!skills && skills.length > 0 ? (
          <ChipListFormField deleteItem={handleDeleteSkill} items={skills} />
        ) : (
          <Text text="Noch keine Fähigkeiten angegeben" />
        )}
      </Grid>
      {/* Input for adding new skills */}
      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="skill-input">Fähigkeit hinzufügen</InputLabel>
          <Input
            id="skill-input"
            value={skillToAdd}
            onChange={(e) => {
              setHelperText("");
              setSkillToAdd(e.target.value);
            }}
            onKeyDown={(e) => {
              // despite being deprecated, 'keyCode' is needed for some browsers
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault();
                handleAddSkill();
              }
            }}
            onFocus={(e) => {
              setHelperText("");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    handleAddSkill();
                  }}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          ></Input>
          <FormHelperText error={Boolean(helperText)}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SkillsFormPart;
