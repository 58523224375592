import { Link, useHistory } from "react-router-dom";
import { useAuthentication } from "../../utils/useAuthentication";
import { useState } from "react";
import {
  Alert,
  CircularProgress,
  // Button,
  // Divider,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import PrimaryBtn from "../Atoms/Buttons/PrimaryBtn";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Title from "../Atoms/Typography/Title";
import axios from "axios";
// import { Facebook, Google, LinkedIn } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitImage: {
      maxWidth: "100%",
      marginTop: "auto",
      marginBottom: "auto",
    },
    PageWrapper: {
      marginTop: "24px",
      marginBottom: "24px",
    },
    boxShadow: {
      boxShadow: "7px 1px 75px -19px rgba(109,130,21,0.30)",
    },
  })
);

export const SignInPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shownLoginError, setShownLoginError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { signIn } = useAuthentication();
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.PageWrapper}>
      <form
        onSubmit={async (event) => {
          setIsLoading(true);
          event.preventDefault();
          try {
            await signIn(email, password);
            // TODO: probably wanna go back to the page previously visited by the user
            history.push("/");
          } catch (err) {
            setIsLoading(false);
            if (axios.isAxiosError(err)) {
              if (err.response?.status === 400) {
                // Error message for non-confirmed email
                setShownLoginError(
                  "Deine Email-Adresse wurde noch nicht bestätigt. Bitte schaue in deinem Email-Postfach nach"
                );
              } else if (err.response?.status === 401) {
                // Error message for faulty login credentials
                setShownLoginError(
                  "Fehlerhafte Login-Daten. Bitte überprüfe deine Eingabe und versuche es erneut"
                );
              } else {
                // Unexpected error
                setShownLoginError(
                  "Ein unerwarteter Fehler ist aufgetreten. Helfe uns, ihn zu beheben und schicke eine Email mit den Einzelheiten an support@jobs.searchtalent.com"
                );
              }
            } else {
              // Even more unexpected error
              setShownLoginError(
                "Ein unerwarteter Fehler ist aufgetreten. Helfe uns, ihn zu beheben und schicke eine Email mit den Einzelheiten an support@jobs.searchtalent.com"
              );
            }
          }
        }}
      >
        <Grid
          container
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            xs={12}
            md={5}
            container
            item
            spacing={4}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item xs={12}>
              <Title size="large" text={"Bei Searchtalent anmelden"} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.boxShadow}
                fullWidth
                label="E-Mail"
                variant="outlined"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.boxShadow}
                fullWidth
                label="Passwort"
                type="password"
                variant="outlined"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <PrimaryBtn label="Anmelden" disabled={!(email && password)} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Du hast noch kein Nutzerkonto?{" "}
                <Link to={"/sign-up"}>Registrieren</Link>
              </Typography>
            </Grid>
            {/* comment out below lines for oauth2 sign up services */}
            {/* <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={5}>
                <Divider />
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                oder
              </Grid>
              <Grid item xs={5}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                startIcon={<LinkedIn />}
              >
                Login mit LinkedIn
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                startIcon={<Google />}
              >
                Login mit Google
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                startIcon={<Facebook />}
              >
                Login mit Facebook
              </Button>
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={7}>
            <img
              className={classes.fitImage}
              src={"/login.svg"}
              alt="Einloggen"
            />
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={Boolean(shownLoginError)}
        onClose={() => setShownLoginError("")}
        autoHideDuration={5000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {shownLoginError}
        </Alert>
      </Snackbar>
    </div>
  );
};
