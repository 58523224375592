import { Grid } from "@mui/material";
import { ApplicationTypeSelectionProps } from "../../types/jobdetails";
import ApplicationTypePicker from "../Organisms/ApplicationTypePicker";

const ApplicationTypeSelection: React.FC<ApplicationTypeSelectionProps> = ({
  jobAdId,
  setApplicationSectionShown,
}) => {
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} justifyContent="center">
        <ApplicationTypePicker
          setApplicationSectionShown={setApplicationSectionShown}
          jobAdId={jobAdId}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationTypeSelection;
