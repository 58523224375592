import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React, { useState } from "react";

interface ClientLogoProps {
  title?: string;
  image?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      transition: "opacity 1s ease-out",
      minHeight: 100,
      minWidth: 100,
      width: 100,
      height: 100,
      marginRight: "1rem",
    },
  })
);

const ClientLogo: React.FC<ClientLogoProps> = (props) => {
  const classes = useStyles();
  const { title, image } = props;
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  return (
    <img
      style={hasLoaded ? { opacity: 1 } : { opacity: 0 }}
      alt={title}
      src={image}
      className={classes.image}
      onLoad={() => {
        setHasLoaded(true);
      }}
    />
  );
};

export default ClientLogo;
