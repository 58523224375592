import { Button, Grid, Hidden, Link } from "@mui/material";
import Text from "../Atoms/Typography/Text";
import Title from "../Atoms/Typography/Title";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { recunitedBaseUrl } from "../../utils/environmentDependencies";
import { RecunitedIntroProps } from "../../types/jobdetails";
import {
  fireGAEvent,
  GA_CATEGORY_RECUNITED_APPLICATION,
} from "../../utils/googleAnalytics";

const RecunitedIntro: React.FC<RecunitedIntroProps> = ({
  setApplicationSectionShown,
  jobAdId,
  companyName,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <img
          src="/recunited_logo_color_850x160.png"
          alt="recunited-logo"
          style={{ height: "auto", width: 300, maxWidth: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Title
          text={`Lern das ${companyName}-Team jetzt schon kennen!`}
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <Text text="3 Schritte. 5 Minuten. 16 Aussagen." />
        <ol style={{ color: "#227671", paddingLeft: "1.8em" }}>
          <li>
            <Text
              color={false}
              text="Test machen: Eigene Werte, Arbeitstyp & -weise herausfinden"
            />
          </li>
          <li>
            <Text
              color={false}
              text="Ergebnisse vergleichen: Wie tickst du und passt das Team zu dir?"
            />
          </li>
          <li>
            <Text
              color={false}
              text="Bewerben: Überzeug dich persönlich und bewirb dich mit (oder ohne) deinem matching-Ergebnis. :)"
            />
          </li>
        </ol>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <Hidden smDown>
          <Link
            href={`${recunitedBaseUrl}?job_id=${jobAdId}&application`}
            underline="none"
            onClick={() => {
              fireGAEvent(
                GA_CATEGORY_RECUNITED_APPLICATION,
                GA_CATEGORY_RECUNITED_APPLICATION.actions.clickApplyDialog,
                `id: ${jobAdId}`
              );
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              style={{ backgroundColor: "#227671", color: "white" }}
            >
              Jetzt den Wertetest machen und bewerben
            </Button>
          </Link>
        </Hidden>
        <Hidden smUp>
          <Link
            href={`${recunitedBaseUrl}?job_id=${jobAdId}&application`}
            underline="none"
            onClick={() => {
              fireGAEvent(
                GA_CATEGORY_RECUNITED_APPLICATION,
                GA_CATEGORY_RECUNITED_APPLICATION.actions.clickApplyDialog,
                `id: ${jobAdId}`
              );
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              style={{ backgroundColor: "#227671", color: "white" }}
            >
              Jetzt zum Wertetest
            </Button>
          </Link>
        </Hidden>
      </Grid>
      <Grid item xs={12}>
        <Text text="Du kannst von deiner Auswertung, welche du per Email erhältst, wieder zu dieser Bewerbung gehen, und kannst dich entscheiden, ob der Arbeitgeber auch Einsicht in die Ergebnisse erhalten soll." />
      </Grid>

      <Grid item container justifyContent="center" xs={12}>
        <Hidden smDown>
          <Button
            variant="outlined"
            style={{ borderColor: "#227671", color: "#333333" }}
            onClick={() => {
              setApplicationSectionShown("");
            }}
          >
            Nein, ich möchte auf den gratis Wertetest von Recunited verzichten
            und mich direkt bewerben
          </Button>
        </Hidden>
        <Hidden smUp>
          <Button
            variant="outlined"
            style={{ borderColor: "#227671", color: "#333333" }}
            onClick={() => {
              setApplicationSectionShown("");
            }}
          >
            Nein, direkt zur Bewerbung
          </Button>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default RecunitedIntro;
